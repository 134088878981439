import { FORM_VALUE_ENUM, FORM_VALUE_INTER } from './constant';

export const convert = (data: FORM_VALUE_INTER) => {
  return {
    ...data,
    [FORM_VALUE_ENUM.EVENT_TYPE]: data[FORM_VALUE_ENUM.EVENT_TYPE].map(
      (item) => item.value,
    ),
  };
};
