//@ts-nocheck

import React, { useEffect } from 'react';

import { GridElem } from '../../common/grid';

import styled from 'styled-components';

import { SessionItemRoomScenarioContainer } from '../session-item-room-scenario';
import { SessionItemRoomHeaderContainer } from '../session-item-room-header';
import { SessionItemRoomReservationContainer } from '../session-item-room-reservation';
import { Spacing } from '../../theme';
import { SESSION_ITEM_DATA } from '../../data/session/constant';

import { FlexElem } from '../../common/flex';
import { SessionItemRoomChatContainer } from '../session-item-room-chat';
import { SessionItemRoomVideoContainer } from '../session-item-room-video';
import { ViewerItemDataContainer } from '../viewer-item-data';
import { MESSAGE_ITEM_DATA } from '../../data/message/constant';
import {
  EVENT_ITEM_DATA_RAW,
  REACTION_RAW_TYPE,
} from '../../data/event/constant';
import { SessionItemRoomEventContainer } from '../session-item-room-event';

export const Component: React.FC<{
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  data?: SESSION_ITEM_DATA;
  sessionId: string;
  sessionToken: string;
  chatMessage?: MESSAGE_ITEM_DATA[];
  sendMessage: Function;
  setChatMessage: Function;
  event?: EVENT_ITEM_DATA_RAW;
  reservationButtonClick: Function;
  sessionEnd: boolean;
  sessionStart: boolean;
  button?: EVENT_ITEM_DATA_RAW;
  banner?: EVENT_ITEM_DATA_RAW;
  reaction?: REACTION_RAW_TYPE;
  sendReaction: (emoji: string) => void;
  reservationCreated?: RESERVATION_CREATED_TYPE;
  question?: QUESTION_TYPE;
  eventStarted: boolean;
  chatActive?: boolean;
  survey: SURVEY_TYPE;
  sendAnswer: (answer: number) => void;
  viewerCount?: number;
  setReactionActive: React.Dispatch<React.SetStateAction<boolean>>;
  reactionActive: boolean;
}> = ({
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  data,
  sessionId,
  sessionToken,
  chatMessage,
  sendMessage,
  event,
  setChatMessage,
  reservationButtonClick,
  sessionStart,
  sessionEnd,
  banner,
  button,
  reaction,
  sendReaction,
  reservationCreated,
  question,
  eventStarted,
  chatActive,
  survey,
  sendAnswer,
  viewerCount,
  reactionActive,
  setReactionActive,
}) => {
  useEffect(() => {
    if (data?.webinar?.pixelId) {
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js',
      );

      fbq('init', data.webinar.pixelId);
      fbq('track', 'PageView');
      fbq('track', 'ViewContent');
    }
    // Инициализация Pixel
  }, [data?.webinar?.pixelId]);
  return (
    <>
      <ViewerItemDataContainer />

      <Container>
        <SessionItemRoomScenarioContainer
          data={data}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          errorMessage={errorMessage}
        />
        <SessionItemRoomHeaderContainer
          data={data}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          errorMessage={errorMessage}
          sessionStart={sessionStart}
          viewerCount={viewerCount}
          reactionActive={reactionActive}
          setReactionActive={setReactionActive}
        />
        <SessionItemRoomChatContainer
          chatMessage={chatMessage}
          sendMessage={sendMessage}
          setChatMessage={setChatMessage}
          eventStarted={chatActive}
          data={data}
          isLoading={isLoading}
          isSuccess={isSuccess}
        />
        <VideoEventContainer spacing={4}>
          <SessionItemRoomVideoContainer
            isLoading={isLoading}
            isSuccess={isSuccess}
            data={data}
            sessionStart={sessionStart}
          />
          {(button ||
            banner ||
            reaction ||
            question ||
            reservationCreated ||
            event ||
            survey) && (
            <SessionItemRoomEventContainer
              button={button}
              banner={banner}
              reaction={reaction}
              sendReaction={sendReaction}
              question={question}
              reservationCreated={reservationCreated}
              sessionId={sessionId}
              modal
              webinarData={data?.webinar}
              reservation={event}
              reservationButtonClick={reservationButtonClick}
              survey={survey}
              sendAnswer={sendAnswer}
              reactionActive={reactionActive}
            />
          )}
        </VideoEventContainer>
      </Container>
    </>
  );
};

const VideoEventContainer = styled(FlexElem)`
  flex-direction: column;
  height: calc(100%);
  max-height: 100%;
  max-width: 100%;
  @media screen and (max-width: 600px) {
    height: auto;
  }
`;

const Container = styled(GridElem)`
  min-height: calc(100dvh - 48px);
  height: auto;
  grid-template-columns: 28% auto;
  grid-template-rows: 90px calc(100% - 110px);
  box-sizing: border-box;
  gap: ${Spacing(5)};

  @media screen and (max-width: 1060px) {
    grid-template-columns: auto;
    gap: ${Spacing(3)};

    grid-template-rows: min-content min-content auto min-content;
  }

  @media screen and (max-width: 600px) {
    grid-template-rows: min-content min-content auto auto;
    gap: 16px;
  }
`;
