import React, { useState } from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { GridElem } from '../../common/grid';

import { Spacing } from '../../theme';

import { DATA, STATUS_TAB } from './constant';
import { EXPENDITURE_ITEM_DATA } from '../../data/expenditure/constant';
import { ExpenditureItemElem } from '../../data/expenditure/frame/expenditure-item-list.elem';
import { COLOR_ENUM } from '../../theme/color';
import { TextElem } from '../../common/text';
import { TabActionElem } from '../../common/tab-action';
import { IonInfiniteScroll } from '@ionic/react';
import { AlertEmptyElem } from '../../common/alert-empty';
import { ExpenditureSkeletonElem } from '../../data/expenditure/frame/expenditure-skeleton.elem';

export const Component: React.FC<{
  data?: DATA;
  isError?: boolean;
  errorMessage?: string;
  status: string;
  setStatus: any;
  isFetching?: boolean;
  fetchNextPage: Function;
  hasNextPage?: boolean;
}> = ({
  data,
  isError,
  errorMessage,
  status,
  setStatus,
  isFetching,
  fetchNextPage,
  hasNextPage,
}) => {
  const [updateVisible, setUpdateVisible] = useState(null);
  return (
    <GridElem spacing={5}>
      {isError && <AlertActionElem text={errorMessage} />}
      <TabActionElem
        tabList={STATUS_TAB}
        value={status}
        onChange={setStatus}
        backgroundColor="backgroundSecondary"
      />
      {data && data.isEmpty && !isFetching && <AlertEmptyElem />}

      <Container>
        <NewGrid>
          {data && !data.isEmpty && !isFetching && (
            <>
              <Head>
                <TextElem
                  tid="EXPENDITURE.HEAD.DATE"
                  type="medium"
                  color="textPrimary"
                />
                <TextElem
                  tid="EXPENDITURE.HEAD.DEADLINE"
                  type="medium"
                  color="textPrimary"
                />

                <TextElem
                  tid="EXPENDITURE.HEAD.AMOUNT"
                  type="medium"
                  color="textPrimary"
                />

                <TextElem
                  tid="EXPENDITURE.HEAD.BILL"
                  type="medium"
                  color="textPrimary"
                />
                <TextElem
                  tid="EXPENDITURE.HEAD.CATEGORY"
                  type="medium"
                  color="textPrimary"
                />
                <TextElem
                  tid="EXPENDITURE.HEAD.DEPARTMENT"
                  type="medium"
                  color="textPrimary"
                />
                <TextElem
                  tid="EXPENDITURE.HEAD.RESPONSIBLE"
                  type="medium"
                  color="textPrimary"
                />
                <TextElem
                  tid="EXPENDITURE.HEAD.COMMENT"
                  type="medium"
                  color="textPrimary"
                />
                <TextElem
                  tid="EXPENDITURE.HEAD.STATUS"
                  type="medium"
                  color="textPrimary"
                />
              </Head>

              {data &&
                !data.isEmpty &&
                data?.list.map((item: EXPENDITURE_ITEM_DATA) => (
                  <ExpenditureItemElem
                    data={item}
                    updateVisible={updateVisible}
                    setUpdateVisible={setUpdateVisible}
                  />
                ))}
            </>
          )}

          {isFetching && <ExpenditureSkeletonElem />}
        </NewGrid>
      </Container>
      {hasNextPage && (
        <IonInfiniteScroll
          onIonInfinite={async (ev) => {
            await fetchNextPage();
            ev.target.complete();
          }}
          threshold="100px"
          disabled={!hasNextPage}
        >
          <div style={{ height: '1px' }}></div>
        </IonInfiniteScroll>
      )}
    </GridElem>
  );
};
const Container = styled.div``;

const NewGrid = styled(GridElem)`
  gap: 0;
  row-gap: ${Spacing(3)};
  @media screen and (width<1100px) {
    justify-content: space-between;
    grid-template-columns: repeat(4, auto);
  }
  @media screen and (width<700px) {
    justify-content: space-between;

    grid-template-columns: auto auto;
  }

  @media screen and (width<580px) {
    justify-content: space-between;

    grid-template-columns: auto;
  }
`;

const Head = styled(GridElem)`
  height: 46px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
  width: 100%;
  align-items: center;
  padding: 0 ${Spacing(5)};
  display: grid;
  grid-column: span 9;
  grid-template-columns: subgrid;
  gap: ${Spacing(5)};
  @media screen and (width<1100px) {
    display: none;
  }
`;
