import styled, { css } from 'styled-components';
import { GridElem } from '../../../common/grid';
import { TextElem } from '../../../common/text';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { UseMutationResult } from 'react-query';
import { ReactComponent as FileIcon } from '../../../asset/svg/upload/file.svg';

import { ReactComponent as ImageLoadingIcon } from '../../../asset/svg/upload/image-loading.svg';
import { i18n } from '../../../lib/lang';
import { SuccessButtonContainer, SuccessButton } from './image';
import { ReactComponent as CloseIcon } from '../../../asset/svg/upload/edit.svg';
import { ReactComponent as EditIcon } from '../../../asset/svg/upload/close.svg';
import { FILE_ITEM_DATA_RAW } from '../../../data/file/constant';

export const FileComponent: React.FC<{
  data?: FILE_ITEM_DATA_RAW;
  handleClick: (e: any) => Promise<void>;
  uploadFile: UseMutationResult;
  defaultValue?: string;
  getInputProps: any;
  getRootProps: any;
  error: string;
  progress: number;
  handleReselect: (e: any) => void;
  handleReset: (e: any) => void;
}> = ({
  getInputProps,
  getRootProps,
  uploadFile,
  defaultValue,
  handleClick,
  error,
  progress,
  handleReselect,
  handleReset,
  data,
}) => {
  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };
  return (
    <Wrapper
      isError={!!error}
      onClick={handleClick}
      isSuccess={
        !!uploadFile.isSuccess || (!!defaultValue && !uploadFile.isLoading)
      }
      className="file-upload-wrapper"
    >
      {!uploadFile.isLoading && !uploadFile.isSuccess && !defaultValue && (
        <div {...getRootProps()}>
          <Input {...getInputProps()} />
          <Content spacing={3}>
            <Center spacing={2}>
              <FileIcon />
              <TextElem
                tid="FILE.UPLOAD.STATUS.UPLOAD"
                type="medium"
                color="textPrimary"
              />
            </Center>
            {!!error && (
              <TextElem tid={i18n.t(error)} size="small" color="error" />
            )}
          </Content>
        </div>
      )}
      {uploadFile.isLoading && (
        <Content spacing={3}>
          <Center spacing={2}>
            <ImageLoadingIcon />
            <TextElem
              tid="FILE.UPLOAD.STATUS.UPLOADING"
              type="medium"
              color="textFourth"
            />
          </Center>
          <Progress>
            <ProgressBar width={progress} />
          </Progress>
        </Content>
      )}

      {(!!uploadFile.isSuccess ||
        (!!defaultValue && !uploadFile.isLoading)) && (
        <div
          onClick={() => {
            window.open(data?.url || defaultValue);
          }}
        >
          <Center spacing={2}>
            <FileIconStyled />
            <TextElem tid="Uploaded" type="medium" color="textDefault" />
          </Center>
          <SuccessButtonContainer
            className="SuccessButtonContainer"
            onClick={stopPropagation}
          >
            <SuccessButton onClick={handleReselect}>
              <EditIcon className="editIcon" />
            </SuccessButton>
            <SuccessButton onClick={handleReset}>
              <CloseIcon />
            </SuccessButton>
          </SuccessButtonContainer>
        </div>
      )}
    </Wrapper>
  );
};

const FileIconStyled = styled(FileIcon)`
  path {
    stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_DEFAULT]};
    fill: ${({ theme }) => theme[COLOR_ENUM.TEXT_DEFAULT]};
  }
`;

export const ProgressBar = styled.div<{ width: number }>`
  border-radius: 100px;
  width: 100%;
  height: 4px;
  background: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
  position: absolute;
  ${({ width }) =>
    css`
      width: ${width}%;
    `}
`;

export const Progress = styled.div`
  border-radius: 100px;
  width: 100%;
  height: 4px;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
  position: relative;
`;

export const Center = styled(GridElem)`
  justify-items: center;
  text-align: center;
`;

export const Content = styled(Center)`
  padding: 0 ${Spacing(4)};
`;

export const Wrapper = styled.div<{ isSuccess?: boolean; isError?: boolean }>`
  ${({ isError }) => css`
    border: 1px solid
      ${({ theme }) =>
        isError ? theme[COLOR_ENUM.ERROR] : theme[COLOR_ENUM.BACKGROUND_THIRD]};
    background: ${({ theme }) =>
      isError
        ? theme[COLOR_ENUM.TRANSPARENT]
        : theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  `}
  transition: all 0.2s;

  :hover {
    background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
  }
  ${({ isSuccess }) =>
    isSuccess &&
    css`
      :hover {
        background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};

        border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
      }
    `}
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  display: grid;
  align-items: center;
  position: relative;
  cursor: pointer;
  height: 100%;
  min-height: 132px;
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
  display: block !important;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  cursor: pointer;
`;
