import React from 'react';
import { EVENT_ITEM_DATA } from '../constant';
import { TextElem } from '../../../common/text';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';

export const ReservationCreatedMobile: React.FC<{
  data: EVENT_ITEM_DATA;
}> = ({ data }) => {
  const { person } = data;

  return (
    <>
      <EllipsisContainerElem>
        <TextElem type="medium" color="textFourth" size="main" oneLine>
          {person?.name}
          {person?.country}
          {person?.city}
        </TextElem>
      </EllipsisContainerElem>
      <div></div>
    </>
  );
};
