import React, { useEffect } from 'react';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { RouteComponentProps } from 'react-router';

import { SessionItemRoomContainer } from '../../epic/session-item-room';
import styled from 'styled-components';
import { Spacing } from '../../theme';

interface SessionItemPageProps
  extends RouteComponentProps<{
    sessionId: string;
  }> {}

export const Page: React.FC<SessionItemPageProps> = ({ match }) => {
  const sessionId = match.params.sessionId;

  useEffect(() => {
    const handleContextMenu = (event: MouseEvent) => {
      event.preventDefault();
    };

    // Добавляем обработчик при монтировании
    document.addEventListener('contextmenu', handleContextMenu);

    // Убираем обработчик при размонтировании
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  return (
    <PageElem>
      <LayoutAppElem oneColumn>
        <LayoutElemStyled style={{ maxWidth: '100%' }}>
          <SessionItemRoomContainer sessionId={sessionId} />
        </LayoutElemStyled>
      </LayoutAppElem>
    </PageElem>
  );
};

const LayoutElemStyled = styled(LayoutElem)`
  @media screen and (max-width: 600px) {
    padding: ${Spacing(5)} ${Spacing(4)};
  }

  @media screen and (min-width: 1200px) {
    padding: ${Spacing(6)} ${Spacing(6)};
  }

  #layoutElem {
    display: none;
  }

  height: 100%;

  min-height: 100dvh;
`;
