import { convertDateToList } from '../../lib/lang/service';
import {
  FILE_ITEM_DATA_RAW,
  FILE_ITEM_DATA,
  FILE_ITEM_LIST_DATA_RAW,
  FILE_ITEM_LIST_DATA,
} from './constant';

export const convertFile = (file: FILE_ITEM_DATA_RAW): FILE_ITEM_DATA => {
  return {
    ...file,
    createDate: convertDateToList(file.createDate),
  };
};

export const convertFileList = (
  fileList: FILE_ITEM_DATA_RAW[],
): FILE_ITEM_LIST_DATA => {
  return {
    list: fileList?.map((file: FILE_ITEM_DATA_RAW) => {
      return convertFile(file);
    }),
    isEmpty: !fileList?.length,
  };
};
