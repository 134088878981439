import React from 'react';
import { action as deleteMember } from './action';
import { useMutation } from 'react-query';
import { DeleteIconElem } from '../../common/delete-icon';
import { useHistory } from 'react-router';
import { WEBINAR_PAGE_PATH } from '../../page/webinar';
import deleteIcon from '../../asset/svg/button/delete.svg';
import { ButtonElem } from '../../common/button';

export const Container: React.FC<{
  webinarId: any;
}> = ({ webinarId }) => {
  const history = useHistory();

  const action = useMutation(() => deleteMember(webinarId), {
    onSuccess: () => {
      history.push(WEBINAR_PAGE_PATH);
    },
  });

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const onClick = () => {
    action.mutate();
  };

  return (
    <DeleteIconElem
      onClick={onClick}
      isLoading={isLoading()}
      titleTid="COMMON.MODAL.TITLE.SESSION"
    >
      <ButtonElem
        color="error"
        tid="WEBINAR.UPDATE.BUTTON.DELETE"
        iconRight={deleteIcon}
      />
    </DeleteIconElem>
  );
};
