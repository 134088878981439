import React, { ReactNode } from 'react';

import styled, { css } from 'styled-components';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';

import { IonRippleEffect } from '@ionic/react';

export const Elem: React.FC<{
  children: ReactNode;
  handleClick?: any;
  hover?: boolean;
  control?: boolean;
  className?: string;
  style?: object;
}> = ({
  children,
  handleClick,
  hover = true,
  control = false,
  style,
  className,
}) => {
  return (
    <Card
      onClick={handleClick}
      hover={hover}
      control={control}
      style={style}
      className={`${className} ${control && 'ion-activatable'} `}
    >
      {children}
      {control && <IonRippleEffect />}
    </Card>
  );
};

const Card = styled.div<{ hover: boolean; control?: boolean }>`
  padding: ${Spacing(4)};

  @media screen and (width>700px) {
    padding: ${Spacing(4)} ${Spacing(5)};
  }

  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;

  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};
  max-width: 100%;
  transition: all 0.2s;
  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
        background-color: ${({ theme }) => theme[COLOR_ENUM.HOVER]};
        cursor: pointer;
      }
    `}
`;
