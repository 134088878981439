import { USER_DATA } from '../user/constant';

export const MODULE_NAME = 'AUTH_MODULE_NAME';

export const ACTION_TYPE = {
  SET_DATA: `${MODULE_NAME}.SET_DATA`,
  SET_META_DATA: `${MODULE_NAME}.SET_META_DATA`,
  CLEAR_DATA: `${MODULE_NAME}.CLEAR_DATA`,
  SET_SAFETY_DATA: `${MODULE_NAME}.SET_SAFETY_DATA`,
};

export interface META_DATA {
  haveLogin?: boolean;
  haveWellcome?: boolean;
}

export interface ACTION_DATA {
  user: USER_DATA;
  meta: META_DATA;
}

export interface STORE_INTER {
  logged: boolean | null;
  isConfirm: boolean | null;
  meta: META_DATA | null;
  user: USER_DATA | null;
}

export interface LOCAL_STORE_INTER {
  token: string | null;
  sessionToken?: string | null;
  user: USER_DATA | null;
  meta: META_DATA | null;
}

export const API = {
  GET_TOKEN: {
    TYPE: 'GET',
    URL: '/auth/token',
  },
};

export interface GET_TOKEN_ACTION_RESPONSE_INTER {
  accessToken: string;
}

export enum AUTH_TYPE {
  PHONE = 1,
  EMAIL = 2,
}
