import React from 'react';

import styled, { css } from 'styled-components';
import { TextElem } from '../../common/text';

import { Spacing } from '../../theme';
import { SIZE_LAYOUT_DATA, SIZE_LAYOUT_TYPE } from '../../theme/size';
import { ButtonBackElem } from '../../common/button-back';
import { SESSION_ITEM_DATA } from '../../data/session/constant';
import { FlexElem } from '../../common/flex';
import { COLOR_ENUM } from '../../theme/color';
import { TabElem } from '../../common/tab';
import { IonSkeletonText } from '@ionic/react';
import { WEBINAR_SESSION_PAGE_PATH_DYNAMIC } from '../../page/webinar-session';
import { SESSION_EVENT_PAGE_PATH_DYNAMIC } from '../../page/session-event';
import { SESSION_PERSON_PAGE_PATH_DYNAMIC } from '../../page/session-person';
import { SESSION_VIEWER_PAGE_PATH_DYNAMIC } from '../../page/session-viewer';
import { SESSION_ITEM_ROOM_MODERATION_PAGE_PATH_DYNAMIC } from '../../page/session-item-room-moderation';
import { ANALYTICS_PAGE_PATH_DYNAMIC } from '../../page/analytics';

export const Component: React.FC<{
  data?: SESSION_ITEM_DATA;
  size?: SIZE_LAYOUT_TYPE;
  isLoading?: boolean;
  isSuccess?: boolean;
  sessionId: string;
}> = ({ isLoading, sessionId, isSuccess, data, size = 'small' }) => {
  return (
    <>
      <Container size={size}>
        {isLoading && (
          <Head>
            <FlexElem spacing={3} style={{ width: 'auto' }}>
              <ButtonBackElem />
              <FlexElem spacing={2}>
                <IonSkeleton
                  style={{ width: '140px', height: '24px' }}
                  animated
                />
              </FlexElem>
            </FlexElem>
            <StatusContainer>
              <IonSkeleton
                style={{ height: '20px', width: '380px' }}
                animated
              />
            </StatusContainer>
          </Head>
        )}
        {isSuccess && data && (
          <Head>
            <FlexElem spacing={3} style={{ width: 'auto' }}>
              <ButtonBackElem
                path={WEBINAR_SESSION_PAGE_PATH_DYNAMIC(data.webinar.id)}
              />
              {/* <EllipsisContainerElem> */}
              <FlexElem spacing={2} style={{ maxWidth: '300px' }}>
                <TextElem type="semi-bold" size="heading" oneLine>
                  {data?.name}
                </TextElem>
              </FlexElem>
              {/* </EllipsisContainerElem> */}
            </FlexElem>
            <StatusContainer>
              <Status>
                <TextElem
                  tid={`WEBINAR.STATUS.${data.status}`}
                  color={data.statusColor}
                />
              </Status>

              <Dot />
              <Status>
                <TextElem>{data.time}</TextElem>
              </Status>
              <Dot />
              <Status>
                <TextElem>{data.timeZone}</TextElem>
              </Status>
            </StatusContainer>
          </Head>
        )}
        <TabElem
          tabList={[
            {
              tid: 'SESSION.TAB.VIEWER',
              path: SESSION_VIEWER_PAGE_PATH_DYNAMIC(sessionId),
            },
            {
              tid: 'SESSION.TAB.PERSON',
              path: SESSION_PERSON_PAGE_PATH_DYNAMIC(sessionId),
            },
            {
              tid: 'SESSION.TAB.EVENT',
              path: SESSION_EVENT_PAGE_PATH_DYNAMIC(sessionId),
            },
            {
              tid: 'SESSION.TAB.MODERATION',
              path: SESSION_ITEM_ROOM_MODERATION_PAGE_PATH_DYNAMIC(sessionId),
            },
            {
              tid: 'SESSION.TAB.ANALYTICS',
              path: ANALYTICS_PAGE_PATH_DYNAMIC(
                data?.webinar?.project?.id,
                data?.webinar?.id,
                sessionId,
              ),
            },
          ]}
        />
      </Container>
    </>
  );
};

const IonSkeleton = styled(IonSkeletonText)`
  --background-rgb: 255, 255, 255;
`;

const Head = styled(FlexElem)`
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 50px;
`;

const StatusContainer = styled.div`
  display: none;
  @media screen and (width>380px) {
    display: flex;
    align-items: center;
    gap: ${Spacing(3)};
  }
`;

const Dot = styled.div`
  border-radius: 100%;
  background: ${({ theme }) => theme[COLOR_ENUM.TEXT_THIRD]};
  height: 4px;
  width: 4px;
`;

const Status = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.5em;
`;

const Container = styled.div<{
  size: SIZE_LAYOUT_TYPE;
}>`
  display: grid;
  gap: ${Spacing(6)};
  align-items: center;
  width: 100%;

  ${({ size }) => css`
    max-width: calc(${SIZE_LAYOUT_DATA[size]}px + ${Spacing(10 * 2)});
  `};
`;
