import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Spacing } from '../../../theme';
import { TextElem } from '../../../common/text';
import { COLOR_ENUM } from '../../../theme/color';

export const ChatSamplePhrases: React.FC<{
  onInsertText: Function;
}> = ({ onInsertText }) => {
  const { t } = useTranslation();

  const samplePhrases = t('SESSION.MODERATION.CHAT.SAMPLE', {
    returnObjects: true,
  });

  if (!Array.isArray(samplePhrases)) {
    return null;
  }

  return (
    <Container>
      {samplePhrases.map(
        (phrase: { TITLE: string; TEXT: string }, index: number) => (
          <Item key={index} onClick={() => onInsertText(phrase.TEXT)}>
            <TextElem color="textPrimary" size="mobileDefault" type="medium">
              {phrase.TITLE}
            </TextElem>
          </Item>
        ),
      )}
    </Container>
  );
};

const Item = styled.div`
  padding: ${Spacing(2)} ${Spacing(6)};
  border-radius: 8px;
  cursor: pointer;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
  span {
    height: 13px;
  }
`;

const Container = styled.div`
  padding: ${Spacing(0)} ${Spacing(1)};
  height: 46px;
  display: flex;
  align-items: center;

  gap: ${Spacing(1)};
  overflow-y: auto;
  scrollbar-width: none;
  position: static;

  ::after {
    content: '';
    display: block;
    width: 100px;
    min-width: 100px;
    height: 38px;

    background: ${({ theme }) =>
      `linear-gradient(90deg, ${theme[COLOR_ENUM.BACKGROUND_SECONDARY]}00, ${
        theme[COLOR_ENUM.BACKGROUND_SECONDARY]
      }FF)`};

    position: sticky;
    pointer-events: none;
  }
`;
