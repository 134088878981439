import React from 'react';

import styled, { css } from 'styled-components';
import { TextElem } from '../../common/text';

import { Spacing } from '../../theme';
import { SIZE_LAYOUT_DATA, SIZE_LAYOUT_TYPE } from '../../theme/size';
import { ButtonBackElem } from '../../common/button-back';
import { WEBINAR_ITEM_DATA } from '../../data/webinar/constant';
import { WEBINAR_PAGE_PATH } from '../../page/webinar';
import { FlexElem } from '../../common/flex';
import { ChipElem } from '../../common/chip';
import { COLOR_ENUM } from '../../theme/color';
import { TabElem } from '../../common/tab';
import { WEBINAR_SETTINGS_PAGE_PATH_DYNAMIC } from '../../page/webinar-settings';
import { WEBINAR_SESSION_PAGE_PATH_DYNAMIC } from '../../page/webinar-session';
import { IonSkeletonText } from '@ionic/react';
import { WEBINAR_PROFILE_PAGE_PATH_DYNAMIC } from '../../page/webinar-profile';
import { ANALYTICS_PAGE_PATH_DYNAMIC } from '../../page/analytics';

export const Component: React.FC<{
  data?: WEBINAR_ITEM_DATA;
  size?: SIZE_LAYOUT_TYPE;
  isLoading?: boolean;
  isSuccess?: boolean;
  webinarId: string;
}> = ({ isLoading, isSuccess, data, size = 'small', webinarId }) => {
  return (
    <>
      <Container size={size}>
        {isLoading && (
          <Head>
            <FlexElem spacing={3} style={{ width: 'auto' }}>
              <ButtonBackElem path={WEBINAR_PAGE_PATH} />
              <FlexElem spacing={2}>
                <IonSkeleton
                  style={{ width: '140px', height: '24px' }}
                  animated
                />
                <ChipElem color="active" tid="          " />
              </FlexElem>
            </FlexElem>
            <StatusContainer>
              <IonSkeleton style={{ height: '20px', width: '80px' }} animated />

              <Dot />
              <IonSkeleton style={{ height: '20px', width: '80px' }} animated />

              <Dot />
              <IonSkeleton style={{ height: '20px', width: '80px' }} animated />
            </StatusContainer>
          </Head>
        )}
        {isSuccess && data && (
          <Head>
            <FlexElem spacing={3} style={{ width: 'auto' }}>
              <ButtonBackElem path={WEBINAR_PAGE_PATH} />
              {/* <EllipsisContainerElem> */}
              <FlexElem spacing={2} style={{ maxWidth: '300px' }}>
                <TextElem type="semi-bold" size="heading" oneLine>
                  {data?.name}
                </TextElem>
                <ChipElem color="active" tid={data?.project.name} />
              </FlexElem>
              {/* </EllipsisContainerElem> */}
            </FlexElem>
            <StatusContainer>
              <Status>
                <TextElem tid={`${data?.plannedCount}`} type="semi-bold" />
                <TextElem tid="WEBINAR.STATUS.PLAN" />
              </Status>
              <Dot />

              <Status>
                <TextElem
                  tid={`${data?.comingNowCount}`}
                  color="ongoingStatus"
                  type="semi-bold"
                />
                <TextElem tid="WEBINAR.STATUS.ONGOING" color="ongoingStatus" />
              </Status>
              <Dot />

              <Status>
                <TextElem
                  tid={`${data?.completedCount}`}
                  color="success"
                  type="semi-bold"
                />
                <TextElem tid="WEBINAR.STATUS.COMPLETED" color="success" />
              </Status>
            </StatusContainer>
          </Head>
        )}
        <TabElem
          tabList={[
            {
              tid: 'WEBINAR.TAB.SETTINGS',
              path: WEBINAR_SETTINGS_PAGE_PATH_DYNAMIC(webinarId),
            },
            {
              tid: 'WEBINAR.TAB.PROFILE',
              path: WEBINAR_PROFILE_PAGE_PATH_DYNAMIC(webinarId),
            },
            {
              tid: 'WEBINAR.TAB.SESSION',
              path: WEBINAR_SESSION_PAGE_PATH_DYNAMIC(webinarId),
            },
            {
              tid: 'WEBINAR.TAB.ANALYTICS',
              path: ANALYTICS_PAGE_PATH_DYNAMIC(data?.project?.id, webinarId),
            },
          ]}
        />
      </Container>
    </>
  );
};

const IonSkeleton = styled(IonSkeletonText)`
  --background-rgb: 255, 255, 255;
`;

const Head = styled(FlexElem)`
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 50px;
`;

const StatusContainer = styled.div`
  display: none;
  @media screen and (width>380px) {
    display: flex;
    align-items: center;
    gap: ${Spacing(3)};
  }
`;

const Dot = styled.div`
  border-radius: 100%;
  background: ${({ theme }) => theme[COLOR_ENUM.TEXT_THIRD]};
  height: 4px;
  width: 4px;
`;

const Status = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.5em;
`;

const Container = styled.div<{
  size: SIZE_LAYOUT_TYPE;
}>`
  display: grid;
  gap: ${Spacing(6)};
  align-items: center;
  width: 100%;

  margin: auto;
  ${({ size }) => css`
    max-width: calc(${SIZE_LAYOUT_DATA[size]}px + ${Spacing(10 * 2)});
  `};
`;
