export const MODULE_NAME = 'SESSION_ITEM_MODERATION_MESSAGE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  DELETE: {
    TYPE: 'PATCH',
    URL: (id: string) => `session/moderation/deleteMessage/${id}`,
  },
  APPROVE: {
    TYPE: 'PATCH',
    URL: (id: string) => `session/moderation/approveMessage/${id}`,
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
