import { SESSION_ITEM_DATA } from '../../data/session/constant';
import { Component } from './component';

export const Container: React.FC<{
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  data?: SESSION_ITEM_DATA;
  sessionStart: boolean;
  viewerCount?: number;
  setReactionActive: React.Dispatch<React.SetStateAction<boolean>>;
  reactionActive: boolean;
}> = ({
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  data,
  sessionStart,
  viewerCount,
  reactionActive,
  setReactionActive,
}) => {
  return (
    <Component
      isError={isError}
      isLoading={isLoading}
      isSuccess={isSuccess}
      errorMessage={errorMessage}
      data={data}
      sessionStart={sessionStart}
      viewerCount={viewerCount}
      reactionActive={reactionActive}
      setReactionActive={setReactionActive}
    />
  );
};
