import { TextElem } from '../../../common/text';
import { FlexElem } from '../../../common/flex';
import { ReactComponent as DotIcon } from '../../../asset/svg/session/dot.svg';
import EditIcon from '../../../asset/svg/session/edit.svg';
import { ButtonElem } from '../../../common/button';
import { COLOR_ENUM } from '../../../theme/color';
import styled from 'styled-components';
import { PhoneInput } from 'react-international-phone';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';

export const FakeField: React.FC<{
  name?: string;
  phone?: string;
  setFormVisible: Function;
}> = ({ name, phone, setFormVisible }) => {
  return (
    <Container spacing={2}>
      <FakeFieldEl spacing={2} style={{ alignItems: 'center' }}>
        <EllipsisContainerElem style={{ textAlign: 'end' }}>
          <TextElem oneLine>{`${name}`}</TextElem>
        </EllipsisContainerElem>

        <DotIcon style={{ minWidth: '4px' }} />
        <FlexElem spacing={0}>
          <PhoneInputStyled disabled value={phone} />
          <TextElem
            tid={phone?.replace('+', '')}
            size="small"
            type="medium"
            color="textFourth"
          />
        </FlexElem>
      </FakeFieldEl>

      <ButtonElem
        color="backgroundThird"
        tid="SESSION.VIEWER.RESERVATION.MODAL.EDIT.TITLE"
        iconRight={EditIcon}
        style={{ width: 'auto' }}
        onClick={() => setFormVisible(true)}
        type="button"
      />
    </Container>
  );
};

const PhoneInputStyled = styled(PhoneInput)`
  input {
    display: none;
  }
  div,
  button {
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]} !important;
  }
  .react-international-phone-input {
    margin-left: 4px !important;
  }
  img {
    position: relative;
    bottom: 1px;
    width: 16px;
    min-width: 16px;
    margin: 0;
  }
`;

const FakeFieldEl = styled(FlexElem)`
  height: 46px;
  padding: 0 16px;
  border-radius: 12px;
  justify-content: center;
  @media screen and (width<472px) {
    padding: 0 8px;
  }
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
`;

const Container = styled(FlexElem)`
  @media screen and (width<472px) {
    flex-wrap: wrap;
    & > * {
      width: 100% !important;
    }
  }
`;
