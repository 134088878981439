import React from 'react';
import { useHistory } from 'react-router';
import { ReactComponent as ArrowBack } from '../../asset/svg/common/arrowBack.svg';

import styled from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';

export const Elem: React.FC<{ path?: string | boolean }> = ({ path }) => {
  const history = useHistory();

  const handleAction = () => {
    if (typeof path === 'string') {
      history.push(`${path}`);
    }

    if (path === true) {
      history.go(-1);
    }
  };

  return <ArrowBackStyled onClick={handleAction} />;
};

const ArrowBackStyled = styled(ArrowBack)`
  cursor: pointer;
  transition: all 2s;
  min-width: 24px;
  path {
    transition: all 0.2s;
  }
  :hover {
    path {
      stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
      fill: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
    }
  }
`;
