import React from 'react';
import { useMutation } from 'react-query';
import { LoaderElem } from '../../common/loader';
import { ACTION_ERROR_INTER } from './constant';
import { AlertActionElem } from '../../common/alert-action';
import { ButtonElem } from '../../common/button';
import styled from 'styled-components';
import stopIcon from '../../asset/svg/session/moderation/chatStop.svg';
import startIcon from '../../asset/svg/session/moderation/chatStart.svg';
import { COLOR_ENUM } from '../../theme/color';
import { action } from './action';
import { DeleteIconElem } from '../../common/delete-icon';
import { ModalConfirmElem } from '../../common/modal-confirm';

export const Container: React.FC<{
  sessionId: string;
  chatActive: boolean;
}> = ({ sessionId, chatActive }) => {
  const actionMutate = useMutation(() => action(sessionId), {});

  const isLoading = () => {
    if (actionMutate.isLoading) {
      return true;
    }
  };

  const actionHandleClick = (e: any) => {
    actionMutate.mutate();
  };

  const isError = () => {
    if (actionMutate.isError && !actionMutate.isLoading && getErrorMessage())
      return true;
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = actionMutate.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };
  return (
    <>
      <ModalConfirmElem action={actionHandleClick} type="confirm">
        <Button
          color={chatActive ? 'error' : 'activeBackground'}
          tid={
            chatActive
              ? 'SESSION.MODERATION.CHAT.STOP'
              : 'SESSION.MODERATION.CHAT.START'
          }
          iconRight={chatActive ? stopIcon : startIcon}
          chatActive={chatActive}
          disabled={isLoading()}
        />
      </ModalConfirmElem>
      {isError() && <AlertActionElem tid={getErrorMessage()} />}
    </>
  );
};

const Button = styled(ButtonElem)<{ chatActive: boolean }>`
  & > * > * {
    color: ${({ theme, chatActive }) =>
      theme[chatActive ? COLOR_ENUM.ERROR : COLOR_ENUM.ACTIVE]};
  }
`;
