import React from 'react';

import { FormikValues } from 'formik';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import styled from 'styled-components';
import { i18n } from '../../lib/lang';

import { DoubleContainerElem } from '../../common/double-container';

import { FieldDataTimeInputElem } from '../../common/field-datetime-input';
import {
  EXPENDITURE_BILL_OPTION_LIST,
  EXPENDITURE_CATEGORY_OPTION_LIST,
  EXPENDITURE_DEPARTMENT_OPTION_LIST,
  EXPENDITURE_ITEM_DATA_RAW,
  EXPENDITURE_STATUS_OPTION_LIST,
  EXPENDITURE_VALUTE_OPTION_LIST,
} from '../../data/expenditure/constant';
import { SelectElem } from '../../common/select';
import { getAllUserList, getUserList } from '../../data/user/action';
import { convertUserListToSelect } from '../../data/user/convert';
import { SelectPreloadContainer } from '../select-preload';
import closeIcon from '../../asset/svg/common/close.svg';
import { FieldTextAreaElem } from '../../common/field-text-area';
import { TextElem } from '../../common/text';
import { FORM_VALUE_ENUM } from '../expenditure-item-create/constant';
import { FileItemUploadContainer } from '../file-item-upload';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue: Function;
  setValue: Function;
  close: any;
  onSuccessUpload: Function;
  data?: EXPENDITURE_ITEM_DATA_RAW;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isError,
  errorMessage,
  setFieldValue,
  setValue,
  close,
  isSuccess,
  onSuccessUpload,
  data,
}) => {
  return (
    <FormElemCustom onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}
      <GridElem spacing={5}>
        <TitleContainer>
          <TextElem
            tid="EXPENDITURE.FORM.EDIT_TITLE"
            type="semi-bold"
            size="main"
          />
          <Icon src={closeIcon} onClick={close} />
        </TitleContainer>

        <GridElem spacing={3}>
          <DoubleContainerElem>
            <SelectElem
              name={FORM_VALUE_ENUM.STATUS}
              onChange={setFieldValue}
              options={EXPENDITURE_STATUS_OPTION_LIST}
              title="EXPENDITURE.FORM.STATUS.TITLE"
              errorMessage={getFieldError(FORM_VALUE_ENUM.STATUS)}
              error={isFieldError(FORM_VALUE_ENUM.STATUS)}
              value={setValue(
                EXPENDITURE_STATUS_OPTION_LIST,
                FORM_VALUE_ENUM.STATUS,
              )}
            />
            <ButtonElem
              type="submit"
              color="success"
              tid="EXPENDITURE.FORM.UPDATE"
              fill="solid"
              disabled={isSubmitDisabled()}
              style={{ marginTop: '26px' }}
            />
          </DoubleContainerElem>

          <DoubleContainerElem>
            <FieldTextAreaElem
              name={FORM_VALUE_ENUM.REQUISITES}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="EXPENDITURE.FORM.REQUISITES.TITLE"
              value={getFieldValue(FORM_VALUE_ENUM.REQUISITES)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.REQUISITES)}
              error={isFieldError(FORM_VALUE_ENUM.REQUISITES)}
            />
            <FieldTextAreaElem
              name={FORM_VALUE_ENUM.DESCRIPTION}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="EXPENDITURE.FORM.DESCRIPTION.TITLE"
              value={getFieldValue(FORM_VALUE_ENUM.DESCRIPTION)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
              error={isFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
            />
          </DoubleContainerElem>

          <FieldDataTimeInputElem
            name={FORM_VALUE_ENUM.DEADLINE}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="EXPENDITURE.FORM.DEADLINE.TITLE"
            value={getFieldValue(FORM_VALUE_ENUM.DEADLINE)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.DEADLINE)}
            error={isFieldError(FORM_VALUE_ENUM.DEADLINE)}
            format="YYYY-MM-DD"
            showTime={false}
            type="customNumber"
          />

          <DoubleContainerElem>
            <FieldTextElem
              name={FORM_VALUE_ENUM.AMOUNT}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="EXPENDITURE.FORM.AMOUNT.TITLE"
              value={getFieldValue(FORM_VALUE_ENUM.AMOUNT)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.AMOUNT)}
              error={isFieldError(FORM_VALUE_ENUM.AMOUNT)}
            />

            <SelectElem
              name={FORM_VALUE_ENUM.VALUTE}
              onChange={setFieldValue}
              options={EXPENDITURE_VALUTE_OPTION_LIST}
              title="EXPENDITURE.FORM.VALUTE.TITLE"
              errorMessage={getFieldError(FORM_VALUE_ENUM.VALUTE)}
              error={isFieldError(FORM_VALUE_ENUM.VALUTE)}
              value={setValue(
                EXPENDITURE_VALUTE_OPTION_LIST,
                FORM_VALUE_ENUM.VALUTE,
              )}
            />
          </DoubleContainerElem>
          <DoubleContainerElem>
            <SelectElem
              name={FORM_VALUE_ENUM.BILL}
              onChange={setFieldValue}
              options={EXPENDITURE_BILL_OPTION_LIST}
              title="EXPENDITURE.FORM.BILL.TITLE"
              errorMessage={getFieldError(FORM_VALUE_ENUM.BILL)}
              error={isFieldError(FORM_VALUE_ENUM.BILL)}
              value={setValue(
                EXPENDITURE_BILL_OPTION_LIST,
                FORM_VALUE_ENUM.BILL,
              )}
            />

            <SelectElem
              name={FORM_VALUE_ENUM.CATEGORY}
              onChange={setFieldValue}
              options={EXPENDITURE_CATEGORY_OPTION_LIST}
              title="EXPENDITURE.FORM.CATEGORY.TITLE"
              errorMessage={getFieldError(FORM_VALUE_ENUM.CATEGORY)}
              error={isFieldError(FORM_VALUE_ENUM.CATEGORY)}
              value={setValue(
                EXPENDITURE_CATEGORY_OPTION_LIST,
                FORM_VALUE_ENUM.CATEGORY,
              )}
            />
          </DoubleContainerElem>
          <DoubleContainerElem>
            <SelectElem
              name={FORM_VALUE_ENUM.DEPARTMENT}
              onChange={setFieldValue}
              options={EXPENDITURE_DEPARTMENT_OPTION_LIST}
              title="EXPENDITURE.FORM.DEPARTMENT.TITLE"
              errorMessage={getFieldError(FORM_VALUE_ENUM.DEPARTMENT)}
              error={isFieldError(FORM_VALUE_ENUM.DEPARTMENT)}
              value={setValue(
                EXPENDITURE_DEPARTMENT_OPTION_LIST,
                FORM_VALUE_ENUM.DEPARTMENT,
              )}
            />

            <SelectPreloadContainer
              name={FORM_VALUE_ENUM.RESPONSIBLE}
              action={getAllUserList}
              convert={convertUserListToSelect}
              formik={formik}
              title="EXPENDITURE.FORM.RESPONSIBLE.TITLE"
              onChange={setFieldValue}
              errorMessage={getFieldError(FORM_VALUE_ENUM.RESPONSIBLE)}
              error={isFieldError(FORM_VALUE_ENUM.RESPONSIBLE)}
              value={getFieldValue(FORM_VALUE_ENUM.RESPONSIBLE)}
            />
          </DoubleContainerElem>
          <FieldTextElem
            name={FORM_VALUE_ENUM.COMMENT}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="EXPENDITURE.FORM.COMMENT.TITLE"
            value={getFieldValue(FORM_VALUE_ENUM.COMMENT)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.COMMENT)}
            error={isFieldError(FORM_VALUE_ENUM.COMMENT)}
          />
          <DoubleContainerElem>
            <GridElem spacing={2}>
              <TextElem
                tid="EXPENDITURE.FORM.COMMENT_FILE.TITLE"
                color="textPrimary"
              />
              <FileItemUploadContainer
                onSuccess={onSuccessUpload}
                defaultValue={data?.commentFile?.url}
                name={FORM_VALUE_ENUM.COMMENT_FILE}
                type="file"
              />
            </GridElem>
            <GridElem spacing={2}>
              <TextElem
                tid="EXPENDITURE.FORM.CONFIRM_FILE.TITLE"
                color="textPrimary"
              />
              <FileItemUploadContainer
                onSuccess={onSuccessUpload}
                defaultValue={data?.confirmFile?.url}
                name={FORM_VALUE_ENUM.CONFIRM_FILE}
                type="file"
              />
            </GridElem>
          </DoubleContainerElem>
        </GridElem>
      </GridElem>
      {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
      {isSuccess && <AlertActionElem type="success" tid="Success" />}
      {isLoading && <LoaderElem />}
    </FormElemCustom>
  );
};

const FormElemCustom = styled(FormElem)`
  cursor: default;
`;

const FieldContainer = styled(GridElem)`
  @media screen and (width>600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (width>900px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Icon = styled.img`
  cursor: pointer;
`;
