import {
  ANSWER_TYPE,
  EVENT_TYPE,
  REACTION_TYPE,
} from '../../data/event/constant';

export const MODULE_NAME = 'EVENT_ITEM_CREATE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  TIME = 'time',
  TYPE = 'type',
  MESSAGE = 'message',
  PERSON = 'person',
  BUTTON_TEXT = 'buttonText',
  END_TIME = 'endTime',
  PLACE_AVAILABLE = 'placeAvailable',
  PLACE_RESERVED = 'placeReserved',
  TIME_BOOST = 'timeBoost',
  LINK = 'link',
  FILE = 'file',
  BUTTON_COLOR = 'buttonColor',
  TEXT_COLOR = 'textColor',
  MESSAGE_REPLY = 'messageReply',
  MESSAGES = 'messages',
  REACTION = 'reaction',
  ANSWER = 'answers',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.TIME]: string;
  [FORM_VALUE_ENUM.TYPE]: EVENT_TYPE | null;
  [FORM_VALUE_ENUM.MESSAGE]: string;
  [FORM_VALUE_ENUM.PERSON]: string | string[];
  [FORM_VALUE_ENUM.BUTTON_TEXT]: string;
  [FORM_VALUE_ENUM.END_TIME]: string;
  [FORM_VALUE_ENUM.TIME_BOOST]: string;
  [FORM_VALUE_ENUM.FILE]: string;
  [FORM_VALUE_ENUM.LINK]: string;
  [FORM_VALUE_ENUM.PLACE_AVAILABLE]: number | string;
  [FORM_VALUE_ENUM.PLACE_RESERVED]: number | string;
  [FORM_VALUE_ENUM.MESSAGES]: { user: string; time: string; message: string }[];
  [FORM_VALUE_ENUM.REACTION]: { emoji: string; quantity: string }[];
  [FORM_VALUE_ENUM.ANSWER]: ANSWER_TYPE[];
}

export interface FORM_VALUE_RAW {
  [FORM_VALUE_ENUM.TIME]: string;
  [FORM_VALUE_ENUM.TYPE]: EVENT_TYPE | null;
  [FORM_VALUE_ENUM.MESSAGE]: string;
  [FORM_VALUE_ENUM.PERSON]: string | string[];
  [FORM_VALUE_ENUM.BUTTON_TEXT]: string;
  [FORM_VALUE_ENUM.END_TIME]: string;
  [FORM_VALUE_ENUM.TIME_BOOST]: string;
  [FORM_VALUE_ENUM.FILE]: string;
  [FORM_VALUE_ENUM.PLACE_AVAILABLE]: number | string;
  [FORM_VALUE_ENUM.PLACE_RESERVED]: number | string;
  [FORM_VALUE_ENUM.LINK]: string;
  [FORM_VALUE_ENUM.MESSAGES]: { user: string; time: string; message: string }[];
  [FORM_VALUE_ENUM.REACTION]: REACTION_TYPE[];
  [FORM_VALUE_ENUM.ANSWER]: ANSWER_TYPE[];
}

export const EVENT_TYPE_TAB = [
  {
    tid: 'EVENT.TYPE.MESSAGE',
    value: EVENT_TYPE.MESSAGE,
  },
  {
    tid: 'EVENT.TYPE.GROUP_MESSAGE',
    value: EVENT_TYPE.GROUP_MESSAGE,
  },
  {
    tid: 'EVENT.TYPE.RESERVATION',
    value: EVENT_TYPE.RESERVATION,
  },
  {
    tid: 'EVENT.TYPE.BANNER',
    value: EVENT_TYPE.BANNER,
  },
  {
    tid: 'EVENT.TYPE.BUTTON',
    value: EVENT_TYPE.BUTTON,
  },
  {
    tid: 'EVENT.TYPE.TIMECODE',
    value: EVENT_TYPE.TIMECODE,
  },

  {
    tid: 'EVENT.TYPE.REACTION',
    value: EVENT_TYPE.REACTION,
  },

  {
    tid: 'EVENT.TYPE.RESERVATION_CREATED',
    value: EVENT_TYPE.RESERVATION_CREATED,
  },
  {
    tid: 'EVENT.TYPE.QUESTION',
    value: EVENT_TYPE.QUESTION,
  },
  {
    tid: 'EVENT.TYPE.SURVEY',
    value: EVENT_TYPE.SURVEY,
  },
];

export const API = {
  TYPE: 'POST',
  URL: (sessionId: string) => `/event/${sessionId}`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
