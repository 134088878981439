import React, { useState } from 'react';
import styled from 'styled-components';
import { VIEWER_ITEM_DATA } from '../constant';
import { Spacing } from '../../../theme';

import { CardElem } from '../../../common/card';

import { TextElem } from '../../../common/text';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';
import { ViewerItemContainer } from '../../../epic/viewer-item';

const Elem: React.FC<VIEWER_ITEM_DATA> = ({
  id,
  name,
  phone,
  lang,
  needRecord,
  ban,
  watchTime,
  status,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const modalClose = (e: any) => {
    if (e) {
      e.stopPropagation();
    }
    setModalVisible(false);
  };
  const modalOpen = () => {
    setModalVisible(true);
  };
  return (
    <Card handleClick={modalOpen}>
      {modalVisible && (
        <ViewerItemContainer
          viewerId={id}
          visible={modalVisible}
          onClose={modalClose}
        />
      )}
      <EllipsisContainerElem style={{ paddingRight: `${Spacing(5)}` }}>
        <TextElem type="semi-bold" size="main" oneLine>
          {name}
        </TextElem>
      </EllipsisContainerElem>
      <TextElem color="textSecondary" style={{ maxWidth: '200px' }} oneLine>
        {phone}
      </TextElem>

      <TextElem
        tid="VIEWER.LIST.WATCH_TIME"
        tvalue={{ value: watchTime }}
        color="textSecondary"
        oneLine
        style={{ justifySelf: 'center' }}
      />
      <TextElem
        tid={`VIEWER.BAN.${ban}`}
        color="textSecondary"
        oneLine
        style={{ justifySelf: 'center' }}
      />
      <TextElem
        tid={needRecord ? `VIEWER.LIST.REQUEST_YES` : `VIEWER.LIST.REQUEST_NO`}
        color="textSecondary"
        oneLine
        style={{ justifySelf: 'center' }}
      />
      <TextElem
        tid={`VIEWER.LIST.${status}`}
        color="textSecondary"
        oneLine
        style={{ justifySelf: 'center' }}
      />
    </Card>
  );
};

const Card = styled(CardElem)<{}>`
  display: grid;
  grid-column: span 6;
  grid-template-columns: subgrid;
  gap: 0;
  justify-content: space-between;
  position: relative;
  align-items: center;
  gap: ${Spacing(12)};
`;

export { Elem as ViewerItemElem };
