import React from 'react';
import { RESERVATION_CREATED_TYPE } from '../../../data/event/constant';
import styled from 'styled-components';
import { GridElem } from '../../../common/grid';
import { TextElem } from '../../../common/text';
import { COLOR_ENUM } from '../../../theme/color';
import { AvatarElem } from '../../../common/avatar';

export const ReservationCreatedEvent: React.FC<{
  data: RESERVATION_CREATED_TYPE;
}> = ({ data }) => {
  const { photo, name, city, country, photoUrl } = data;
  return (
    <Container>
      <Avatar size={46} userAvatar={photo?.url || photoUrl} />
      <p>
        <TextElem type="semi-bold" size="title">
          {name}
        </TextElem>

        <TextElem
          color="textFourth"
          type="medium"
          size="title"
          tid="EVENT.RESERVATION_CREATED.TITLE"
          tvalue={{ city, country }}
        />
      </p>
    </Container>
  );
};

const Container = styled(GridElem)`
  grid-template-columns: 46px auto;
  align-items: center;
  padding: 24px 16px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  width: auto;

  @media screen and (width<700px) {
    grid-template-columns: 28px auto;
    p > span {
      font-size: 11px;
    }
  }
`;

const Avatar = styled(AvatarElem)`
  @media screen and (width<700px) {
    height: 28px !important;
    width: 28px !important;
    min-height: 28px !important;
    min-width: 28px !important;
  }
`;
