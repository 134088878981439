import React from 'react';

import styled from 'styled-components';
import { TextElem } from '../text';
import { marked } from 'marked';
// import { markdown } from 'markdown';

export const Elem: React.FC<{
  text: string;
  [key: string]: any;
}> = ({ text, ...props }) => {
  marked.setOptions({
    // breaks: true, // Поддержка переносов строк
    gfm: true, // Поддержка GitHub Flavored Markdown
  });
  return (
    <Text {...props}>
      <MarkdownContainer
        className="markdown"
        dangerouslySetInnerHTML={{
          __html: marked(text) as string,
        }}
      />
    </Text>
  );
};

const Text = styled(TextElem)`
  width: 100%;
  height: auto;
`;

const MarkdownContainer = styled.div`
  height: auto;
  white-space: normal;
  p {
    margin: 0; // Удаляем отступы, если нужно
    line-height: 150%; // Установка line-height для всего текста
  }

  // Другие стили для элементов Markdown
`;
