import React from 'react';
import { EVENT_ITEM_DATA, EVENT_TYPE } from '../constant';
import { Spacing } from '../../../theme';
import { TextElem } from '../../../common/text';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';

export const ReservationCreated: React.FC<{
  data: EVENT_ITEM_DATA;
}> = ({ data }) => {
  const { message, person } = data;

  return (
    <>
      <EllipsisContainerElem>
        <TextElem
          type="medium"
          color="textFourth"
          tid="EVENT.LIST.RESERVATION_CREATED"
          tvalue={{
            name: person.name,
            city: person.city,
            country: person.country,
          }}
          oneLine
          style={{ paddingLeft: `${Spacing(5)}` }}
        >
          {person?.name}
          {person?.country}
          {person?.city}
        </TextElem>
      </EllipsisContainerElem>
      <TextElem></TextElem>
    </>
  );
};
