import React, { useEffect, useMemo, useState } from 'react';

import { ACTION_ERROR_INTER, MODULE_NAME } from './constant';

import { Component } from './component';
import { useQuery } from 'react-query';
import { action as fetch } from './action';
import { SESSION_ITEM_DATA } from '../../data/session/constant';
import { convertSession } from '../../data/session/convert';
import { MESSAGE_ITEM_DATA } from '../../data/message/constant';
import useRoomSocket from './useSocket';

export const Container: React.FC<{ sessionId: string }> = ({ sessionId }) => {
  const [chatMessage, setChatMessage] = useState<MESSAGE_ITEM_DATA[]>([]);
  const [sessionStart, setSessionStart] = useState<boolean>(false);
  const [sessionEnd, setSessionEnd] = useState<boolean>(false);
  const [chatActive, setChatActive] = useState<boolean>(false);
  const [preModerationActive, setPreModerationActive] =
    useState<boolean>(false);
  const [messageDateFilter, setMessageDateFilter] = useState(
    new Date().toISOString(),
  );
  const [messageTypeFilter, setMessageTypeFilter] = useState('ALL');
  const [filtredMessage, setFiltredMessage] = useState<MESSAGE_ITEM_DATA[]>([]);
  const [selectedMessages, setSelectedMessages] = useState<{
    messageIds: string[];
    viewerIds: string[];
  }>({
    messageIds: [],
    viewerIds: [],
  });

  const toggleMessageSelection = (id: string, viewerId?: string) => {
    setSelectedMessages((prevSelected) => {
      // Обновление messageIds
      const updatedMessageIds = prevSelected.messageIds.includes(id)
        ? prevSelected.messageIds.filter((messageId) => messageId !== id)
        : [...prevSelected.messageIds, id];

      // Обновление viewerIds, если viewerId существует
      const updatedViewerIds = viewerId
        ? prevSelected.viewerIds.includes(viewerId)
          ? prevSelected.viewerIds.filter((vId) => vId !== viewerId)
          : [...prevSelected.viewerIds, viewerId]
        : prevSelected.viewerIds; // Если viewerId нет, оставляем без изменений

      return {
        messageIds: updatedMessageIds,
        viewerIds: updatedViewerIds,
      };
    });
  };

  const action = useQuery(MODULE_NAME, () => fetch(sessionId));
  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const getData = (): SESSION_ITEM_DATA | undefined => {
    const data = action.data as unknown as SESSION_ITEM_DATA;

    if (data) {
      return convertSession(data);
    }
  };

  const data = useMemo(() => getData(), [action.data]);

  useEffect(() => {
    if (data?.sessionStarted) setSessionStart(true);

    if (data?.sessionIsOver) setSessionEnd(true);

    if (data?.chatActive) setChatActive(true);

    if (data?.preModerationActive) setPreModerationActive(true);
  }, [data]);

  const { sendMessage } = useRoomSocket({
    sessionId,
    setChatMessage,
    setSessionStart,
    setSessionEnd,
    setChatActive,
    setPreModerationActive,
  });

  const filterMessage = () => {
    return chatMessage.filter((item) => {
      const sameDay =
        new Date(item.updateDate).setHours(0, 0, 0, 0) ===
        new Date(messageDateFilter).setHours(0, 0, 0, 0);
      if (messageTypeFilter === 'MODERATION') {
        return sameDay && item.moderCheck === false;
      }
      return sameDay;
    });
  };

  useEffect(() => {
    setFiltredMessage(filterMessage());
  }, [chatMessage, messageDateFilter, messageTypeFilter]);

  const resetSelectedList = () => {
    setSelectedMessages({ messageIds: [], viewerIds: [] });
  };

  return (
    <Component
      isError={isError()}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
      data={data}
      sessionId={sessionId}
      chatMessage={filtredMessage}
      sendMessage={sendMessage}
      setChatMessage={setChatMessage}
      sessionStart={sessionStart}
      sessionEnd={sessionEnd}
      preModerationActive={preModerationActive}
      chatActive={chatActive}
      messageDateFilter={messageDateFilter}
      setMessageDateFilter={setMessageDateFilter}
      messageTypeFilter={messageTypeFilter}
      setMessageTypeFilter={setMessageTypeFilter}
      toggleMessageSelection={toggleMessageSelection}
      selectedMessages={selectedMessages}
      resetSelectedList={resetSelectedList}
    />
  );
};
