import React from 'react';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { RouteComponentProps } from 'react-router';
import { SessionDataContainer } from '../../epic/session-data';
import { SessionHeaderContainer } from '../../epic/session-header';
import { LayoutSectionElem } from '../../common/layout-section';
import { MenuAppContainer } from '../../epic/menu-app';
import { SessionItemModerationContainer } from '../../epic/session-item-moderation';
import styled from 'styled-components';

interface SessionItemPageProps
  extends RouteComponentProps<{
    sessionId: string;
  }> {}

export const Page: React.FC<SessionItemPageProps> = ({ match }) => {
  const sessionId = match.params.sessionId;

  return (
    <PageCustom>
      <LayoutAppElem style={{ height: '100%' }}>
        <MenuAppContainer />

        <LayoutElem size="default" style={{ height: '100%' }}>
          <SessionDataContainer sessionId={sessionId} />
          <SessionHeaderContainer size="default" sessionId={sessionId} />
          <LayoutSection spacing={8}>
            <SessionItemModerationContainer sessionId={sessionId} />
          </LayoutSection>
        </LayoutElem>
      </LayoutAppElem>
    </PageCustom>
  );
};

const PageCustom = styled(PageElem)`
  @media screen and (width>800px) {
    max-height: 100%;
  }
`;

const LayoutSection = styled(LayoutSectionElem)`
  @media screen and (width>800px) {
    max-height: 100%;
    height: 100%;
    overflow: auto;
  }
`;
