import React from 'react';
import { GridElem } from '../../common/grid';
import styled from 'styled-components';
import { SESSION_ITEM_DATA } from '../../data/session/constant';
import { MESSAGE_ITEM_DATA } from '../../data/message/constant';
import { SessionItemModerationChatStartContainer } from '../session-item-moderation-chat-start';
import { ContentContainerElem } from '../../common/content-container';
import { SessionItemModerationChatContainer } from '../session-item-moderation-chat';
import { SessionItemModerationPremoderationContainer } from '../session-item-moderation-premoderation';
import { FieldDataTimeInputElem } from '../../common/field-datetime-input';
import { TabActionElem } from '../../common/tab-action';
import { TAB_LIST } from './constant';
import { SessionItemModerationMessageDeleteContainer } from '../session-item-moderation-message-delete';
import { SessionItemModerationViewerBanContainer } from '../session-item-moderation-viewer-ban';
import { SessionItemModerationViewerMuteContainer } from '../session-item-moderation-viewer-mute';
import { DoubleContainerElem } from '../../common/double-container';
import { SessionItemModerationCreateEventContainer } from '../session-item-moderation-create-event';
import { SessionItemModerationCreateQuestionContainer } from '../session-item-moderation-create-question';
import { SessionItemModerationStartContainer } from '../session-item-moderation-start';

export const Component: React.FC<{
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  data?: SESSION_ITEM_DATA;
  sessionId: string;
  chatMessage: MESSAGE_ITEM_DATA[];
  sendMessage: Function;
  setChatMessage: Function;

  sessionEnd: boolean;
  sessionStart: boolean;

  chatActive: boolean;
  preModerationActive: boolean;
  messageTypeFilter: string;
  setMessageTypeFilter: Function;
  messageDateFilter: string;
  setMessageDateFilter: Function;
  toggleMessageSelection: Function;
  selectedMessages: { messageIds: string[]; viewerIds: string[] };
  resetSelectedList: Function;
}> = ({
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  data,
  sessionId,
  chatMessage,
  sendMessage,
  setChatMessage,
  sessionStart,
  sessionEnd,
  preModerationActive,
  chatActive,
  messageTypeFilter,
  setMessageTypeFilter,
  messageDateFilter,
  setMessageDateFilter,
  toggleMessageSelection,
  selectedMessages,
  resetSelectedList,
}) => {
  const onDateChange = (e: any) => {
    setMessageDateFilter(e.target.value);
  };
  return (
    <Container>
      <SessionItemModerationChatContainer
        chatMessage={chatMessage}
        sendMessage={sendMessage}
        setChatMessage={setChatMessage}
        eventStarted={chatActive}
        data={data}
        isLoading={isLoading}
        isSuccess={isSuccess}
        toggleMessageSelection={toggleMessageSelection}
        selectedMessages={selectedMessages}
        preModerationActive={preModerationActive}
      />
      <ActionContainer spacing={4}>
        <FieldDataTimeInputElem
          showTime={false}
          value={messageDateFilter}
          onChange={onDateChange}
          format="YYYY-MM-DD"
        />
        {!data?.auto && (
          <ContentContainer>
            <DoubleContainerElem>
              <SessionItemModerationPremoderationContainer
                sessionId={sessionId}
                preModerationActive={preModerationActive}
              />
              {preModerationActive && (
                <TabAction
                  tabList={TAB_LIST}
                  onChange={setMessageTypeFilter}
                  value={messageTypeFilter}
                />
              )}
            </DoubleContainerElem>
          </ContentContainer>
        )}
        <ContentContainer>
          <TrippleContainer>
            <SessionItemModerationMessageDeleteContainer
              sessionId={sessionId}
              selectedList={selectedMessages.messageIds}
              resetSelectedList={resetSelectedList}
            />
            <DoubleContainer>
              <SessionItemModerationViewerBanContainer
                sessionId={sessionId}
                selectedList={selectedMessages.viewerIds}
                resetSelectedList={resetSelectedList}
              />
              <SessionItemModerationViewerMuteContainer
                sessionId={sessionId}
                selectedList={selectedMessages.viewerIds}
                resetSelectedList={resetSelectedList}
              />
            </DoubleContainer>
          </TrippleContainer>
        </ContentContainer>

        <ContentContainer>
          <DoubleContainer>
            <SessionItemModerationStartContainer
              sessionId={sessionId}
              sessionStart={sessionStart}
            />
            <SessionItemModerationChatStartContainer
              sessionId={sessionId}
              chatActive={chatActive}
            />
          </DoubleContainer>
          <SessionItemModerationCreateEventContainer sessionId={sessionId} />
          <SessionItemModerationCreateQuestionContainer
            selecteMessage={selectedMessages.messageIds[0]}
            resetSelectedList={resetSelectedList}
          />
        </ContentContainer>
      </ActionContainer>
    </Container>
  );
};

const DoubleContainer = styled(GridElem)`
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  @media screen and (width<450px) {
    grid-template-columns: 1fr;
  }
`;

const TabAction = styled(TabActionElem)`
  ::after {
    display: none;
  }

  width: 100%;
`;

const ContentContainer = styled(ContentContainerElem)`
  padding: 24px;
`;
const Container = styled(GridElem)`
  @media screen and (width<800px) {
    display: flex;
    flex-direction: column;
  }
  overflow: auto;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  height: 100%;
  max-height: 100%;
`;

const TrippleContainer = styled(GridElem)`
  grid-template-columns: 1fr 2fr;

  gap: 12px;
  > {
    height: 100%;
    max-height: 100%;
  }
  @media screen and (width<600px) {
    grid-template-columns: 1fr;
  }
`;

const ActionContainer = styled(GridElem)``;
