import React from 'react';
import { EVENT_ITEM_DATA } from '../constant';
import { TextElem } from '../../../common/text';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';

export const ReactionMobile: React.FC<{
  data: EVENT_ITEM_DATA;
}> = ({ data }) => {
  const { reactionCounter, emojiList } = data;

  return (
    <>
      <EllipsisContainerElem>
        <TextElem type="medium" color="textFourth" size="main" oneLine>
          {emojiList}
        </TextElem>
      </EllipsisContainerElem>
      <EllipsisContainerElem>
        <TextElem
          type="medium"
          color="textPrimary"
          oneLine
          tid="EVENT.LIST.REACTION_COUNTER"
          tvalue={{ count: reactionCounter }}
        />
      </EllipsisContainerElem>
    </>
  );
};
