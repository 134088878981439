import React, { useState } from 'react';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
} from './constant';

import { Component } from './component';
import { maxLength, name, required } from '../../lib/validation/service';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { useMutation, useQuery } from 'react-query';
import { action as fetch } from './action';
import { WEBINAR_ITEM_DATA } from '../../data/webinar/constant';
import { WEBINAR_ITEM_DATA_MODULE_NAME } from '../webinar-item-data';
import { convert } from './convert';

export const Container: React.FC<{ webinarId: string }> = ({ webinarId }) => {
  const preFetch = useQuery([WEBINAR_ITEM_DATA_MODULE_NAME, webinarId], {
    retry: 0,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const getData = (): WEBINAR_ITEM_DATA | undefined => {
    const data = preFetch.data as unknown as WEBINAR_ITEM_DATA;
    if (data) {
      return data;
    }
  };

  const isSuccessPreFetch = () => {
    if (preFetch.isSuccess) {
      return true;
    }
  };

  const isLoadingPreFetch = () => {
    if (preFetch.isLoading) {
      return true;
    }
  };

  const data = getData();

  const action = useMutation((values: FORM_VALUE_INTER) =>
    fetch(values, webinarId),
  );

  const config = {
    [FORM_VALUE_ENUM.NAME]: [required, name, maxLength(120)],
    [FORM_VALUE_ENUM.DESCRIPTION]: [required, maxLength(320)],
    [FORM_VALUE_ENUM.LINK]: [required],
  };

  const validate = (values: FormikValues) => validation(values, config);
  const initialValues =
    isSuccessPreFetch() && data
      ? {
          [FORM_VALUE_ENUM.NAME]: data.buttonLinkDescription,
          [FORM_VALUE_ENUM.DESCRIPTION]: data.buttonLinkName,
          [FORM_VALUE_ENUM.LINK]: data.buttonLink,
        }
      : {
          [FORM_VALUE_ENUM.NAME]: '',
          [FORM_VALUE_ENUM.DESCRIPTION]: '',
          [FORM_VALUE_ENUM.LINK]: '',
        };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: (values: FORM_VALUE_INTER) => {
      return action.mutate(convert(values));
    },
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  return (
    <Component
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      isError={isError()}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
      isSuccessPreFetch={isSuccessPreFetch()}
      isLoadingPreFetch={isLoadingPreFetch()}
    />
  );
};
