import { API } from './constant';
import { HttpRequest } from '../../lib/http';

export const getSession = (id: string) => {
  return HttpRequest({
    method: API.GET.TYPE,
    url: API.GET.URL(id),
  });
};

export const connect = (id: string, date: string) => {
  return HttpRequest({
    method: API.CONNECT.TYPE,
    url: API.CONNECT.URL(id),
    data: { date },
  });
};
