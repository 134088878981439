import React from 'react';
import { AlertActionElem } from '../../common/alert-action';
import { GridElem } from '../../common/grid';
import { ContentContainerElem } from '../../common/content-container';
import { TextElem } from '../../common/text';
import { WEBINAR_ITEM_DATA } from '../../data/webinar/constant';
import { DoubleContainerElem } from '../../common/double-container';

import { FormikValues } from 'formik';
import { FileItemUploadContainer } from '../file-item-upload';
import { WEBINAR_UPDATE_FORM_VALUE_ENUM } from '../webinar-item-update-general';
import { Skeleton } from './skeleton';

export const Component: React.FC<{
  formik: FormikValues;
  data?: WEBINAR_ITEM_DATA;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  onSuccessUpload: Function;
}> = ({
  onSuccessUpload,
  data,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  formik,
}) => {
  return (
    <GridElem spacing={5}>
      <ContentContainerElem>
        <TextElem
          tid="WEBINAR.UPDATE.MEDIA.TITLE"
          type="semi-bold"
          size="main"
        />

        {isLoading && <Skeleton />}
        {isError && <AlertActionElem text={errorMessage} />}

        {isSuccess && data && (
          <GridElem spacing={3}>
            <DoubleContainerElem>
              <GridElem spacing={2}>
                <TextElem
                  tid="WEBINAR.UPDATE.FORM.SPEAKER_IMG"
                  color="textPrimary"
                />
                <FileItemUploadContainer
                  onSuccess={onSuccessUpload}
                  defaultValue={data?.speakerImg?.url}
                  name={WEBINAR_UPDATE_FORM_VALUE_ENUM.SPEAKER_IMG}
                  type="image"
                  maxSize={3145728}
                  maxSizeText="3 MB"
                />
              </GridElem>
              <GridElem spacing={2}>
                <TextElem tid="WEBINAR.UPDATE.FORM.LOGO" color="textPrimary" />
                <FileItemUploadContainer
                  onSuccess={onSuccessUpload}
                  defaultValue={data?.logo?.url}
                  name={WEBINAR_UPDATE_FORM_VALUE_ENUM.LOGO}
                  type="image"
                  maxSize={3145728}
                  maxSizeText="3 MB"
                />
              </GridElem>
            </DoubleContainerElem>
          </GridElem>
        )}
      </ContentContainerElem>
    </GridElem>
  );
};
