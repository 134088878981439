//@ts-nocheck

import moment from 'moment';
import { FORM_VALUE_ENUM, FORM_VALUE_INTER } from './constant';
export const convert = (data: FORM_VALUE_INTER) => {
  return {
    ...data,
    [FORM_VALUE_ENUM.ONLINE_MAX]: Number(data[FORM_VALUE_ENUM.ONLINE_MAX]),
    [FORM_VALUE_ENUM.ONLINE_MIN]: Number(data[FORM_VALUE_ENUM.ONLINE_MIN]),
  };
};
