import React from 'react';

import { FormikValues } from 'formik';
import { GridElem } from '../../../common/grid';
import { DoubleContainerElem } from '../../../common/double-container';
import { FORM_VALUE_ENUM } from '../constant';
import { FieldTimeInputElem } from '../../../common/field-time-input';
import { FieldTextElem } from '../../../common/field-text';
import { FileItemUploadContainer } from '../../file-item-upload';
import { TextElem } from '../../../common/text';
import styled from 'styled-components';

export const BannerForm: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  onSuccessUpload: Function;
  file?: string;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  getFieldValue,
  onSuccessUpload,
  file,
}) => {
  return (
    <Container spacing={5}>
      <GridElem spacing={2}>
        <TextElem color="textPrimary" tid="EVENT.FORM.IMAGE" />
        <FileItemUploadContainer
          onSuccess={onSuccessUpload}
          defaultValue={file}
          // value={getFieldValue(FORM_VALUE_ENUM.FILE)}
          name={FORM_VALUE_ENUM.FILE}
          maxSize={3145728}
          maxSizeText="3 MB"
          type="image"
        />
      </GridElem>
      <GridElem spacing={5}>
        <DoubleContainerElem>
          <FieldTimeInputElem
            name={FORM_VALUE_ENUM.TIME}
            onChange={formik.handleChange}
            title="EVENT.FORM.START_TIME"
            value={getFieldValue(FORM_VALUE_ENUM.TIME)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.TIME)}
            error={isFieldError(FORM_VALUE_ENUM.TIME)}
            formik={formik}
          />
          <FieldTimeInputElem
            name={FORM_VALUE_ENUM.END_TIME}
            onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            title="EVENT.FORM.END_TIME"
            value={getFieldValue(FORM_VALUE_ENUM.END_TIME)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.END_TIME)}
            error={isFieldError(FORM_VALUE_ENUM.END_TIME)}
            formik={formik}
          />
        </DoubleContainerElem>
        <FieldTextElem
          name={FORM_VALUE_ENUM.LINK}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          title="EVENT.FORM.LINK"
          value={getFieldValue(FORM_VALUE_ENUM.LINK)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.LINK)}
          error={isFieldError(FORM_VALUE_ENUM.PLACE_AVAILABLE)}
        />
      </GridElem>
    </Container>
  );
};

const Container = styled(GridElem)`
  grid-template-columns: 330px auto;
  @media screen and (width<700px) {
    grid-template-rows: auto auto;
    grid-template-columns: auto;
  }
`;
