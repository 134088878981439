import React from 'react';
import styled, { css } from 'styled-components';
import { Spacing } from '../../theme';

import { COLOR_ENUM } from '../../theme/color';
import { ContentContainerElem } from '../content-container';
import { GridElem } from '../grid';
import { FlexElem } from '../flex';
import { TextElem } from '../text';

import { CARD_DATA } from './constant';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';

export const Elem: React.FC<{
  title?: string;
  subTitle?: string;
  cardData: CARD_DATA[];
}> = ({ title, subTitle, cardData }) => {
  return (
    <ContentContainerElem style={{ padding: `${Spacing(6)}` }}>
      <GridElem spacing={5}>
        <HeaderElem>
          <TextElem size="main" type="semi-bold" color="default" tid={title} />
          <TextElem
            size="small"
            type="regular"
            color="textThird"
            tid={subTitle}
          />
        </HeaderElem>

        <ContainerElem>
          {cardData.map((card, index) => (
            <CardElem key={index}>
              <Content>
                <FlexElem spacing={1}>
                  <Img src={card.icon} />
                  <TextElem type="medium" color="textThird" tid={card.title} />
                </FlexElem>
                <TextElem
                  size="veryBig"
                  type="semi-bold"
                  color="textSecondary"
                  tid={card.text}
                />
              </Content>
            </CardElem>
          ))}
        </ContainerElem>
      </GridElem>
    </ContentContainerElem>
  );
};

const HeaderElem = styled(FlexElem)`
  justify-content: space-between;
  @media screen and (width<660px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

const ContainerElem = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 12px;
`;

const CardElem = styled.div`
  padding: ${Spacing(5)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  flex: 1;
  text-align: center;

  ${({ theme }) => css`
    background-color: ${theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
  align-items: flex-start;
  justify-content: center;
`;

const Img = styled.img`
  margin-bottom: 4px;
  width: 16px;
  height: 16px;
`;
