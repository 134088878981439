import React, { useState } from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { GridElem } from '../../common/grid';

import { Spacing } from '../../theme';
import {
  PERSON_ITEM_DATA,
  PERSON_ITEM_LIST_DATA,
} from '../../data/person/constant';

import { IonInfiniteScroll } from '@ionic/react';
import { PersonFilterContainer } from '../person-filter';
import { PersonSkeletonElem } from '../../data/person/frame/person-skeleton.elem';
import { PersonItemElem } from '../../data/person/frame/person-item-list.elem';
import { PersonItemCreateContainer } from '../person-item-create';

export const Component: React.FC<{
  data?: PERSON_ITEM_LIST_DATA;
  isError?: boolean;
  errorMessage?: string;
  isFetching?: boolean;
  fetchNextPage: Function;
  isIdle?: boolean;
  setSort: Function;
  sort: string;
  search: string;
  setSearch: Function;
  sessionId: string;
  hasNextPage?: boolean;
}> = ({
  data,
  isError,
  errorMessage,
  isFetching,
  fetchNextPage,
  setSort,
  sort,
  search,
  setSearch,
  sessionId,
  hasNextPage,
}) => {
  const [updateVisible, setUpdateVisible] = useState(null);

  return (
    <GridElem spacing={5}>
      <PersonFilterContainer
        sort={sort}
        setSort={setSort}
        search={search}
        setSearch={setSearch}
        sessionId={sessionId}
      />
      <PersonItemCreateContainer sessionId={sessionId} />
      {isError && <AlertActionElem text={errorMessage} />}
      <Container>
        <NewGrid>
          {data &&
            !data.isEmpty &&
            data?.list.map((item: PERSON_ITEM_DATA) => (
              <PersonItemElem
                data={item}
                updateVisible={updateVisible}
                setUpdateVisible={setUpdateVisible}
                key={item.id}
              />
            ))}
        </NewGrid>

        {isFetching && <PersonSkeletonElem />}
      </Container>
      {hasNextPage && (
        <IonInfiniteScroll
          onIonInfinite={async (ev) => {
            await fetchNextPage();
            ev.target.complete();
          }}
          threshold="100px"
          disabled={!hasNextPage}
        >
          <div style={{ height: '1px' }}></div>
        </IonInfiniteScroll>
      )}
    </GridElem>
  );
};

const Container = styled.div`
  display: grid;
  gap: ${Spacing(3)};
`;

const NewGrid = styled(GridElem)`
  @media screen and (width>600) {
    grid-template-columns: 24px auto repeat(3, min-content);
  }
  row-gap: ${Spacing(3)};
`;
