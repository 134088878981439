import React, { useState } from 'react';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
} from './constant';

import { Component } from './component';
import {
  maxLength,
  name,
  phone,
  required,
  userName,
} from '../../lib/validation/service';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { useMutation, useQueryClient } from 'react-query';
import { action as fetch } from './action';
import { PERSON_LIST_MODULE_NAME } from '../person-list';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { PERSON_ROLE } from '../../data/person/constant';
import generateData from './generateData.json';
const data: GenerateData = generateData;

interface Viewer {
  NAME: string;
  COUNTRY: string;
  CITY: string;
  PHONE: string;
  PHOTO: string;
}

type GenerateData = Record<string, Viewer>;

export const Container: React.FC<{ sessionId: string }> = ({ sessionId }) => {
  const [formVisible, setFormVisible] = useState(false);

  const query = useQueryClient();

  const onSuccess = (d: any, values: any) => {
    query.invalidateQueries(PERSON_LIST_MODULE_NAME);
    formik.resetForm();
    setFormVisible(false);
  };

  const action = useMutation(
    (values: FORM_VALUE_INTER) => fetch(values, sessionId),
    { onSuccess },
  );

  const config = {
    [FORM_VALUE_ENUM.NAME]: [required, userName, maxLength(80)],
    [FORM_VALUE_ENUM.PHONE]: [required, phone],
    [FORM_VALUE_ENUM.COUNTRY]: [required, name, maxLength(50)],
    [FORM_VALUE_ENUM.CITY]: [required, name, maxLength(50)],
    [FORM_VALUE_ENUM.FILE]: [],
    [FORM_VALUE_ENUM.PHOTO_URL]: [],
    [FORM_VALUE_ENUM.ROLE]: [required],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues = {
    [FORM_VALUE_ENUM.NAME]: '',
    [FORM_VALUE_ENUM.CITY]: '',
    [FORM_VALUE_ENUM.COUNTRY]: '',
    [FORM_VALUE_ENUM.FILE]: '',
    [FORM_VALUE_ENUM.PHONE]: '',
    [FORM_VALUE_ENUM.ROLE]: '',
    [FORM_VALUE_ENUM.PHOTO_URL]: '',
  };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    onSubmit: (values: FORM_VALUE_INTER) => {
      return action.mutate(values);
    },
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const onSuccessUpload = (id: string, name: string) => {
    formik.setFieldValue(name, id);
  };

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) =>
    formik.setFieldValue(name, e.value);

  const generateRandomData = () => {
    // Выбираем случайного зрителя
    const keys = Object.keys(generateData); // ['1', '2', '3', ...]
    const randomKey = keys[Math.floor(Math.random() * keys.length)]; // Выбираем случайный ключ
    const randomViewer = data[randomKey];

    // Устанавливаем значения в поля
    if (randomViewer) {
      formik.setFieldValue(FORM_VALUE_ENUM.CITY, randomViewer.CITY);
      formik.setFieldValue(FORM_VALUE_ENUM.COUNTRY, randomViewer.COUNTRY);
      formik.setFieldValue(FORM_VALUE_ENUM.PHONE, randomViewer.PHONE);
      formik.setFieldValue(FORM_VALUE_ENUM.PHOTO_URL, randomViewer.PHOTO);
      formik.setFieldValue(FORM_VALUE_ENUM.NAME, randomViewer.NAME);
      formik.setFieldValue(FORM_VALUE_ENUM.ROLE, PERSON_ROLE.USER);
    } else {
      console.error('Данные случайного зрителя не найдены');
    }
  };

  const onResetPhoto = () => {
    formik.setFieldValue(FORM_VALUE_ENUM.PHOTO_URL, '');
    formik.setFieldValue(FORM_VALUE_ENUM.FILE, '');
  };

  return (
    <Component
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      isError={isError()}
      isLoading={isLoading()}
      errorMessage={getErrorMessage()}
      formVisible={formVisible}
      setFormVisible={setFormVisible}
      onSuccessUpload={onSuccessUpload}
      setValue={setValue}
      setFieldValue={setFieldValue}
      generateRandomData={generateRandomData}
      onResetPhoto={onResetPhoto}
    />
  );
};
