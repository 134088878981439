import { FormikValues } from 'formik';
import { GridElem } from '../../../common/grid';
import { FormElem } from '../../../common/form';
import { FakeField } from './fakeField';
import { TextElem } from '../../../common/text';
import { Form } from './form';
import { FormBorder } from '../component';
import { FlexElem } from '../../../common/flex';
import { ButtonElem } from '../../../common/button';
import ReservationIcon from '../../../asset/svg/session/reservation.svg';
import styled from 'styled-components';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import { WEBINAR_ITEM_DATA_RAW } from '../../../data/webinar/constant';
import { WEBGIFT_ITEM_DATA_RAW } from '../../../data/webgift/constant';
import { ReactComponent as GarantIcon } from '../../../asset/svg/session/garant.svg';
import { ViewerCounter } from './viewerCounter';
import { PopoverElem } from './popover';
import { EVENT_ITEM_DATA_RAW } from '../../../data/event/constant';

export const ReservationCreate: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  name?: string;
  phone?: string;
  setFormVisible: Function;
  formVisible: boolean;
  webinarData: WEBINAR_ITEM_DATA_RAW;
  isSubmitDisabled?: boolean;
  event?: EVENT_ITEM_DATA_RAW;
}> = ({
  formik,
  getFieldValue,
  getFieldError,
  isFieldError,
  name,
  phone,
  setFormVisible,
  formVisible,
  webinarData,
  isSubmitDisabled,
  event,
}) => {
  return (
    <FormElem onSubmit={formik.handleSubmit}>
      <GridElem spacing={5}>
        <TextElem
          style={{ lineHeight: '150%', textAlign: 'center' }}
          size="medium"
          type="semi-bold"
          tid="SESSION.VIEWER.RESERVATION.MODAL.TITLE"
          className="titleContent"
        />
        <ViewerCounterStyled
          placeAvailable={event?.placeAvailable || 100}
          placeReserved={event?.placeReserved || 60}
        />

        <FormBorder spacing={4}>
          <GridElem spacing={2} style={{ justifyItems: 'center' }}>
            <TextElem
              style={{ lineHeight: '150%' }}
              size="main"
              type="semi-bold"
              color="textSecondary"
              tid="SESSION.VIEWER.RESERVATION.MODAL.DESCRIPTION"
            />
          </GridElem>
          {formVisible ? (
            <Form
              formik={formik}
              isFieldError={isFieldError}
              getFieldError={getFieldError}
              getFieldValue={getFieldValue}
            />
          ) : (
            <FakeField
              name={name}
              phone={phone}
              setFormVisible={setFormVisible}
            />
          )}
          <ButtonElem
            tid="SESSION.VIEWER.RESERVATION.MODAL.BUTTON"
            type="submit"
            iconRight={ReservationIcon}
            style={{ whiteSpace: 'nowrap' }}
          />

          <PopoverElem />
        </FormBorder>
        <GridElem spacing={3}>
          {webinarData?.webgift?.map((item: WEBGIFT_ITEM_DATA_RAW) => (
            <GiftCard spacing={4} key={item.id}>
              <ImageContainer>
                <Image src={item.photo?.url} />
              </ImageContainer>
              <GridElem
                style={{
                  alignItems: 'start',
                  justifyContent: 'start',
                  textAlign: 'start',
                }}
                spacing={2}
              >
                <TextElem type="semi-bold" size="main">
                  {item.name}
                </TextElem>
                <TextElem
                  type="medium"
                  size="mobileDefault"
                  color="textPrimary"
                >
                  {item.description}
                </TextElem>
              </GridElem>
            </GiftCard>
          ))}
        </GridElem>
      </GridElem>
    </FormElem>
  );
};

const ImageContainer = styled(FlexElem)`
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
`;

const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;

export const ViewerCounterStyled = styled(ViewerCounter)`
  span {
    font-size: 14px;
  }
  @media screen and (width<700px) {
    height: 48px;

    svg {
      height: 14px;
      width: 14px;
    }
    span {
      font-size: 14px;
    }
  }
`;

const GiftCard = styled(FlexElem)`
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  padding: ${Spacing(3)};
  border-radius: 12px;
`;
