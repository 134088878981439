import React, { useState } from 'react';

import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM } from './constant';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import { i18n } from '../../lib/lang';
import styled from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  setFieldValue,
}) => {
  return (
    <FormElem onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}

      <GridElem spacing={4}>
        <FieldText
          name={FORM_VALUE_ENUM.NAME}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          title="SESSION.VIEWER.JOIN.FORM.NAME"
          value={getFieldValue(FORM_VALUE_ENUM.NAME)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
          error={isFieldError(FORM_VALUE_ENUM.NAME)}
        />

        <FieldText
          name={FORM_VALUE_ENUM.PHONE}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          title="SESSION.VIEWER.JOIN.FORM.PHONE"
          value={getFieldValue(FORM_VALUE_ENUM.PHONE)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.PHONE)}
          error={isFieldError(FORM_VALUE_ENUM.PHONE)}
          type="phone"
        />
        <ButtonElem
          type="submit"
          tid="SESSION.VIEWER.JOIN.FORM.BUTTON"
          fill="solid"
          disabled={isSubmitDisabled()}
        />
      </GridElem>

      {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
    </FormElem>
  );
};

const FieldText = styled(FieldTextElem)`
  && input {
    :focus-within {
      background: ${({ theme }) => theme[COLOR_ENUM.INPUT_BACKGROUND]};
    }

    :hover {
      background: ${({ theme }) =>
        theme[COLOR_ENUM.DEFAULT] === '#1B1924'
          ? theme[COLOR_ENUM.INPUT_BACKGROUND]
          : theme[COLOR_ENUM.INPUT]};
    }
    background: ${({ theme }) =>
      theme[COLOR_ENUM.DEFAULT] === '#1B1924'
        ? theme[COLOR_ENUM.INPUT_BACKGROUND]
        : theme[COLOR_ENUM.INPUT]};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
    -webkit-box-shadow: 0 0 0px 1000px
      ${({ theme }) =>
        theme[COLOR_ENUM.DEFAULT] === '#1B1924'
          ? theme[COLOR_ENUM.INPUT_BACKGROUND]
          : theme[COLOR_ENUM.INPUT]}
      inset;
  }
`;
