import React from 'react';
import { useMutation, useQuery } from 'react-query';

import { ACTION_ERROR_INTER } from './constant';
import { Component } from './component';
import { WEBINAR_ITEM_DATA_MODULE_NAME } from '../webinar-item-data';
import { WEBINAR_ITEM_DATA } from '../../data/webinar/constant';
import { updateWebinar } from '../webinar-item-update-general/action';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import {
  WEBINAR_UPDATE_FORM_VALUE_INTER,
  WEBINAR_UPDATE_FORM_VALUE_ENUM,
} from '../webinar-item-update-general';

export const Container: React.FC<{ webinarId: string }> = ({ webinarId }) => {
  const preFetch = useQuery([WEBINAR_ITEM_DATA_MODULE_NAME, webinarId], {
    retry: 0,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const action = useMutation(
    (values: WEBINAR_UPDATE_FORM_VALUE_INTER) =>
      updateWebinar(webinarId, values),
    {},
  );

  const config = {
    [WEBINAR_UPDATE_FORM_VALUE_ENUM.PREVIEW]: [],
    [WEBINAR_UPDATE_FORM_VALUE_ENUM.VIDEO]: [],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const isLoading = () => {
    if (preFetch.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (preFetch.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = preFetch.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const getData = (): WEBINAR_ITEM_DATA | undefined => {
    const data = preFetch.data as unknown as WEBINAR_ITEM_DATA;
    if (data) {
      return data;
    }
  };

  const data = getData();

  const initialValues =
    isSuccess() && data
      ? {
          [WEBINAR_UPDATE_FORM_VALUE_ENUM.PREVIEW]: data?.preview?.id,
          [WEBINAR_UPDATE_FORM_VALUE_ENUM.VIDEO]: data?.video?.id,
        }
      : {
          [WEBINAR_UPDATE_FORM_VALUE_ENUM.PREVIEW]: '',
          [WEBINAR_UPDATE_FORM_VALUE_ENUM.VIDEO]: '',
        };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: (values: WEBINAR_UPDATE_FORM_VALUE_INTER) => {
      return action.mutate(values);
    },
  });

  const onSuccessUpload = (id: string, name: string) => {
    console.log(name, id);
    formik.setFieldValue(name, id);
    formik.handleSubmit();
  };

  return (
    <Component
      data={data}
      isLoading={isLoading()}
      isError={isError()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
      formik={formik}
      onSuccessUpload={onSuccessUpload}
    />
  );
};
