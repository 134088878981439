import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const getSessionList = (webinarId: string) => {
  return HttpRequest({
    method: API.LIST.TYPE,
    url: API.LIST.URL(webinarId),
  });
};

export const getByWebinarSessionList = (params: string) => {
  return HttpRequest({
    method: API.LIST_2.TYPE,
    url: API.LIST_2.URL(params),
  });
};

export const getViewerDateList = (sessionId: string) => {
  return HttpRequest({
    method: API.VIEWER_DATE.TYPE,
    url: API.VIEWER_DATE.URL(sessionId),
  });
};
