import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const action = ({
  sessionId,
  category,
  search,
  range,
  session,
  date,
  pageParam,
}: any) => {
  let params = '';
  if (search) params += `&search=${search}`;
  if (category) params += `&${category}`;
  if (date) params += `&dates=${date}`;
  if (range) params += `&range=${range}`;

  // if (session) params += `&session=${session}`;
  if (pageParam) params += `&skip=${pageParam}`;

  return HttpRequest({
    method: API.GET.TYPE,
    url: API.GET.URL(sessionId, params),
  });
};

export const getOnlineAction = (sessionId: string) => {
  return HttpRequest({
    method: API.GET_ONLINE.TYPE,
    url: API.GET_ONLINE.URL(sessionId),
  });
};
