import { WEBINAR_ITEM_DATA, WEBINAR_ITEM_DATA_RAW } from '../webinar/constant';

export interface PROJECT_ITEM_DATA_RAW {
  id: string;
  createDate: string;
  name: string;
  webinar: WEBINAR_ITEM_DATA_RAW[];
  smsAlphaName: string;
}

export interface PROJECT_ITEM_LIST_DATA_RAW {
  list: PROJECT_ITEM_DATA_RAW[];
}

export interface PROJECT_ITEM_DATA extends PROJECT_ITEM_DATA_RAW {
  webinar: WEBINAR_ITEM_DATA[];
}

export interface PROJECT_ITEM_LIST_DATA {
  list: PROJECT_ITEM_DATA[];
  isEmpty: boolean;
}

export const API = {
  LIST: {
    TYPE: 'GET',
    URL: '/project/list',
  },
};
