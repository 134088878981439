import React, { FunctionComponent, SVGProps, useRef, useState } from 'react';
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Area,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import styled from 'styled-components';
import { CustomTooltip } from './frame/tooltip';
import { useSelector } from '../../lib/store';
import { THEME_MODULE_NAME } from '../../data/theme';
import {
  COLOR_ENUM,
  COLOR_TYPE,
  DARK_COLOR_DATA,
  LIGHT_COLOR_DATA,
} from '../../theme/color';
import { THEME_ENUM } from '../../data/theme/constant';
import { TextElem } from '../text';
import { GridElem } from '../grid';
import { FlexElem } from '../flex';
import { ContentContainerElem } from '../content-container';
import { Spacing } from '../../theme';
import { useOnClickOutside } from 'usehooks-ts';

import ReactDOM from 'react-dom';
import { ReactComponent as CloseIcon } from '../../asset/svg/common/close.svg';
import { CHART_AREA_DATA_TYPE } from './constant';

export const Elem: React.FC<{
  data: CHART_AREA_DATA_TYPE[];
  color?: COLOR_TYPE;
  IconX?: FunctionComponent<SVGProps<SVGSVGElement>>;
  IconY?: FunctionComponent<SVGProps<SVGSVGElement>>;
  DetailDataContainer?: React.FC<{ data: any }>;
  title: string;
  subTitle: string;
  modalData?: any;
}> = ({
  color = 'success',
  IconX,
  IconY,
  data,
  DetailDataContainer,
  title,
  subTitle,
  modalData,
}) => {
  const [detailData, setDetailData] = useState(null);

  const { theme: themeType } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));

  const theme =
    themeType.type === THEME_ENUM.LIGHT ? LIGHT_COLOR_DATA : DARK_COLOR_DATA;

  const handleChartClick = (e: any) => {
    if (e && e.activePayload && DetailDataContainer) {
      setDetailData({ ...e.activePayload[0].payload, modalData });
    }
  };

  const onClose = () => {
    setDetailData(null);
  };

  const ref = useRef(null);

  const handleClickOutside = () => {
    onClose();
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <Container>
      {DetailDataContainer && !!detailData && (
        <>
          {ReactDOM.createPortal(
            <ModalWrapper>
              <CustomModal ref={ref}>
                <GridElem spacing={5} style={{ overflow: 'hidden' }}>
                  <GridElem style={{ gridTemplateColumns: 'auto 16px' }}>
                    <TextElem
                      tid="Переглянути фрагмент"
                      size="main"
                      type="semi-bold"
                    />
                    <CloseIconStyled onClick={onClose} />
                  </GridElem>

                  <DetailDataContainer data={detailData} />
                </GridElem>
              </CustomModal>
            </ModalWrapper>,
            document.body,
          )}
        </>
      )}
      <Header>
        <TextElem size="label" type="semi-bold" tid={title}></TextElem>
        <TextElem size="small" tid={subTitle} color="textThird"></TextElem>
      </Header>
      <Wrapper>
        <div
          style={{
            width: `${data.length * 70 + 90}px`,
            minWidth: '100%',
            height: '320px',
          }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={data}
              onClick={handleChartClick}
              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="0%"
                    stopColor={theme[color]}
                    stopOpacity={0.4}
                  />
                  <stop
                    offset="65%"
                    stopColor={theme[color]}
                    stopOpacity={0.1}
                  />
                </linearGradient>
              </defs>

              <XAxis
                tickSize={0}
                tickMargin={12}
                dataKey="X"
                tick={{
                  // width: 70,
                  fill: `${theme[COLOR_ENUM.TEXT_THIRD]}`,
                  fontSize: 12,
                }}
              />
              <YAxis
                tickSize={0}
                tickMargin={12}
                tick={{ fill: `${theme[COLOR_ENUM.TEXT_THIRD]}`, fontSize: 12 }}
              />

              <CartesianGrid
                stroke={theme[COLOR_ENUM.INPUT]} // цвет линий сетки
                strokeDasharray="none" // сплошные линии
                strokeWidth={1} // толщина линий сетки
              />
              <Tooltip
                content={<CustomTooltip IconX={IconX} IconY={IconY} />}
                cursor={{
                  stroke: `${theme[COLOR_ENUM.ERROR]}`,
                  strokeWidth: 1,
                }}
              />

              <Area
                type="monotone"
                dataKey="Y"
                stroke={theme[color]}
                fillOpacity={1}
                activeDot={{
                  fill: `${theme[color]}`,
                  strokeWidth: 0,
                  r: 5,
                }}
                fill="url(#colorPv)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </Wrapper>
    </Container>
  );
};
const Container = styled(ContentContainerElem)`
  width: 100%;
  max-width: 100%;
`;

const Header = styled(FlexElem)`
  position: sticky;
  top: 0;
  z-index: 10;
  justify-content: space-between;
  @media screen and (width<660px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

const Wrapper = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(20, 18, 26, 0.8);

  @media screen and (width<620px) {
    align-items: end;
    border-radius-bottom: 0;
  }
  cursor: pointer;
`;

const CustomModal = styled.div`
  display: block;
  max-height: 80dvh;
  height: auto;
  padding: ${Spacing(6)} ${Spacing(4)};
  width: 620px;
  @media screen and (width<620px) {
    align-items: end;
    border-radius: 16px 16px 0 0;
  }
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  border-radius: 16px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
`;

const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
  path {
    transition: all 0.2s;
  }
  :hover {
    path {
      stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
    }
  }
`;
