import React from 'react';
import { useMutation } from 'react-query';
import { LoaderElem } from '../../common/loader';
import { ACTION_ERROR_INTER } from './constant';
import { AlertActionElem } from '../../common/alert-action';
import styled from 'styled-components';

import { action } from './action';
import { ToggleElem } from '../../common/toggle';
import { COLOR_ENUM } from '../../theme/color';

export const Container: React.FC<{
  sessionId: string;
  preModerationActive: boolean;
}> = ({ sessionId, preModerationActive }) => {
  const actionMutate = useMutation(() => action(sessionId), {});

  const isLoading = () => {
    if (actionMutate.isLoading) {
      return true;
    }
  };

  const actionHandleClick = (e: any) => {
    actionMutate.mutate();
  };

  const isError = () => {
    if (actionMutate.isError && !actionMutate.isLoading && getErrorMessage())
      return true;
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = actionMutate.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };
  return (
    <Wrapper>
      {isLoading() && <LoaderElem />}

      <ToggleElem
        handleClick={actionHandleClick}
        tid="Pre-moderation"
        disabled={isLoading()}
        checked={preModerationActive}
      />

      {isError() && <AlertActionElem tid={getErrorMessage()} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-radius: 10px;
  padding: 9.5px 16px;
  background: ${({ theme }) => theme[COLOR_ENUM.INPUT]};
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]};
`;
