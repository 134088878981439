export const MODULE_NAME = 'SESION_ITEM_MODERATION_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  GET: {
    TYPE: 'GET',
    URL: (sessionId: string) => `/session/${sessionId}`,
  },
};

export const TAB_LIST = [
  {
    tid: 'All',
    value: 'ALL',
  },
  {
    tid: 'Moderation',
    value: 'MODERATION',
  },
];

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
