import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const action = (
  { sessionId, sort, type, person, pageParam, startTime }: any,
  search?: string,
) => {
  let params = '';
  if (sort) params += `&sort=${sort}`;
  if (startTime) params += `&startTime=${startTime}`;

  if (pageParam) params += `&skip=${pageParam}`;
  if (search) params += `&search=${search}`;
  if (type) {
    type?.map((item: any) => (params += `&type=${item.value}`));
  }

  if (person) {
    person?.map((item: any) => (params += `&person=${item.value}`));
  }

  return HttpRequest({
    method: API.TYPE,
    url: API.URL(sessionId, params),
  });
};
