import VisitedIcon from '../../asset/svg/analytic/status/visit.svg';
import ButtonSeen from '../../asset/svg/analytic/status/button-seen.svg';
import ButtonTouched from '../../asset/svg/analytic/status/button-touched.svg';
import Application from '../../asset/svg/analytic/status/application.svg';
import ViewerIcon from '../../asset/svg/analytic/card/viewer.svg';
import UnicViewerIcon from '../../asset/svg/analytic/card/unic-viewer.svg';
import MaxIcon from '../../asset/svg/analytic/card/max.svg';

import { calculatePercentage } from '../../lib/util/calculatePercentage';
import { RESERVATION_SOURCE } from './constant';
import moment from 'moment';
import { VIEWER_ITEM_DATA_RAW } from '../viewer/constant';
import { convertDate } from '../../lib/lang/service';
import { i18n } from '../../lib/lang';
import { CARD_DATA } from '../../common/card-info/constant';
import { CHART_AREA_DATA_TYPE } from '../../common/chart-area/constant';

export const convertViewerListToDetailContainer = (
  viewerList: VIEWER_ITEM_DATA_RAW[],
) => {
  const sessionsSet = new Set();

  // Проходим по каждому зрителю и добавляем уникальные сессии в Set
  viewerList.forEach((viewer) => {
    if (
      viewer.session &&
      viewer.session.name &&
      viewer.session.webinar &&
      viewer.session.webinar.name
    ) {
      const sessionData = {
        name: viewer.session.name,
        webinarName: viewer.session.webinar.name,
        time: convertDate(viewer.session.date),
        timeZone: viewer.session.timeZone,
        sessionAuto: viewer.session.auto,
        videoUrl: viewer.session.webinar?.video?.url,
        youtubeUrl: viewer.session.webinar?.youtubeLink,
      };

      // Добавляем сериализованную сессию в Set для уникальности
      sessionsSet.add(JSON.stringify(sessionData));
    }
  });

  return Array.from(sessionsSet).map((sessionString) =>
    JSON.parse(sessionString as string),
  );
};

export function convertConnectByTime(
  viewers: VIEWER_ITEM_DATA_RAW[],
): CHART_AREA_DATA_TYPE[] {
  const timeMap: Record<number, number> = {}; // Временная карта {время: количество зрителей}

  viewers.forEach((viewer) => {
    const sessionDate = new Date(viewer.sessionDate).getTime();

    viewer.connectDate.forEach((connect) => {
      const connectTime = new Date(connect).getTime();
      const sessionTime = Math.floor((connectTime - sessionDate) / 1000); // Разница в секундах

      // Учитываем только время от -3600 до +∞ (не более часа до начала сессии)
      if (sessionTime > -3600) {
        timeMap[sessionTime] = (timeMap[sessionTime] || 0) + 1;
      }
    });
  });

  // Сортировка по времени (ascending)
  const sortedData = Object.entries(timeMap)
    .map(([time, viewers]) => ({
      rawTime: Number(time),
      X: convertMillisecondsToMMSS(Number(time)), // Конвертация в формат hh:mm:ss
      Y: viewers,
    }))
    .sort((a, b) => a.rawTime - b.rawTime); // Сортировка по времени

  // Накопление количества зрителей
  let accumulatedViewers = 0;
  const graphData = sortedData.map((data) => {
    accumulatedViewers += data.Y; // Накопление зрителей
    return {
      ...data,
      Y: accumulatedViewers, // Накопленное количество зрителей
    };
  });

  return graphData;
}

export const convertViewerRatioReservation = (data: {
  viewer: number;
  reservation: number;
}) => {
  return [
    { name: 'Не залишили заявку', value: data.viewer },
    { name: 'Залишили заявку', value: data.reservation },
  ];
};

function convertMillisecondsToMMSS(seconds: number): string {
  const isNegative = seconds < 0; // Проверяем, является ли значение отрицательным
  const absSeconds = Math.abs(seconds); // Работаем с абсолютным значением для расчётов

  const hours = Math.floor(absSeconds / 3600);
  const minutes = Math.floor((absSeconds % 3600) / 60);
  const remainingSeconds = absSeconds % 60;

  // Форматируем результат в -hh:mm:ss
  return `${isNegative ? '-' : ''}${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

export const convertReservationByTime = (data: { X: number; Y: number }[]) => {
  return data.map((item: any) => ({
    X: convertMillisecondsToMMSS(item.X),
    Y: item.Y,
  }));
};

export const convertReservationSource = (data: {
  [key in RESERVATION_SOURCE]: number;
}) => {
  return [
    {
      name: `ANALYTICS.CHART.SOURCE.${RESERVATION_SOURCE.SESSION}`,
      value: data[RESERVATION_SOURCE.SESSION],
    },
    {
      name: `ANALYTICS.CHART.SOURCE.${RESERVATION_SOURCE.OVER_SESSION}`,
      value: data[RESERVATION_SOURCE.OVER_SESSION],
    },
    {
      name: `ANALYTICS.CHART.SOURCE.${RESERVATION_SOURCE.INACTIVE_SESSION}`,
      value: data[RESERVATION_SOURCE.INACTIVE_SESSION],
    },
  ];
};

export const convertViewerRatioReservationPaid = (data: {
  viewer: number;
  reservation: number;
}) => {
  return [
    { name: 'Не залишили заявку', value: data.viewer },
    { name: 'Залишили заявку', value: data.reservation },
  ];
};

export const convertViewerRatioMaxViewer = (data: VIEWER_ITEM_DATA_RAW[]) => {
  return [
    { name: 'Кількість унікальних глядачів загалом', value: data.length },
    { name: 'Максимум глядачів одночасно', value: getMaxViewers(data) },
  ];
};

function timeToSeconds(time: string): number {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}

interface Viewer {
  duration: string[][]; // массив интервалов времени вида [['start', 'end'], ['start', 'end'], ...]
}

interface Event {
  time: number;
  type: 'enter' | 'exit';
}

function calculateAverageMaxViewers(viewers: VIEWER_ITEM_DATA_RAW[]): number {
  // Группируем зрителей по session.id
  const sessions = viewers.reduce<Record<string, Viewer[]>>((acc, viewer) => {
    const sessionId = viewer.session.id;
    if (!acc[sessionId]) acc[sessionId] = [];
    acc[sessionId].push(viewer);
    return acc;
  }, {});

  // Считаем максимум зрителей для каждой сессии
  const sessionStats: any[] = Object.entries(sessions).map(
    ([sessionId, sessionViewers]) => ({
      sessionId,
      maxViewers: sessionViewers.length, // Число зрителей в сессии
    }),
  );

  // Вычисляем среднее арифметическое максимумов
  const totalMaxViewers = sessionStats.reduce(
    (sum, { maxViewers }) => sum + maxViewers,
    0,
  );
  const averageMaxViewers = totalMaxViewers / sessionStats.length;

  return parseFloat(averageMaxViewers.toFixed(1)) || 0;
}

function removeDuplicateViewersByIp(viewers: VIEWER_ITEM_DATA_RAW[]): number {
  const uniqueIps = new Set<string>(); // Набор для хранения уникальных IP (преобразованных в строку)
  const uniqueViewers: Viewer[] = []; // Результирующий массив зрителей

  for (const viewer of viewers) {
    // Сортируем IP для стабильного сравнения, приводим к строке
    const ipKey = viewer.ip.sort().join(',');

    if (!uniqueIps.has(ipKey)) {
      uniqueIps.add(ipKey); // Добавляем уникальный IP в Set
      uniqueViewers.push(viewer); // Добавляем зрителя в результат
    }
  }

  return uniqueViewers.length;
}

export const getCardInfo = (list: VIEWER_ITEM_DATA_RAW[]): CARD_DATA[] => {
  return [
    {
      icon: ViewerIcon,
      text: `${list.length}`,
      title: i18n.t('ANALYTICS.CARD.ALL_VIEWERS'),
    },
    {
      icon: UnicViewerIcon,
      text: `${removeDuplicateViewersByIp(list)}`,
      title: i18n.t('ANALYTICS.CARD.UNIC_VIEWERS'),
    },
    {
      icon: MaxIcon,
      text: `${calculateAverageMaxViewers(list)}`,
      title: i18n.t('ANALYTICS.CARD.MAX_VIEWERS'),
    },
  ];
};

function getMaxViewers(viewerList: Viewer[]): number {
  const events: Event[] = [];

  // Проходим по всем зрителям и собираем события входа и выхода
  for (const viewer of viewerList) {
    for (const [start, end] of viewer.duration) {
      events.push({ time: timeToSeconds(start), type: 'enter' });
      events.push({ time: timeToSeconds(end), type: 'exit' });
    }
  }

  // Сортируем события: сначала по времени, затем по типу события (exit перед enter)
  events.sort(
    (a: Event, b: Event) => a.time - b.time || (a.type === 'exit' ? -1 : 1),
  );

  let currentViewers: number = 0;
  let maxViewers: number = 0;

  // Проходим по событиям и отслеживаем количество зрителей
  for (let i = 0; i < events.length; i++) {
    const event: Event = events[i];

    if (event.type === 'enter') {
      currentViewers++;
      // Если текущее количество зрителей больше максимума, обновляем максимум
      if (currentViewers > maxViewers) {
        maxViewers = currentViewers;
      }
    } else {
      currentViewers--;
    }
  }

  return maxViewers;
}

export const converViewerOpenBannerRatioReservation = (data: {
  viewer: number;
  reservation: number;
}) => {
  return [
    {
      name: `Не залишили заявку`,
      value: data.viewer,
    },
    {
      name: `Залишили заявку`,
      value: data.reservation,
    },
  ];
};

export const converStepStatus = (data: VIEWER_ITEM_DATA_RAW[]) => {
  const analyticsData = {
    totalUsers: data?.length || 0,
    isButtonSee: 0,
    isButtonOpen: 0,
    reservation: 0,
  };

  data.forEach((viewer: any) => {
    if (viewer?.reservation) {
      analyticsData.reservation++;
    }
    if (viewer?.isButtonOpen) {
      analyticsData.isButtonOpen++;
    }
    if (viewer?.isButtonSee) {
      analyticsData.isButtonSee++;
    }
  });

  return [
    {
      status: 'STATUS_VIEWED_WEBINAR',
      icon: VisitedIcon,
      title: 'ANALYTICS.STATUS.VIEWED_WEBINAR',
      text1: `${analyticsData.totalUsers}`,
      text2: '100%',
    },
    {
      status: 'STATUS_BUTTON_NOT_PRESSED',
      icon: ButtonSeen,
      title: 'ANALYTICS.STATUS.BUTTON_NOT_PRESSED',
      text1: `${analyticsData.isButtonSee}`,
      text2: `${calculatePercentage(
        analyticsData.isButtonSee,
        analyticsData.totalUsers,
      )}%`,
    },
    {
      status: 'STATUS_BUTTON_PRESSED',
      icon: ButtonTouched,
      title: 'ANALYTICS.STATUS.BUTTON_PRESSED',
      text1: `${analyticsData.isButtonOpen}`,
      text2: `${calculatePercentage(
        analyticsData.isButtonOpen,
        analyticsData.totalUsers,
      )}%`,
    },
    {
      status: 'STATUS_SENT',
      icon: Application,
      title: 'ANALYTICS.STATUS.SENT',
      text1: `${analyticsData.reservation}`,
      text2: `${calculatePercentage(
        analyticsData.reservation,
        analyticsData.totalUsers,
      )}%`,
      active: true,
    },
  ];
};
