import React from 'react';
import { useMutation } from 'react-query';
import { LoaderElem } from '../../common/loader';
import { ACTION_ERROR_INTER } from './constant';
import { AlertActionElem } from '../../common/alert-action';
import { ButtonElem } from '../../common/button';

import approveIcon from '../../asset/svg/session/moderation/approve.svg';
import deleteIcon from '../../asset/svg/session/moderation/delete.svg';
import { approveAction, deleteAction } from './action';
import { FlexElem } from '../../common/flex';
import { IonSpinner } from '@ionic/react';
import { LoaderIconElem } from '../../common/loader-icon';

export const Container: React.FC<{
  messageId: string;
  sessionId: string;
}> = ({ messageId, sessionId }) => {
  const approveMutate = useMutation(() => approveAction(messageId), {});

  const deleteMutate = useMutation(() => deleteAction(messageId), {});

  const isLoading = () => {
    if (deleteMutate.isLoading || approveMutate.isLoading) {
      return true;
    }
  };

  const onClickDelete = (e: any) => {
    deleteMutate.mutate();
  };

  const onClickApprove = (e: any) => {
    approveMutate.mutate();
  };

  const isError = () => {
    if (
      (approveMutate.isError &&
        !approveMutate.isLoading &&
        getErrorMessage()) ||
      (deleteMutate.isError && !deleteMutate.isLoading && getErrorMessage())
    ) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER =
      (deleteMutate.error as ACTION_ERROR_INTER) || approveMutate;
    if (error) {
      return error.message;
    }
  };
  return (
    <FlexElem spacing={1} style={{ width: 'min-content' }}>
      {isLoading() && <LoaderElem />}

      {approveMutate.isLoading ? (
        <LoaderIconElem />
      ) : (
        <ButtonElem
          onClick={onClickApprove}
          iconRight={approveIcon}
          disabled={isLoading()}
          fill="clear"
        />
      )}

      {deleteMutate.isLoading ? (
        <LoaderIconElem />
      ) : (
        <ButtonElem
          onClick={onClickDelete}
          iconRight={deleteIcon}
          disabled={isLoading()}
          fill="clear"
        />
      )}

      {isError() && <AlertActionElem tid={getErrorMessage()} />}
    </FlexElem>
  );
};
