import React from 'react';
import styled from 'styled-components';
import { ContentContainerElem } from '../../common/content-container';
import { Spacing } from '../../theme';
import { GridElem } from '../../common/grid';
import { TextElem } from '../../common/text';
import { LoaderElem } from '../../common/loader';
import { ReactComponent as PersonIcon } from '../../asset/svg/analytic/chart/person.svg';
import { ReactComponent as ClockIcon } from '../../asset/svg/analytic/chart/clock.svg';
import { ChartAreaElem } from '../../common/chart-area';
import { ChartPieElem } from '../../common/chat-pie';
import { DoubleContainerElem } from '../../common/double-container';
import { FlexElem } from '../../common/flex';
import { ChartBarElem } from '../../common/chart-bar';
import {
  converStepStatus,
  convertConnectByTime,
  convertReservationByTime,
  convertReservationSource,
  convertViewerListToDetailContainer,
  convertViewerRatioMaxViewer,
  convertViewerRatioReservation,
  convertViewerRatioReservationPaid,
  converViewerOpenBannerRatioReservation,
  getCardInfo,
} from '../../data/analytic/convert';
import { DetailDataContainer } from './frame/detailContainer';
import { AnalyticStatusElem } from '../../common/analytic-status';
import { FormikValues } from 'formik';
import { AnalyticFilterContainer } from './frame/filter';
import { CardInfoElem } from '../../common/card-info';

export const Component: React.FC<{
  data?: any;
  isLoading?: boolean;
  isSuccess?: boolean;
  // isSuccess?: boolean;
  // isError?: boolean;
  // errorMessage?: string;
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  onRemove: Function;
}> = ({
  data,
  isLoading,
  // isError,
  // errorMessage,
  isSuccess,
  formik,
  isFieldError,
  getFieldError,
  getFieldValue,
  onRemove,
}) => {
  return (
    <Wrapper>
      <GridElem spacing={4}>
        <AnalyticFilterContainer
          formik={formik}
          isFieldError={isFieldError}
          getFieldError={getFieldError}
          getFieldValue={getFieldValue}
          onRemove={onRemove}
        />
        {isSuccess && data && !isLoading && (
          <>
            <GridElem style={{ display: 'flex', flexDirection: 'column' }}>
              <ChartAreaElem
                IconX={ClockIcon}
                IconY={PersonIcon}
                data={convertReservationByTime(data.reservationByTime)}
                DetailDataContainer={DetailDataContainer}
                title="ANALYTICS.CHART.RESERVATION.TITLE"
                subTitle="ANALYTICS.CHART.RESERVATION.SUB_TITLE"
                modalData={convertViewerListToDetailContainer(data.list)}
              />
            </GridElem>
            <GridElem style={{ display: 'flex', flexDirection: 'column' }}>
              <ChartAreaElem
                IconX={ClockIcon}
                IconY={PersonIcon}
                data={data.disconnectByTime}
                DetailDataContainer={DetailDataContainer}
                title="ANALYTICS.CHART.DISCONNECT.TITLE"
                subTitle="ANALYTICS.CHART.DISCONNECT.SUB_TITLE"
                modalData={convertViewerListToDetailContainer(data.list)}
              />
            </GridElem>
            <GridElem style={{ display: 'flex', flexDirection: 'column' }}>
              <ChartAreaElem
                IconX={ClockIcon}
                IconY={PersonIcon}
                data={convertConnectByTime(data.list)}
                DetailDataContainer={DetailDataContainer}
                title="ANALYTICS.CHART.CONNECT.TITLE"
                subTitle="ANALYTICS.CHART.CONNECT.SUB_TITLE"
                modalData={convertViewerListToDetailContainer(data.list)}
              />
            </GridElem>
            <CardInfoElem
              title="ANALYTICS.CARD.TITLE"
              subTitle="ANALYTICS.CARD.SUB_TITLE"
              cardData={getCardInfo(data.list)}
            />
            <ContentContainer spacing={5}>
              <Header>
                <TextElem
                  size="label"
                  type="semi-bold"
                  tid="ANALYTICS.CHART.CONVERSIA.TITLE"
                ></TextElem>
                <TextElem
                  size="small"
                  tid="ANALYTICS.CHART.CONVERSIA.SUB_TITLE"
                  color="textThird"
                ></TextElem>
              </Header>
              <DoubleContainerStyled>
                <ChartPieElem
                  data={convertViewerRatioReservation(
                    data?.viewerRatioReservation,
                  )}
                  title="ANALYTICS.CHART.CONVERSIA.COUNT"
                />
                <ChartPieElem
                  data={convertReservationSource(data?.reservationSource)}
                  title="ANALYTICS.CHART.CONVERSIA.SOURCE"
                />
              </DoubleContainerStyled>
            </ContentContainer>
            <ContentContainer spacing={5}>
              <Header>
                <TextElem
                  size="label"
                  type="semi-bold"
                  tid="ANALYTICS.CHART.INTEREST.TITLE"
                ></TextElem>
                <TextElem
                  size="small"
                  tid="ANALYTICS.CHART.INTEREST.SUB_TITLE"
                  color="textThird"
                ></TextElem>
              </Header>
              <DoubleContainerStyled>
                <ChartPieElem
                  data={convertViewerRatioMaxViewer(data?.list)}
                  title="ANALYTICS.CHART.INTEREST.COUNT"
                />
                <ChartPieElem
                  data={converViewerOpenBannerRatioReservation(
                    data?.viewerOpenBannerRatioReservation,
                  )}
                  title="ANALYTICS.CHART.INTEREST.SOURCE"
                />
              </DoubleContainerStyled>
            </ContentContainer>
            <ContentContainer spacing={5}>
              <Header>
                <TextElem
                  size="label"
                  type="semi-bold"
                  tid="ANALYTICS.CHART.CONVERSIA_PAYEED.TITLE"
                ></TextElem>
                <TextElem
                  size="small"
                  tid="ANALYTICS.CHART.CONVERSIA_PAYEED.SUB_TITLE"
                  color="textPrimary"
                ></TextElem>
              </Header>
              <DoubleContainerStyled>
                <ChartPieElem
                  data={convertViewerRatioReservationPaid(
                    data?.viewerRatioReservationPaid,
                  )}
                  title="ANALYTICS.CHART.CONVERSIA_PAYEED.COUNT"
                />
                <ChartPieElem
                  title="ANALYTICS.CHART.CONVERSIA_PAYEED.SOURCE"
                  data={convertReservationSource(data?.reservationPaidSource)}
                />
              </DoubleContainerStyled>
            </ContentContainer>
            <AnalyticStatusElem
              title="ANALYTICS.STATUS.TITLE"
              subTitle="ANALYTICS.STATUS.SUB_TITLE"
              activeColor="analyticStatusActive"
              stepsData={converStepStatus(data?.list)}
            />
            <ContentContainerElem>
              <Header>
                <TextElem
                  size="label"
                  type="semi-bold"
                  tid="ANALYTICS.CHART.DEVICE.TITLE"
                ></TextElem>
                <TextElem
                  size="small"
                  tid="ANALYTICS.CHART.DEVICE.SUB_TITLE"
                  color="textThird"
                ></TextElem>
              </Header>
              <DoubleContainerStyled>
                <ChartBarElem
                  data={data?.langCounts}
                  title="ANALYTICS.CHART.DEVICE.LANG"
                />
                <ChartBarElem
                  data={data?.platformCounts}
                  title="ANALYTICS.CHART.DEVICE.PLATFORM"
                />
              </DoubleContainerStyled>
              <DoubleContainerStyled>
                <ChartBarElem
                  data={data?.osCounts}
                  title="ANALYTICS.CHART.DEVICE.OS"
                />
                <ChartBarElem
                  data={data?.browserCounts}
                  title="ANALYTICS.CHART.DEVICE.BROWSER"
                />
              </DoubleContainerStyled>
            </ContentContainerElem>
            <ContentContainer>
              <Header>
                <TextElem
                  size="label"
                  type="semi-bold"
                  tid="ANALYTICS.CHART.UTM.TITLE"
                ></TextElem>
                <TextElem
                  size="small"
                  tid="ANALYTICS.CHART.UTM.SUB_TITLE"
                  color="textThird"
                ></TextElem>
              </Header>
              <DoubleContainerStyled>
                <ChartBarElem data={data?.utmSourceCounts} title="Utm source" />
                <ChartBarElem data={data?.utmMediumCounts} title="Utm medium" />
              </DoubleContainerStyled>
              <DoubleContainerStyled>
                <ChartBarElem
                  data={data?.utmCampaingCounts}
                  title="Utm campaing"
                />
                <ChartBarElem data={data?.utmTermCounts} title="Utm term" />
              </DoubleContainerStyled>
              <ChartBarElem data={data?.utmContentCounts} title="Utm content" />
            </ContentContainer>
          </>
        )}

        {isLoading && <LoaderElem />}
        {!data && (
          <ContentContainerElem>
            <GridElem style={{ textAlign: 'center' }}>
              <TextElem type="semi-bold" size="label" tid="ANALYTICS.EMPTY.1" />
              <TextElem
                type="regular"
                size="small"
                tid="ANALYTICS.EMPTY.2"
                color="textPrimary"
              />
            </GridElem>
          </ContentContainerElem>
        )}
      </GridElem>
    </Wrapper>
  );
};

const DoubleContainerStyled = styled(DoubleContainerElem)`
  @media screen and (width<800px) {
    grid-template-columns: 1fr;
  }
`;

const ContentContainer = styled(ContentContainerElem)`
  padding: ${Spacing(5)};
`;

const Wrapper = styled.div`
  width: 950px;
  min-width: 950px;
  max-width: 950px;
  height: 100%;
  @media screen and (width<1000px) {
    min-width: calc(100vw - 60px);
    max-width: calc(100vw - 60px);
    width: calc(100vw - 60px);
  }

  @media screen and (width<500px) {
    min-width: calc(100vw - 40px);
    max-width: calc(100vw - 40px);
    width: calc(100vw - 40px);
  }
`;

const Header = styled(FlexElem)`
  justify-content: space-between;

  @media screen and (width<660px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;
