import React from 'react';

import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM } from './constant';

import { GridElem } from '../../common/grid';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import { i18n } from '../../lib/lang';
import { SelectPreloadContainer } from '../select-preload';
import { ContentContainerElem } from '../../common/content-container';
import { DoubleContainerElem } from '../../common/double-container';
import importIcon from '../../asset/svg/button/import.svg';
import importIconLight from '../../asset/svg/button/import-light-theme.svg';
import { getSessionList } from '../../data/session/action';
import { convertSessionListToSelect } from '../../data/session/convert';
import { CheckBoxElem } from '../../common/checkbox';
import { TextElem } from '../../common/text';
import { THEME_ENUM } from '../../data/theme/constant';
import { useSelector } from '../../lib/store';
import { THEME_MODULE_NAME } from '../../data/theme';
import { SELECT_OPTION_ITEM_DATA, SelectElem } from '../../common/select';
import { TYPE_OPTION_LIST } from '../event-filter/constant';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  handleChange: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue: Function;
  webinarId: string;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  handleChange,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  setFieldValue,
  webinarId,
}) => {
  const { theme } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));

  return (
    <FormElem onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}

      <GridElem spacing={5}>
        <ContentContainerElem>
          <GridElem spacing={5}>
            <TextElem tid="SESSION.IMPORT.TITLE" type="semi-bold" size="main" />
            <SelectPreloadContainer
              name={FORM_VALUE_ENUM.SESSION}
              action={getSessionList}
              convert={convertSessionListToSelect}
              formik={formik}
              onChange={setFieldValue}
              errorMessage={getFieldError(FORM_VALUE_ENUM.SESSION)}
              error={isFieldError(FORM_VALUE_ENUM.SESSION)}
              value={getFieldValue(FORM_VALUE_ENUM.SESSION)}
              placeholder="Choose session name"
              param={webinarId}
              dynamic
            />
          </GridElem>
          <GridElem spacing={4}>
            <CheckBoxElem
              name={FORM_VALUE_ENUM.PERSON}
              checked={
                getFieldValue(FORM_VALUE_ENUM.EVENT) ||
                getFieldValue(FORM_VALUE_ENUM.PERSON)
              }
              onChange={formik.handleChange}
              tid="SESSION.IMPORT.FORM.PERSON"
              disabled={getFieldValue(FORM_VALUE_ENUM.EVENT)}
            />
            <CheckBoxElem
              name={FORM_VALUE_ENUM.EVENT}
              checked={getFieldValue(FORM_VALUE_ENUM.EVENT)}
              onChange={formik.handleChange}
              tid="SESSION.IMPORT.FORM.EVENT"
            />
            {getFieldValue(FORM_VALUE_ENUM.EVENT) && (
              <SelectElem
                name={FORM_VALUE_ENUM.EVENT_TYPE}
                onChange={(name: string, value: SELECT_OPTION_ITEM_DATA[]) =>
                  formik.setFieldValue(name, value)
                }
                options={TYPE_OPTION_LIST}
                noAlertContainer
                hideSelectedOptions={false}
                value={getFieldValue(FORM_VALUE_ENUM.EVENT_TYPE)}
                isMulti
                placeholder="Type"
                closeMenuOnSelect={false}
                isClearable
              />
            )}
          </GridElem>
        </ContentContainerElem>

        <DoubleContainerElem>
          <ButtonElem
            tid="SESSION.IMPORT.BUTTON.IMPORT"
            disabled={isSubmitDisabled()}
            iconRight={
              theme.type === THEME_ENUM.LIGHT ? importIconLight : importIcon
            }
          />
        </DoubleContainerElem>

        {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
        {isSuccess && <AlertActionElem type="success" tid="Success" />}
      </GridElem>
    </FormElem>
  );
};
