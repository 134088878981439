import React from 'react';
import styled, { css } from 'styled-components';
import { Spacing } from '../../theme';

import { COLOR_ENUM, COLOR_TYPE } from '../../theme/color';
import { ContentContainerElem } from '../content-container';
import { GridElem } from '../grid';
import { FlexElem } from '../flex';
import { TextElem } from '../text';

import { STEP_DATA } from './constant';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';

export const Elem: React.FC<{
  title?: string;
  subTitle?: string;
  activeColor?: COLOR_TYPE;
  stepsData: STEP_DATA[];
}> = ({ title, subTitle, activeColor, stepsData }) => {
  return (
    <ContentContainerElem style={{ padding: `${Spacing(6)}` }}>
      <GridElem spacing={5}>
        <HeaderElem>
          <TextElem size="main" type="semi-bold" color="default" tid={title} />
          <TextElem
            size="small"
            type="regular"
            color="textThird"
            tid={subTitle}
          />
        </HeaderElem>

        <Container spacing={5}>
          <ProgressContainer>
            {stepsData.map((step, index) => (
              <ProgressCard
                key={index}
                active={step.active}
                activeColor={activeColor}
              >
                <Content>
                  <FlexElem spacing={1.5} style={{ justifyContent: 'center' }}>
                    <Img src={step.icon} />
                    <TextElem
                      size="default"
                      type="medium"
                      color="textSecondary"
                      tid={step.title}
                    />
                  </FlexElem>
                  <FlexElem spacing={1} style={{ justifyContent: 'center' }}>
                    <TextElem
                      size="main"
                      type="semi-bold"
                      color="textSecondary"
                      tid={step.text1}
                    />
                    <TextElem
                      size="main"
                      type="semi-bold"
                      color={step.active ? activeColor : 'textThird'}
                      tid={step.text2}
                    />
                  </FlexElem>
                </Content>
                <ProgressDot
                  status={step.status}
                  index={index}
                  totalSteps={stepsData.length}
                />
              </ProgressCard>
            ))}
          </ProgressContainer>

          <ProgressBar />
        </Container>
      </GridElem>
    </ContentContainerElem>
  );
};

const Container = styled(GridElem)`
  @media screen and (width<500px) {
    display: flex;
    align-items: stretch;
  }
`;

const HeaderElem = styled(FlexElem)`
  justify-content: space-between;
  @media screen and (width<660px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

const ProgressContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media screen and (width<880px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (width<500px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
  gap: ${Spacing(2)};
  align-items: center;
  position: relative;
`;

const ProgressCard = styled.div<{ active?: boolean; activeColor?: COLOR_TYPE }>`
  padding: ${Spacing(6)} ${Spacing(4)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  flex: 1;
  text-align: center;
  position: relative;

  ${({ theme }) => css`
    background-color: ${theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  `}

  ${({ active, activeColor, theme }) =>
    active &&
    css`
      border: 1px solid
        ${activeColor ? theme[activeColor] : theme[COLOR_ENUM.BORDER_SECONDARY]};
      background-color: ${theme[COLOR_ENUM.TRANSPARENT]};
    `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
  align-items: center;
  justify-content: center;
`;

const Img = styled.img`
  margin-bottom: 4px;
  width: 14px;
  height: 14px;
`;

const ProgressDot = styled.div<{
  status: string;
  index: number;
  totalSteps: number;
}>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  @media screen and (width<880px) {
    display: none;
  }

  background: ${({ status, theme }) => {
    switch (status) {
      case 'STATUS_VIEWED_WEBINAR':
        return theme[COLOR_ENUM.DOT_FIRST];
      case 'STATUS_BUTTON_NOT_PRESSED':
        return theme[COLOR_ENUM.DOT_SECOND];
      case 'STATUS_BUTTON_PRESSED':
        return theme[COLOR_ENUM.DOT_THIRD];
      case 'STATUS_SENT':
        return theme[COLOR_ENUM.DOT_FOURTH];
      default:
        return theme[COLOR_ENUM.DOT_FIRST];
    }
  }};
  position: absolute;
  bottom: -27px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  @media screen and (width<500px) {
    display: block;
    bottom: 50%;
    left: -21px;
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme[COLOR_ENUM.DIVIDER]};

  @media screen and (width<880px) {
    display: none;
  }
  @media screen and (width<500px) {
    display: block;
    width: 2px;
    height: auto;
    order: -1;
  }
`;
