// @ts-nocheck

import React, {
  useState,
  useRef,
  MouseEvent,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import { REACTION_RAW_TYPE, REACTION_TYPE } from '../../../data/event/constant';
import { GridElem } from '../../../common/grid';
import { TextElem } from '../../../common/text';
import { COLOR_DATA, COLOR_ENUM } from '../../../theme/color';

const EmojiRow: React.FC<{
  data: REACTION_RAW_TYPE;
  sendReaction: (emoji: string) => void;
  reactionActive: boolean;
}> = ({ data, sendReaction, reactionActive }) => {
  const [activeEmojis, setActiveEmojis] = useState<
    { emoji: string; id: number; x: number; y: number }[]
  >([]);
  const emojiRefs = useRef<HTMLDivElement[]>([]);
  const [prevQuantities, setPrevQuantities] = useState<{
    [key: string]: number;
  }>({});

  const handleClick = (
    emoji: string,
    index: number,
    event: MouseEvent<HTMLDivElement>,
  ) => {
    sendReaction(emoji);
  };

  const handleRest = (id: number) => {
    setActiveEmojis((prev) => prev.filter((emoji) => emoji.id !== id));
  };

  useEffect(() => {
    Object.entries(data).forEach(([key, { emoji, currentQuantity }]) => {
      if (
        prevQuantities[key] !== undefined &&
        prevQuantities[key] !== currentQuantity
      ) {
        const element = emojiRefs.current[Object.keys(data).indexOf(key)];

        if (!element) return;
        if (reactionActive) handleDisplayFlyingEmoji(element, key);
      }

      setPrevQuantities((prev) => ({ ...prev, [key]: currentQuantity }));
    });
  }, [data]);

  const overlayRef = useRef();

  const handleRemoveFlyingEmoji = useCallback((elem, node) => {
    if (!overlayRef.current) return;
    elem.removeChild(node);
  }, []);

  const handleDisplayFlyingEmoji = useCallback(
    (elem, emoji) => {
      if (!elem) {
        return;
      }

      const node = document.createElement('div');

      node.appendChild(document.createTextNode(emoji));
      node.className = 'emoji wiggle-1';
      node.style.transform = `rotate(${-30 + Math.random() * 60}deg)`;
      elem.appendChild(node);

      node.addEventListener('animationend', (e) => {
        handleRemoveFlyingEmoji(elem, node);
      });
    },
    [handleRemoveFlyingEmoji],
  );

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <EmojiRowContainer>
      {Object.values(data).map((item: REACTION_TYPE, index: number) => (
        <EmojiContainer spacing={0} onClick={() => sendReaction(item.emoji)}>
          <AbsoluteContainer
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              className="flying-emojis"
              ref={(el) => (emojiRefs.current[index] = el as HTMLDivElement)}
            ></div>
          </AbsoluteContainer>
          <Emoji id={item.emoji}>
            <TextElem>{item.emoji}</TextElem>
          </Emoji>
          <CounterContainer color="textThird">
            {item.currentQuantity}
          </CounterContainer>
        </EmojiContainer>
      ))}
    </EmojiRowContainer>
  );
};

const Emoji = styled.div`
  span {
    font-size: 40px;
  }
  width: 56px;
  height: 56px;
  z-index: 9999;
  @media screen and (width<=600px) {
    width: 32px;
    height: 32px;
    span {
      font-size: 24px;
    }
  }
`;

const EmojiContainer = styled(GridElem)`
  position: relative;
  justify-items: center;
  cursor: pointer;
`;

const CounterContainer = styled(TextElem)`
  padding: 2px 10px;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  border-radius: 6px;
  width: fit-content;
  height: 28px;
  align-content: center;
  padding-top: 6px;
  @media screen and (width<=600px) {
    background: ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};
    height: 14px;
    padding: 0px 10px;
    padding-top: 1px;
    font-size: 10px;
  }
`;

const EmojiRowContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  position: relative;
  width: fit-content;
`;

const AbsoluteContainer = styled.div`
  height: 500px;
  /* width: 70px; */
  position: absolute;
  bottom: 16px;
  left: 14px;

  .flying-emojis .emoji {
    font-size: 40px;
    left: 0;
    line-height: 1;
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 0;
    @media screen and (width<=600px) {
      width: 24px;
      height: 24px;
      font-size: 24px;
    }
  }

  .flying-emojis .emoji.wiggle-1 {
    animation: emerge 3s forwards, wiggle-1 1s ease-in-out infinite alternate;
  }

  @keyframes emerge {
    to {
      bottom: 85%;
      opacity: 0;
    }
  }

  @keyframes wiggle-1 {
    from {
      margin-left: -20px;
    }
    to {
      margin-left: 50px;
    }
  }
`;

const AnimatedEmoji = styled.div`
  font-size: 48px;
  left: 0;
  line-height: 1;
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 0;

  /* animation: emerge 3s forwards, wiggle 1s ease-in-out infinite alternate; */

  @keyframes emerge {
    to {
      bottom: 85%;
      opacity: 0;
    }
  }

  @keyframes wiggle {
    from {
      margin-left: -20px;
    }
    to {
      margin-left: 50px;
    }
  }
`;

export default EmojiRow;
