import React from 'react';

import { Component } from './component';

import { SESSION_ITEM_DATA } from '../../data/session/constant';

export const Container: React.FC<{
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  data?: SESSION_ITEM_DATA;
  modalDefault?: boolean;
  withoutButtons?: boolean;
  setModalVisibleDefault?: Function;
}> = ({
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  data,
  modalDefault = false,
  withoutButtons = false,
  setModalVisibleDefault,
}) => {
  return (
    <Component
      isError={isError}
      isLoading={isLoading}
      isSuccess={isSuccess}
      errorMessage={errorMessage}
      data={data}
      modalDefault={modalDefault}
      withoutButtons={withoutButtons}
      setModalVisibleDefault={setModalVisibleDefault}
      stepperList={data?.timecode}
    />
  );
};
