import React, { useEffect, useState } from 'react';
import { i18n } from '../../lib/lang';
import { PROPS_TYPE } from './constant';
import { SelectAsyncElem } from '../../common/select-async';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { getIn } from 'formik';

export const Container: React.FC<PROPS_TYPE> = ({
  action,
  convert,
  formik,
  param,
  value,
  isMulti,
  name,
  ...props
}) => {
  const [selectedOption, setSelectedOption] = useState<
    SELECT_OPTION_ITEM_DATA[] | null
  >(isMulti ? [] : null);
  const [initialOptions, setInitialOptions] = useState<
    SELECT_OPTION_ITEM_DATA[]
  >([]);
  const [isLoading, setIsLoading] = useState(true); // Добавлено состояние загрузки

  // Функция для загрузки опций
  const loadOptions = async (inputValue: string) => {
    return convert(await action(param, inputValue));
  };

  useEffect(() => {
    if (!props.isDisabled) {
      const loadInitialOptions = async () => {
        try {
          setIsLoading(true); // Устанавливаем загрузку
          const initialData = await action(param, '');
          const convertedData = convert(initialData);
          setInitialOptions(convertedData);
        } catch (e) {
          console.log(e);
        } finally {
          setIsLoading(false); // Сбрасываем загрузку после завершения
        }
      };

      loadInitialOptions();
    }
  }, [action, param, convert]);

  useEffect(() => {
    if (!props?.isDisabled) {
      if (value?.length) {
        const loadSelectedOption = async () => {
          try {
            if (getIn(formik.values, name)) {
              const selectedId = getIn(formik.values, name);
              const result = convert(await action(param, selectedId));
              console.log('loadSelectedOption', result, value);
              setSelectedOption(result || null);
            }
          } catch (e) {
            console.log(e);
          }
        };

        loadSelectedOption();
      } else {
        setSelectedOption(null);
      }
    }
  }, [name]);

  const onChange = (name: string, event: any) => {
    if (isMulti) {
      formik.setFieldValue(
        name,
        Array.isArray(event)
          ? event.map((item: SELECT_OPTION_ITEM_DATA) => item.value)
          : [event.value],
      );
      setSelectedOption(Array.isArray(event) ? event : []);
    } else {
      formik.setFieldValue(name, event?.value || null);

      setSelectedOption(event);
    }
  };

  const onInputChange = (e: any) => {
    console.log('onInputChange', e);
  };

  console.log(selectedOption);

  return (
    <SelectAsyncElem
      cacheOptions
      defaultOptions={initialOptions}
      loadOptions={loadOptions}
      loadingMessage={() => i18n.t('COMMON.SELECT.LOADING')}
      dynamic
      onChange={onChange}
      onInputChange={onInputChange}
      value={selectedOption}
      placeholder={
        isLoading ? i18n.t('COMMON.SELECT.LOADING') : props.placeholder
      } // Используем загрузку в плейсхолдере
      closeMenuOnSelect={!isMulti}
      isMulti={isMulti}
      name={name}
      {...props}
    />
  );
};
