import { useEffect, useRef, useState } from 'react';
import { SESSION_ITEM_DATA } from '../../data/session/constant';
import { Component } from './component';
import { HttpRequest } from '../../lib/http';
import { UAParser } from 'ua-parser-js';

export const Container: React.FC<{
  data: SESSION_ITEM_DATA;
}> = ({ data }) => {
  const [src, setSrc] = useState('');
  const playerRef = useRef<any>(null);
  const [backgroundVisible, setBackgroundVisible] = useState(true);
  const [buttonVisible, setButtonVisible] = useState(true);
  const isMobileDevice = () => {
    const parser = new UAParser();
    const deviceType = parser.getDevice().type;
    return deviceType === 'mobile' || deviceType === 'tablet';
  };

  const syncTime = async () => {
    const response = await HttpRequest.get('/session/get-current-time');
    const serverNow = new Date(response.data).getTime(); // Серверное время
    const serverDate = new Date(data?.serverDate).getTime();

    // Рассчитываем startTime
    return ((serverNow - serverDate) / 1000).toFixed(0);
  };

  useEffect(() => {
    const fetchServerTime = async () => {
      if (data?.id) {
        const startTime = await syncTime();
        if (data.auto) {
          setSrc(`${data.webinar?.youtubeLink}&start=${startTime}`);
        } else {
          setSrc(`${data.webinar?.youtubeLink}`);
        }
      }
    };

    fetchServerTime();
  }, []);

  const onStart = () => {
    if (playerRef?.current?.player?.isPlaying) {
      setButtonVisible(false);
      setTimeout(() => {
        setBackgroundVisible(false);
      }, 1000 * 5);
    }
  };

  const handleClick = async () => {
    if (playerRef?.current?.player && !playerRef?.current?.player?.isPlaying) {
      setButtonVisible(false);

      const startTime = await syncTime();

      playerRef?.current?.player?.player?.player?.seekTo(startTime); // Запускаем видео

      playerRef?.current?.player?.player?.player?.playVideo();
    }
  };

  useEffect(() => {
    if (!isMobileDevice()) return;
    const handleVisibilityChange = async () => {
      if (!document.hidden && playerRef.current) {
        try {
          setBackgroundVisible(true);
          const startTime = await syncTime();

          playerRef?.current?.player?.player?.player?.seekTo(startTime);

          playerRef?.current?.player?.player?.player?.playVideo();

          setTimeout(() => {
            setBackgroundVisible(false);
          }, 1000);
        } catch (error) {
          console.error('Ошибка при возврате фокуса:', error);
        }
      } else if (document.hidden && playerRef.current) {
        playerRef?.current?.player?.player?.player?.pauseVideo();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  console.log(playerRef?.current?.player);

  return (
    <Component
      src={src}
      handleClick={handleClick}
      onStart={onStart}
      backgroundVisible={backgroundVisible}
      buttonVisible={buttonVisible}
      playerRef={playerRef}
    />
  );
};
