export const MODULE_NAME = 'EVENT_ITEM_START_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  START: { TYPE: 'GET', URL: (id: string) => `event/start/${id}` },
  STOP: { TYPE: 'GET', URL: (id: string) => `event/stop/${id}` },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
