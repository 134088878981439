import React from 'react';

import styled, { keyframes } from 'styled-components';

import { COLOR_TYPE } from '../../theme/color';

export const Elem: React.FC<{
  size?: number;
  color?: COLOR_TYPE;
  className?: any;
}> = ({ size = 15, color = 'backgroundThird', className }) => {
  return (
    <SpinnerWrapper size={size} color={color} className={className}>
      <svg viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="spinner-secondHalf">
            <stop offset="0%" stop-opacity="0" stop-color="currentColor" />
            <stop offset="100%" stop-opacity="0.5" stop-color="currentColor" />
          </linearGradient>
          <linearGradient id="spinner-firstHalf">
            <stop offset="0%" stop-opacity="1" stop-color="currentColor" />
            <stop offset="100%" stop-opacity="0.5" stop-color="currentColor" />
          </linearGradient>
        </defs>

        <g stroke-width="20">
          <path
            stroke="url(#spinner-secondHalf)"
            d="M 10 100 A 90 90 0 0 1 190 100"
          />
          <path
            stroke="url(#spinner-firstHalf)"
            d="M 190 100 A 90 90 0 0 1 10 100"
          />

          <path
            stroke="currentColor"
            stroke-linecap="round"
            d="M 4 100 A 96 96 0 0 1 4 98"
          />
        </g>
      </svg>
    </SpinnerWrapper>
  );
};

const SpinnerWrapper = styled.div<{ size: number; color: COLOR_TYPE }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  max-width: ${({ size }) => size}px;
  max-height: ${({ size }) => size}px;
  animation: spin 1s linear infinite;
  svg {
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    max-width: ${({ size }) => size}px;
    max-height: ${({ size }) => size}px;
    color: ${({ theme, color }) => theme[color]};
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;
