import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { LoaderElem } from '../../common/loader';
import { ACTION_ERROR_INTER } from './constant';
import { AlertActionElem } from '../../common/alert-action';
import { ButtonElem } from '../../common/button';
import { action } from './action';
import { ReactComponent as EventIcon } from '../../asset/svg/session/moderation/convertEvent.svg';
import { LoaderIconElem } from '../../common/loader-icon';
import { EVENT_LIST_MODULE_NAME } from '../event-list';
import { DeleteIconElem } from '../../common/delete-icon';
import { ModalConfirmElem } from '../../common/modal-confirm';

export const Container: React.FC<{
  sessionId: string;
}> = ({ sessionId }) => {
  const query = useQueryClient();

  const onSuccess = () => {
    query.invalidateQueries([EVENT_LIST_MODULE_NAME, sessionId]);
  };
  const actionMutate = useMutation(() => action(sessionId), {
    onSuccess,
  });

  const isLoading = () => {
    if (actionMutate.isLoading) {
      return true;
    }
  };

  const actionHandleClick = (e: any) => {
    actionMutate.mutate();
  };

  const isError = () => {
    if (actionMutate.isError && !actionMutate.isLoading && getErrorMessage())
      return true;
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = actionMutate.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };
  return (
    <>
      <ModalConfirmElem action={actionHandleClick} type="confirm">
        <ButtonElem
          tid="SESSION.MODERATION.CONVERT_TO_MESSAGE"
          color="backgroundThird"
          componentRight={
            isLoading() ? <LoaderIconElem color="textDefault" /> : <EventIcon />
          }
        />
      </ModalConfirmElem>
      {isError() && <AlertActionElem tid={getErrorMessage()} />}
    </>
  );
};
