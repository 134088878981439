import { API } from './constant';
import { HttpRequest } from '../../lib/http';

export const deleteAction = (id: string) => {
  return HttpRequest({
    method: API.DELETE.TYPE,
    url: API.DELETE.URL(id),
  });
};

export const approveAction = (id: string) => {
  return HttpRequest({
    method: API.APPROVE.TYPE,
    url: API.APPROVE.URL(id),
  });
};
