import React from 'react';

import { FormikValues } from 'formik';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import styled from 'styled-components';
import { i18n } from '../../lib/lang';
import { TextElem } from '../../common/text';
import { DoubleContainerElem } from '../../common/double-container';
import closeIcon from '../../asset/svg/common/close.svg';
import { SelectElem } from '../../common/select';
import { timezonearr } from '../session-item-data/constant';
import { ToggleElem } from '../../common/toggle';
import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import { FieldDataTimeInputElem } from '../../common/field-datetime-input';
import { FieldTimeInputElem } from '../../common/field-time-input';
import { FORM_VALUE_ENUM } from '../session-item-create/constant';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  close: Function;
  setFieldValue: Function;
  setValue: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  close,
  setFieldValue,
  setValue,
}) => {
  return (
    <FormElem onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}

      <Container spacing={4}>
        <TitleContainer>
          <TextElem tid="SESSION.UPDATE.TITLE" type="semi-bold" size="main" />
          <Icon src={closeIcon} onClick={() => close()} />
        </TitleContainer>
        <Grid>
          <FieldTextElem
            name={FORM_VALUE_ENUM.NAME}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="SESSION.UPDATE.FORM.NAME"
            value={getFieldValue(FORM_VALUE_ENUM.NAME)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
            error={isFieldError(FORM_VALUE_ENUM.NAME)}
          />
          <ToggleContainer>
            <Toggle
              tid="SESSION.UPDATE.FORM.AUTO"
              checked={getFieldValue(FORM_VALUE_ENUM.AUTO)}
              name={FORM_VALUE_ENUM.AUTO}
              handleClick={formik.handleChange}
            />
          </ToggleContainer>
        </Grid>

        <FieldContainer>
          <FieldTextElem
            name={FORM_VALUE_ENUM.ONLINE_MIN}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="SESSION.UPDATE.FORM.ONLINE_MIN"
            value={getFieldValue(FORM_VALUE_ENUM.ONLINE_MIN)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.ONLINE_MIN)}
            error={isFieldError(FORM_VALUE_ENUM.ONLINE_MIN)}
            type="customNumber"
          />
          <FieldTextElem
            name={FORM_VALUE_ENUM.ONLINE_MAX}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="SESSION.UPDATE.FORM.ONLINE_MAX"
            value={getFieldValue(FORM_VALUE_ENUM.ONLINE_MAX)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.ONLINE_MAX)}
            error={isFieldError(FORM_VALUE_ENUM.ONLINE_MAX)}
            type="customNumber"
          />

          {getFieldValue(FORM_VALUE_ENUM.AUTO) ? (
            <FieldTimeInputElem
              name={FORM_VALUE_ENUM.DATE}
              onChange={formik.handleChange}
              onBlur={() => formik.setFieldTouched(FORM_VALUE_ENUM.DATE, true)}
              title="SESSION.CREATE.FORM.DATE"
              value={getFieldValue(FORM_VALUE_ENUM.DATE)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.DATE)}
              error={isFieldError(FORM_VALUE_ENUM.DATE)}
            />
          ) : (
            <FieldDataTimeInputElem
              name={FORM_VALUE_ENUM.DATE}
              onChange={formik.handleChange}
              onBlur={() => formik.setFieldTouched(FORM_VALUE_ENUM.DATE, true)}
              title="SESSION.CREATE.FORM.DATE"
              value={getFieldValue(FORM_VALUE_ENUM.DATE)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.DATE)}
              error={isFieldError(FORM_VALUE_ENUM.DATE)}
            />
          )}

          <SelectElem
            options={timezonearr}
            dynamic
            name={FORM_VALUE_ENUM.TIMEZONE}
            title="SESSION.UPDATE.FORM.TIMEZONE"
            onChange={setFieldValue}
            value={setValue(timezonearr, FORM_VALUE_ENUM.TIMEZONE)}
          />
        </FieldContainer>

        <DoubleContainerElem>
          <ButtonElem
            type="submit"
            tid="SESSION.UPDATE.BUTTON.UPDATE"
            fill="solid"
            disabled={isSubmitDisabled()}
            color="success"
          />
        </DoubleContainerElem>

        {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
      </Container>
    </FormElem>
  );
};

const Container = styled(GridElem)`
  @media screen and (width>900px) {
    gap: ${Spacing(5)};
  }
`;

const FieldContainer = styled(GridElem)`
  @media screen and (width>600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (width>900px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Grid = styled(GridElem)`
  @media screen and (width>600px) {
    grid-template-columns: auto max-content;
    gap: ${Spacing(3)};
  }
  gap: ${Spacing(4)};
`;

const Toggle = styled(ToggleElem)`
  height: 20px !important;
  width: 33px !important;
  --handle-width: 15px;
  --handle-height: 15px;
  --handle-spacing: 1.8px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ToggleContainer = styled.div`
  @media screen and (width>600px) {
    margin-top: 26px;
  }
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  border-radius: 10px;
  padding: 0 16px;

  height: 46px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  div > div {
    height: 19px;
  }
`;

const Icon = styled.img`
  cursor: pointer;
`;
