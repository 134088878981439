import React, { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';

import { ACTION_ERROR_INTER, MODULE_NAME } from './constant';
import { Component } from './component';
import { convertWebinarList } from '../../data/webinar/convert';
import { action } from './action';
import { WEBINAR_ITEM_DATA_RAW } from '../../data/webinar/constant';

export const Container: React.FC<{}> = () => {
  const [project, setProject] = useState('');

  const [search, setSearch] = useState('');

  const [type, setType] = useState('ALL');

  const preFetch = useInfiniteQuery({
    queryKey: [MODULE_NAME],
    queryFn: ({ pageParam = 1 }) =>
      action({
        pageParam,
        type,
        search,
        project,
      }),
    getNextPageParam: (lastPage: any, allPages: any) => {
      return lastPage.length ? allPages.length + 1 : undefined;
    },
    retry: 0,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const data = useMemo(() => {
    return convertWebinarList(
      preFetch?.data?.pages.reduce((acc: any, page: any) => {
        return [...acc, ...page];
      }, []) as WEBINAR_ITEM_DATA_RAW[],
    );
  }, [preFetch?.data]);

  useEffect(() => {
    preFetch.refetch();
  }, [project, type, search]);

  const isError = () => {
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = preFetch.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  return (
    <Component
      data={data}
      isError={isError()}
      errorMessage={getErrorMessage()}
      project={project}
      setProject={setProject}
      setType={setType}
      search={search}
      setSearch={setSearch}
      type={type}
      fetchNextPage={preFetch.fetchNextPage}
      isFetching={preFetch.isFetching}
      hasNextPage={preFetch.hasNextPage}
    />
  );
};
