export const MODULE_NAME = 'PERSON_ITEM_CREATE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  NAME = 'name',
  PHONE = 'phone',
  COUNTRY = 'country',
  CITY = 'city',
  FILE = 'file',
  ROLE = 'role',
  PHOTO_URL = 'photoUrl',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.PHONE]: string;
  [FORM_VALUE_ENUM.NAME]: string;
  [FORM_VALUE_ENUM.COUNTRY]: string;
  [FORM_VALUE_ENUM.CITY]: string;
  [FORM_VALUE_ENUM.FILE]: string;
  [FORM_VALUE_ENUM.ROLE]: string;
  [FORM_VALUE_ENUM.PHOTO_URL]: string;
}

export const API = {
  TYPE: 'POST',
  URL: (sessionId: string) => `/person/${sessionId}`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
