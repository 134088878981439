import { Component } from './component';
import { SESSION_ITEM_DATA } from '../../data/session/constant';

export const Container: React.FC<{
  data?: SESSION_ITEM_DATA;
  isLoading?: boolean;
  isSuccess?: boolean;
  sessionStart: boolean;
}> = ({ isLoading, isSuccess, data, sessionStart }) => {
  return (
    <Component
      isLoading={isLoading}
      isSuccess={isSuccess}
      data={data}
      sessionStart={sessionStart}
    />
  );
};
