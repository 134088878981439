import React, { useState } from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { GridElem } from '../../common/grid';
import { DATA } from './constant';
import { UserItemElem } from '../../data/user/frame/user-item-list.elem ';
import { USER_DATA } from '../../data/user/constant';
import { SkeletonListElem } from '../../common/skeleton-list';
import { UserItemCreateContainer } from '../user-item-create';
import { Spacing } from '../../theme';

export const Component: React.FC<{
  data?: DATA;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
}> = ({ data, isLoading, isSuccess, isError, errorMessage }) => {
  const [updateVisible, setUpdateVisible] = useState(null);

  return (
    <GridElem spacing={3}>
      <UserItemCreateContainer />
      {isLoading && <SkeletonListElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      <NewGrid>
        {isSuccess &&
          data &&
          !data.isEmpty &&
          data?.list.map((item: USER_DATA) => (
            <UserItemElem
              {...item}
              updateVisible={updateVisible}
              setUpdateVisible={setUpdateVisible}
              key={item.id}
            />
          ))}
      </NewGrid>
    </GridElem>
  );
};

const NewGrid = styled(GridElem)`
  grid-auto-columns: auto max-content max-content;
  row-gap: ${Spacing(3)};
`;
