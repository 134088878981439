import moment from 'moment';
import { i18n } from '../../lib/lang';
import {
  convertDate,
  convertDateTime,
  convertTime,
} from '../../lib/lang/service';
import {
  VIEWER_ITEM_DATA_RAW,
  VIEWER_ITEM_DATA,
  VIEWER_ITEM_LIST_DATA,
  UTMObject,
} from './constant';
import { MESSAGE_ITEM_DATA_RAW } from '../message/constant';

export const filterUTM = (obj?: UTMObject): Partial<UTMObject> => {
  const utmKeys = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'googleClientId',
  ];

  if (obj) {
    return Object.keys(obj)
      .filter((key) => utmKeys.includes(key))
      .reduce((acc, key) => {
        acc[key] = obj[key];
        return acc;
      }, {} as Partial<UTMObject>);
  } else {
    return {};
  }
};

export const convertStatus = (
  viewer: VIEWER_ITEM_DATA_RAW | VIEWER_ITEM_DATA,
): string => {
  if (viewer?.reservation?.payment?.confirm) {
    return 'STATUS_PAID';
  }

  if (viewer?.reservation) {
    return 'STATUS_SENT';
  }
  if (viewer?.isButtonOpen) {
    return 'STATUS_BUTTON_PRESSED';
  }
  if (viewer?.isButtonSee) {
    return 'STATUS_BUTTON_NOT_PRESSED';
  }
  return 'STATUS_NOT_SEEN';
};

function formatTime(totalSeconds: number) {
  const minutes = Math.floor(totalSeconds / 60000);
  const seconds = Math.floor(totalSeconds / 1000) % 60;
  return i18n.t('VIEWER.ITEM.WATCH_TIME_VALUE', {
    min: minutes,
    sec: seconds,
  });
}

export const convertViewer = (
  viewer: VIEWER_ITEM_DATA_RAW,
): VIEWER_ITEM_DATA => {
  return {
    ...viewer,
    watchTime: formatTime(viewer.watchTime),
    connectDate: viewer.connectDate.map((item: string) =>
      moment(item).format('HH:mm DD.MM.YY'),
    ),
    status: convertStatus(viewer),
  };
};

export const convertViewerList = (
  viewerList: VIEWER_ITEM_DATA_RAW[],
): VIEWER_ITEM_LIST_DATA => {
  return {
    list: viewerList?.map((viewer: VIEWER_ITEM_DATA_RAW) => {
      return convertViewer(viewer);
    }),
    isEmpty: !viewerList?.length,
  };
};

export const convertViewerToExcel = (viewer: VIEWER_ITEM_DATA): any => {
  return {
    'Дата вебинару': moment(viewer.session.serverDate).format('DD.MM.YYYY'),
    'Час початку вебінару': convertTime(viewer.session.serverDate),
    'Час закінчення на вебінару': viewer.session.serverDateEnd
      ? convertTime(viewer.session.serverDateEnd)
      : '',
    'Имя сессии': viewer.session.name,
    Имя: viewer.name,
    Телефон: viewer.phone,
    'Тип устройства': viewer.platform.join(),
    ОС: viewer.os.join(),
    Браузер: viewer.browser.join(),
    Страна: viewer.country,
    Город: viewer.city,

    'Время захода/выхода': viewer.duration.map((item) => item.join('-')).join(),
    'Количество минут на вебинаре': viewer.watchTime,
    'Источник utm_source': viewer.utm.utm_source || '',
    'Источник utm_medium': viewer.utm.utm_medium || '',
    'Источник utm_campaign': viewer.utm.utm_campaign || '',
    'Источник utm_term': viewer.utm.utm_term || '',
    'Источник utm_content': viewer.utm.utm_content || '',
    'Нужна запись': viewer.needRecord,
    IP: viewer.ip.join(),
    Кнопка: viewer.isButtonSee ? 'бачив' : 'не бачив',
    'Нажал на кнопу': viewer.isButtonSee,
    'Есть бан': viewer.isBanned ? 'Так' : 'Hi',
    'Есть мут': viewer.isMuted ? 'Так' : 'Hi',
    'Статус зявки': i18n.t(`VIEWER.LIST.${convertStatus(viewer)}`),
    'Дата оплаты': moment(viewer?.reservation?.payment?.updateDate).format(
      'HH:mm DD.MM.YYYY',
    ),
    Комментарии: viewer?.message
      ?.map((message: MESSAGE_ITEM_DATA_RAW) => message.content)
      .join(),
  };
};

export const convertViewerToExcelList = (
  viewerList?: VIEWER_ITEM_DATA_RAW[],
): any => {
  return viewerList
    ?.map((viewerRaw: VIEWER_ITEM_DATA_RAW) => {
      return convertViewer(viewerRaw);
    })
    ?.map((viewer: VIEWER_ITEM_DATA) => {
      return convertViewerToExcel(viewer);
    });
};
