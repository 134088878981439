import React, { useState } from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { GridElem } from '../../common/grid';

import { Spacing } from '../../theme';
import {
  EVENT_ITEM_DATA,
  EVENT_ITEM_LIST_DATA,
} from '../../data/event/constant';

import { IonInfiniteScroll } from '@ionic/react';
import { EventFilterContainer } from '../event-filter';
import { EventSkeletonElem } from '../../data/event/frame/event-skeleton.elem';
import { EventItemElem } from '../../data/event/frame/event-item-list.elem';
import { EventItemCreateContainer } from '../event-item-create';

export const Component: React.FC<{
  data?: EVENT_ITEM_LIST_DATA;
  isError?: boolean;
  errorMessage?: string;
  isFetching?: boolean;
  fetchNextPage: Function;
  setSort: Function;
  sort: string;
  type: string;
  setType: Function;
  setPerson: Function;
  person: any;
  sessionId: string;
  hasNextPage?: boolean;
}> = ({
  data,
  isError,
  errorMessage,
  isFetching,
  fetchNextPage,
  setSort,
  sort,
  type,
  setType,
  person,
  setPerson,
  sessionId,
  hasNextPage,
}) => {
  const [updateVisible, setUpdateVisible] = useState(null);
  return (
    <GridElem spacing={5}>
      <EventFilterContainer
        sort={sort}
        setSort={setSort}
        person={person}
        setPerson={setPerson}
        type={type}
        setType={setType}
        sessionId={sessionId}
      />
      <EventItemCreateContainer sessionId={sessionId} />
      {isError && <AlertActionElem text={errorMessage} />}
      <Container>
        {data && !data.isEmpty && (
          <NewGrid sessionAuto={data?.list[0]?.session.auto}>
            {data?.list.map((item: EVENT_ITEM_DATA) => (
              <EventItemElem
                data={item}
                updateVisible={updateVisible}
                setUpdateVisible={setUpdateVisible}
                key={item.id}
              />
            ))}
          </NewGrid>
        )}

        {isFetching && <EventSkeletonElem />}
      </Container>
      {hasNextPage && (
        <IonInfiniteScroll
          onIonInfinite={async (ev) => {
            await fetchNextPage();
            ev.target.complete();
          }}
          threshold="100px"
          disabled={!hasNextPage}
        >
          <div style={{ height: '1px' }}></div>
        </IonInfiniteScroll>
      )}
    </GridElem>
  );
};
const Container = styled.div`
  display: grid;
  gap: ${Spacing(3)};
`;

const NewGrid = styled(GridElem)<{ sessionAuto?: boolean }>`
  @media screen and (width>600px) {
    grid-template-columns: ${({ sessionAuto }) =>
      sessionAuto
        ? '100px 20px auto min-content auto;'
        : '20px auto min-content auto auto'};
  }
  gap: ${Spacing(3)};
`;
