import React, { useState } from 'react';
import { FILE_ITEM_DATA } from '../constant';

import { CardElem } from '../../../common/card';

import { TextElem } from '../../../common/text';
import styled, { css } from 'styled-components';
import { CopyElem } from '../../../common/copy';
import { ReactComponent as CopyIcon } from '../../../asset/svg/common/copy.svg';
import { GridElem } from '../../../common/grid';
import { COLOR_ENUM } from '../../../theme/color';
import { Spacing } from '../../../theme';
import ImageZoomIcon from '../../../asset/svg/upload/image-zoom.svg';

import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { i18n } from '../../../lib/lang';
import {
  ClosePreviewIconStyled,
  Modal,
  ZoomIcon,
} from '../../../epic/file-item-upload/view/image';

const Elem: React.FC<FILE_ITEM_DATA> = ({
  id,
  name,
  url,
  createDate,
  mimetype,
  webinarLogo,
  webinarSpeakerImg,
  user,
}) => {
  const [modalPreviewVisible, setModalPreviewVisible] = useState(false);

  const openModalPreview = (e: any) => {
    if (mimetype.includes('image')) {
      e.stopPropagation();
      setModalPreviewVisible(true);
    }
  };

  const closeModalPreview = (e: any) => {
    e.stopPropagation();

    setModalPreviewVisible(false);
  };

  const getWebinarCounter = () => {
    const set = new Set([...webinarLogo, ...webinarSpeakerImg]);
    return set.size || i18n.t('FILE.ITEM.WITHOUT_WEBINAR');
  };

  return (
    <Card hover={false}>
      <Preview image={url} className="preview" onClick={openModalPreview}>
        {mimetype.includes('image') && (
          <>
            <Image src={url} />
            <ZoomIcon className="icon">
              <img src={ImageZoomIcon} />
            </ZoomIcon>
            <Modal
              isOpen={modalPreviewVisible}
              onDidDismiss={closeModalPreview}
            >
              <img
                src={url}
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  margin: 'auto',
                }}
              />
              <ClosePreviewIconStyled onClick={closeModalPreview} />
            </Modal>
          </>
        )}
      </Preview>
      <InfoContainer>
        <GridElem spacing={2} style={{ maxWidth: '100%' }}>
          <TextElem size="small" color="textThird" tid={createDate} />
          <TextElem
            type="medium"
            tid="FILE.ITEM.ADDED"
            tvalue={{
              value: user?.email ? user?.email : i18n.t('FILE.ITEM.VIEWER'),
            }}
            oneLine
          />
          <TextElem
            type="medium"
            tid="FILE.ITEM.WEBINAR"
            tvalue={{ value: getWebinarCounter() }}
            oneLine
          />
          <GridElem spacing={3}>
            <TextElem color="textPrimary" size="small" tid={id} oneLine />
          </GridElem>
        </GridElem>
        <CopyElem value={url}>
          <CopyIconStyled />
        </CopyElem>
      </InfoContainer>
    </Card>
  );
};

const InfoContainer = styled(GridElem)`
  grid-template-columns: auto 18px;
  align-items: center;
`;

const CopyIconStyled = styled(CopyIcon)`
  width: 18px;
  height: 18px;
  transition: all 0.2s;
  path {
    transition: all 0.2s;
  }
  :hover {
    path {
      stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
    }
  }
`;

export const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;

const Preview = styled.div<{ image: string }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* height: auto; */
  .icon {
    display: none;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    position: absolute;
    transition: all 0.2s;
  }
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};

  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.ALERT]}px;
  transition: all 0.2s;
  :hover {
    .icon {
      display: flex;
    }
  }
`;

export const Card = styled(CardElem)`
  display: grid;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};

  grid-template-columns: 120px auto;
  grid-template-rows: 98px;
  gap: ${Spacing(4)};
  align-items: center;
  @media screen and (800px<width<980px) {
    grid-template-columns: auto;

    grid-template-rows: 98px auto;
  }

  @media screen and (width<520px) {
    grid-template-columns: auto;

    grid-template-rows: 98px auto;
  }
  div {
    transition: all 0.2s;
  }
  :hover .preview {
    background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]} !important;
  }
`;

export { Elem as FileItemElem };
