import React from 'react';
import { Component } from './component';
import { useQuery } from 'react-query';
import { WEBINAR_ITEM_DATA } from '../../data/webinar/constant';
import { WEBINAR_ITEM_DATA_MODULE_NAME } from '../webinar-item-data';
import { SIZE_LAYOUT_TYPE } from '../../theme/size';

export const Container: React.FC<{
  size?: SIZE_LAYOUT_TYPE;
  webinarId: string;
}> = ({ webinarId, size }) => {
  const preFetch = useQuery([WEBINAR_ITEM_DATA_MODULE_NAME, webinarId], {});

  const isSuccess = () => {
    if (preFetch.isSuccess) {
      return true;
    }
  };

  const isLoading = () => {
    if (preFetch.isLoading) {
      return true;
    }
  };

  const getData = (): WEBINAR_ITEM_DATA | undefined => {
    const data = preFetch.data as unknown as WEBINAR_ITEM_DATA;
    if (data) {
      return data;
    }
  };

  const data = getData();

  return (
    <Component
      data={data}
      size={size}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      webinarId={webinarId}
    />
  );
};
