import VALIDATION from './validation.json';
import ERROR from './error.json';
import AUTH from './auth.json';
import SETTINGS from './settings.json';
import WELLCOME from './wellcome.json';
import ACCOUNT from './account.json';
import COMMON from './common.json';
import NAVIGATION from './navigation.json';

import CONVERT from './convert.json';
import LANG from './lang.json';

import REFERAL from './referal.json';
import FILE from './file.json';
import SESSION from './session.json';
import WEBINAR from './webinar.json';
import ADMIN from './admin.json';
import PROJECT from './project.json';
import USER from './user.json';
import VIEWER from './viewer.json';
import PERSON from './person.json';
import EVENT from './event.json';
import EXPENDITURE from './expenditure.json';
import ANALYTICS from './analytics.json';

export const UA = {
  translation: {
    VALIDATION,
    ERROR,
    AUTH,
    SETTINGS,
    WELLCOME,
    ACCOUNT,
    COMMON,
    CONVERT,
    NAVIGATION,
    LANG,
    REFERAL,
    FILE,
    WEBINAR,
    SESSION,
    ADMIN,
    PROJECT,
    USER,
    VIEWER,
    PERSON,
    EVENT,
    EXPENDITURE,
    ANALYTICS,
  },
};
