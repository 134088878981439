import React, { ReactNode } from 'react';

import styled, { css } from 'styled-components';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import { useHistory, useLocation } from 'react-router';
import { TextElem } from '../text';
import { TAB_ELEM_TYPE } from './constant';

export const Elem: React.FC<{ tabList: TAB_ELEM_TYPE[] }> = ({ tabList }) => {
  const router = useLocation();

  const history = useHistory();

  return (
    <Container>
      {tabList.map((item: TAB_ELEM_TYPE) => (
        <TabItem
          active={router.pathname.includes(item.path.split('?')[0])}
          onClick={() => history.push(item.path)}
        >
          <TextElem
            tid={item.tid}
            color={
              router.pathname.includes(item.path.split('?')[0])
                ? 'default'
                : 'textThird'
            }
            type="medium"
          />
        </TabItem>
      ))}
      {/* <GradientBlock /> */}
    </Container>
  );
};

const Container = styled.div`
  padding: 0 ${Spacing(1)};
  height: 46px;
  display: flex;
  align-items: center;
  position: relative;

  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  gap: ${Spacing(1)};
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

// const GradientBlock = styled.div`
//   display: block;
//   width: 124px;
//   min-width: 124px;

//   height: 46px;
//   background: ${({ theme }) =>
//     `linear-gradient(90deg, ${theme[COLOR_ENUM.BACKGROUND_PRIMARY]}00, ${
//       theme[COLOR_ENUM.BACKGROUND_PRIMARY]
//     }FF)`};
//   border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;

//   right: 0;
//   position: absolute;
//   pointer-events: none;
// `;

const TabItem = styled.div<{ active: boolean }>`
  cursor: pointer;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  padding: 0 ${Spacing(5)};
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};

  transition: all 0.2s;
  span {
    transition: all 0.2s;
    white-space: nowrap !important;
  }

  :hover {
    span {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
    }
  }

  ${({ active }) =>
    active
      ? css`
          :hover {
            background-color: ${({ theme }) =>
              theme[COLOR_ENUM.BACKGROUND_THIRD]};
            span {
              color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
            }
          }
          border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
          background-color: ${({ theme }) =>
            theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
        `
      : ''};
`;
