import React, { useState } from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { GridElem } from '../../common/grid';
import { DATA } from './constant';
import { ProjectItemElem } from '../../data/project/frame/project-item-list.elem ';
import { PROJECT_ITEM_DATA } from '../../data/project/constant';
import { SkeletonListElem } from '../../common/skeleton-list';
import { ProjectItemCreateContainer } from '../project-item-create';
import { Spacing } from '../../theme';

export const Component: React.FC<{
  data?: DATA;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
}> = ({ data, isLoading, isSuccess, isError, errorMessage }) => {
  const [updateVisible, setUpdateVisible] = useState(null);
  return (
    <GridElem spacing={3}>
      <ProjectItemCreateContainer />

      {isLoading && <SkeletonListElem />}

      {isError && <AlertActionElem text={errorMessage} />}

      {isSuccess && data && !data.isEmpty && (
        <Container style={{ width: '100%' }}>
          {data?.list.map((item: PROJECT_ITEM_DATA) => (
            <ProjectItemElem
              {...item}
              updateVisible={updateVisible}
              setUpdateVisible={setUpdateVisible}
            />
          ))}
        </Container>
      )}
    </GridElem>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: auto min-content;
  row-gap: ${Spacing(4)};
`;
