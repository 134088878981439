import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { SkeletonFieldElem } from '../../common/skeleton-field';
import { SESSION_ITEM_DATA } from '../../data/session/constant';
import { BASE_URL } from '../../lib/http/constant';
import { SessionItemRoomLiveVideoContainer } from '../session-item-room-live-video';
import { VideoPlayerElem } from '../../common/video';

export const Component: React.FC<{
  data?: SESSION_ITEM_DATA;
  isLoading?: boolean;
  sessionStart: boolean;
  isSuccess?: boolean;
}> = ({ isLoading, isSuccess, data, sessionStart }) => {
  return (
    <Container>
      {isSuccess && (
        <>
          {data && sessionStart && (
            <SessionItemRoomLiveVideoContainer data={data} />
          )}
          {data && !sessionStart && (
            <PlayerWrapper>
              <VideoPlayerElem
                autoPlay
                muted={true}
                loop={true}
                src={`${BASE_URL}/session/previewStream/${data?.id}`}
                playControl={false}
                progressControl={false}
              />
            </PlayerWrapper>
          )}
        </>
      )}

      {isLoading && !isSuccess && (
        <SkeletonField
          title={false}
          style={{ width: '100%', height: '100%' }}
        />
      )}
    </Container>
  );
};

const PlayerWrapper = styled.div`
  border-radius: 20px;
`;

const Container = styled.div`
  position: relative;
  width: 100%; /* или другая ширина, соответствующая вашему дизайну */
  height: 100%; /* Ограничение по высоте родительского контейнера */
`;

const SkeletonField = styled(SkeletonFieldElem)`
  .field {
    height: 100%;
    width: 100%;
    min-height: 390px;
  }
`;
