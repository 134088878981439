import React from 'react';
import { EVENT_ITEM_DATA } from '../constant';
import { TextElem } from '../../../common/text';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';

export const TimecodeMobile: React.FC<{
  data: EVENT_ITEM_DATA;
}> = ({ data }) => {
  const { message, person } = data;

  return (
    <>
      <EllipsisContainerElem>
        <TextElem type="semi-bold" color="textFourth" size="main" oneLine>
          {message}
        </TextElem>
      </EllipsisContainerElem>
      <EllipsisContainerElem>
        <TextElem
          type="medium"
          color="textPrimary"
          size="main"
          oneLine
        ></TextElem>
      </EllipsisContainerElem>
    </>
  );
};
