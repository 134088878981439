import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import 'plyr/dist/plyr.css';

import ReactPlayer from 'react-player/youtube';
import { COLOR_ENUM } from '../../theme/color';
import { ButtonElem } from '../../common/button';

export const Component: React.FC<{
  src: string;
  onStart: () => void;
  handleClick: () => void;
  playerRef: any;
  backgroundVisible: boolean;
  buttonVisible: boolean;
}> = ({
  src,
  onStart,
  handleClick,
  playerRef,
  backgroundVisible,
  buttonVisible,
}) => {
  return (
    <PlayerWrapper onClick={handleClick}>
      <Player
        ref={playerRef}
        url={src}
        muted={false}
        playing={true}
        controls={false}
        className="player"
        onStart={onStart}
        width="100%"
        height="100%"
        config={{
          //@ts-ignore

          youtube: {
            playerVars: {
              // autoplay: 1,
              cc_load_policy: 0,
              disablekb: 1,
              iv_load_policy: 3,
              modestbranding: 1,
              showinfo: 0,
            },
          },
        }}
      />
      {backgroundVisible && (
        <>
          <PlayerTopBg className="PlayerTopBg" />
          <PlayerBottomBg className="PlayerBottomBg">
            {buttonVisible && (
              <ButtonElem
                color="active"
                fill="outline"
                tid="Для перегляду натисніть"
              />
            )}
          </PlayerBottomBg>
        </>
      )}

      <PlayerBg />
    </PlayerWrapper>
  );
};

const PlayerTopBg = styled.div`
  position: absolute;
  top: 0 !important;
  left: 0;
  width: 100%;
  height: 40% !important;
  background: ${({ theme }) =>
    `linear-gradient(360deg, ${theme[COLOR_ENUM.BACKGROUND_SECONDARY]}00 0%, ${
      theme[COLOR_ENUM.BACKGROUND_SECONDARY]
    } 50%)`};
`;

const PlayerBottomBg = styled.div`
  position: absolute;
  bottom: 0;
  top: auto;
  left: 0;
  width: 100%;
  height: 40% !important;
  background: ${({ theme }) =>
    `linear-gradient(180deg, ${theme[COLOR_ENUM.BACKGROUND_SECONDARY]}00 0%, ${
      theme[COLOR_ENUM.BACKGROUND_SECONDARY]
    } 50%)`};
  padding: 12px;
  display: flex;
  align-items: end;
`;

const PlayerBg = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Player = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;

  .ytp-show-cards-title {
    display: none;
  }
  .ytp-watermark {
    display: none;
  }
`;

const PlayerWrapper = styled.div`
  border-radius: 20px;
  width: 100%;
  height: 100%; // Ограничиваем высоту родителя
  overflow: hidden;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};

  position: relative;

  @media screen and (max-width: 1060px) {
    padding-top: 56.25%; /* Соотношение 16:9 */
    height: 0;
  }
`;
