import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { LoaderElem } from '../../common/loader';
import { ACTION_ERROR_INTER } from './constant';
import { AlertActionElem } from '../../common/alert-action';
import { ButtonElem } from '../../common/button';
import { action } from './action';
import { ReactComponent as QuestionIcon } from '../../asset/svg/session/moderation/convertQuestion.svg';
import { LoaderIconElem } from '../../common/loader-icon';
import { DeleteIconElem } from '../../common/delete-icon';
import { ModalConfirmElem } from '../../common/modal-confirm';

export const Container: React.FC<{
  selecteMessage: string;
  resetSelectedList: Function;
}> = ({ selecteMessage, resetSelectedList }) => {
  const query = useQueryClient();

  const onSuccess = () => {
    resetSelectedList();
  };
  const actionMutate = useMutation(() => action(selecteMessage), {
    onSuccess,
  });

  const isLoading = () => {
    if (actionMutate.isLoading) {
      return true;
    }
  };

  const actionHandleClick = (e: any) => {
    actionMutate.mutate();
  };

  const isError = () => {
    if (actionMutate.isError && !actionMutate.isLoading && getErrorMessage())
      return true;
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = actionMutate.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };
  return (
    <>
      <ModalConfirmElem action={actionHandleClick} type="confirm">
        <ButtonElem
          tid="SESSION.MODERATION.CONVERT_TO_QUESTION"
          color="backgroundThird"
          componentRight={
            isLoading() ? (
              <LoaderIconElem color="textDefault" />
            ) : (
              <QuestionIcon />
            )
          }
          disabled={!selecteMessage}
        />
      </ModalConfirmElem>

      {isError() && <AlertActionElem tid={getErrorMessage()} />}
    </>
  );
};
