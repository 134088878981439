import styled, { css } from 'styled-components';
import React, { useEffect } from 'react';
import { COLOR_ENUM } from '../../../theme/color';
import { Spacing } from '../../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { TextElem } from '../../../common/text';

export const TabType: React.FC<{
  type: string;
  setType: Function;
}> = ({ type, setType }) => {
  const typeArr = ['ALL', 'PLAN', 'ONGOING', 'COMPLETED', 'AUTO'];

  useEffect(() => setType(typeArr[0]), []);

  return (
    <Container>
      {typeArr.map((item: string) => (
        <TabItem active={type === item} onClick={() => setType(item)}>
          <TextElem
            tid={`WEBINAR.LIST.FILTER.TAB.${item}`}
            color={type === item ? 'default' : 'textThird'}
            type="medium"
          />
        </TabItem>
      ))}
      {/* <GradientBlock /> */}
    </Container>
  );
};

const Container = styled.div`
  padding: 0 ${Spacing(1)};

  height: 46px;
  display: flex;
  align-items: center;

  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  gap: ${Spacing(1)};

  overflow-y: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  position: relative;
`;

const GradientBlock = styled.div`
  display: block;
  width: 124px;
  min-width: 124px;

  height: 46px;
  background: ${({ theme }) =>
    `linear-gradient(90deg, ${theme[COLOR_ENUM.BACKGROUND_SECONDARY]}00, ${
      theme[COLOR_ENUM.BACKGROUND_SECONDARY]
    }FF)`};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;

  right: 0;
  position: absolute;
  pointer-events: none;
`;

const TabItem = styled.div<{ active: boolean }>`
  cursor: pointer;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  width: max-content;
  padding: 0 ${Spacing(4)};
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};

  transition: all 0.2s;
  span {
    transition: all 0.2s;
    white-space: nowrap;
  }

  :hover {
    span {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
    }
  }

  ${({ active }) =>
    active
      ? css`
          :hover {
            background-color: ${({ theme }) =>
              theme[COLOR_ENUM.BACKGROUND_THIRD]};
            span {
              color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
            }
          }
          border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
          background-color: ${({ theme }) =>
            theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
        `
      : ''};
`;
