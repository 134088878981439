export const MODULE_NAME = 'SESSION_ITEM_MODERATION_PREMODERATION_MODULE_NAME';

export const API = {
  TYPE: 'PATCH',
  URL: (id: string) => `session/moderation/preModerationActive/${id}`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
