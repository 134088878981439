import React from 'react';
import styled, { css } from 'styled-components';
import { SelectPreloadContainer } from '../../select-preload';
import { COLOR_ENUM } from '../../../theme/color';
import { convertPersonListToSelect } from '../../../data/person/convert';
import { getPersonList } from '../../../data/person/action';

export const PersonSelect: React.FC<{
  setPerson: Function;
  person: any;
  sessionId: string;
}> = ({ sessionId, setPerson, person }) => {
  const onChange = (name: string, values: any) => {
    setPerson(values);
  };

  return (
    <Container className="personSelect">
      <SelectCustom
        name={'dfgdgfdffgdf'}
        action={getPersonList}
        convert={convertPersonListToSelect}
        onChange={onChange}
        noAlertContainer
        placeholder="Sort by user"
        hideSelectedOptions={false}
        dynamic
        isMulti
        param={sessionId}
        value={person}
        closeMenuOnSelect={false}
        isClearable
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const SelectCustom = styled(SelectPreloadContainer)`
  .Select__value-container--is-multi {
    display: flex;
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};

    width: 119px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .Select__value-container--has-value {
    display: block;
  }

  .Select__menu {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .Select__control {
    margin: auto;
    background: ${({ theme }) =>
      theme[COLOR_ENUM.DEFAULT] === '#1B1924'
        ? theme[COLOR_ENUM.INPUT_BACKGROUND]
        : theme[COLOR_ENUM.INPUT]};
  }
`;
