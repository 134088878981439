import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';
import { IonCheckbox } from '@ionic/react';
import { TextElem } from '../text';
import { SIZE_FONT_TYPE } from '../../theme/size';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import { Spacing } from '../../theme';

export const Elem: React.FC<{
  name?: string;
  tid?: string;
  tvalue?: object;
  text?: string;
  textSize?: SIZE_FONT_TYPE;
  checked?: boolean;
  onClick?: any;
  isLoading?: boolean;
  onChange?: any;
  disabled?: boolean;
  className?: string;
}> = ({
  checked = false,
  onClick,
  tid,
  tvalue,
  text = null,
  textSize = 'main',
  isLoading,
  onChange,
  name,
  disabled,
  className,
}) => {
  const [status, setStatus] = useState<boolean>(checked);
  useEffect(() => {
    setStatus(checked);
  }, [checked]);

  const handleClick = () => {
    if (!disabled) {
      if (!isLoading) {
        setStatus(!status);
        if (onClick) {
          onClick();
        }
      }

      if (onChange) {
        onChange({ target: { name: name, value: !status } });
      }
    }
  };
  return (
    <Container onClick={handleClick} className={className}>
      <Checkbox
        checked={status}
        disabled={isLoading}
        name={name}
        slot="center"
      />
      {(text || tid) && (
        <TextElem color="textFourth" tid={tid} tvalue={tvalue}>
          {text}
        </TextElem>
      )}
    </Container>
  );
};

const Checkbox = styled(IonCheckbox)`
  --size: 16px;
  --checkmark-width: 4px;
  --background-checked: ${({ theme }) => theme[COLOR_ENUM.ACTIVE]};
  --border-color-checked: transparent !important;
  --border-width: 1px;
  --border-radius: 4px;
  --border-style: solid;
  --border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_HOVER]};
  --background: transparent;
  transition: color ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  ::part(container) {
    padding: 4px;
    padding-right: 2px;
    padding-top: 2px;
  }

  ::part(mark) {
    stroke: #312f3b;
    stroke-width: 4.25;
    stroke-linecap: round;
    stroke-dashoffset: 7;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spacing(2)};
  width: 100%;
  span {
    height: 16px;
    /* line-height: 1.2em; */
  }
  cursor: pointer;
`;
