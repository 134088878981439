export const checkTextToLink = (text: string) => {
  // Удаление пробелов между символами
  const cleanedText = text.replace(/\s+/g, '');

  // Проверка на стандартные URL (http, https, ftp и т.д.)
  const urlPattern = /https?:\/\/[^\s/$.?#].[^\s]*/i;

  // Проверка на упрощенные ссылки (без протокола)
  const simplifiedUrlPattern = /([a-z0-9-]+\.)+[a-z]{2,}/i;

  // Проверка на сокращенные ссылки
  const shortUrlPattern =
    /\b(bit\.ly|goo\.gl|t\.co|tinyurl\.com|is\.gd|buff\.ly|ow\.ly)\b/i;

  // Проверка на ссылки с обфускацией (dot)
  const obfuscatedPattern =
    /\b([a-z0-9-]+)\s*(?:\[dot\]|\(dot\)|\.|，)\s*([a-z]{2,})\b/i;

  // Проверка на похожие символы (например, латиница и кириллица)
  const similarCharPattern = /(?:a|а|ｅ)[a-zа-я0-9]+\.(?:com|ru|net|org)/i;

  // Unicode-домены (IDN)
  const unicodeDomainPattern =
    /(?:xn--)?([a-z0-9]+)(?:-[a-z0-9]+)?\.(?:[a-z]{2,})/i;

  // HTML-энкодинг
  const htmlEntityPattern = /&#[0-9]+;|&#x[0-9a-f]+;/i;

  // Markdown-ссылки
  const markdownLinkPattern = /\[(.*?)\]\((https?:\/\/[^\s]+)\)/i;

  // Hex-коды
  const hexCodePattern = /%[0-9a-f]{2}/i;

  // Email-формат
  const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z]{2,}\b/i;

  // Base64-данные
  const base64Pattern = /data:text\/html;base64,[a-z0-9+/=]+/i;

  // Проверка на другие протоколы (ftp, file)
  const alternativeProtocolPattern = /(ftp|file):\/\/[^\s/$.?#].[^\s]*/i;

  return (
    urlPattern.test(cleanedText) ||
    simplifiedUrlPattern.test(cleanedText) ||
    shortUrlPattern.test(cleanedText) ||
    obfuscatedPattern.test(cleanedText) ||
    similarCharPattern.test(cleanedText) ||
    unicodeDomainPattern.test(cleanedText) ||
    htmlEntityPattern.test(cleanedText) ||
    markdownLinkPattern.test(cleanedText) ||
    hexCodePattern.test(cleanedText) ||
    emailPattern.test(cleanedText) ||
    base64Pattern.test(cleanedText) ||
    alternativeProtocolPattern.test(cleanedText)
  );
};
