import React from 'react';
import { FormikProvider } from 'formik';
import { FieldArray } from 'formik';
import { GridElem } from '../../../common/grid';
import { DoubleContainerElem } from '../../../common/double-container';
import { FORM_VALUE_ENUM } from '../constant';
import { FieldTimeInputElem } from '../../../common/field-time-input';

import styled from 'styled-components';
import { ButtonElem } from '../../../common/button';
import { FieldTextElem } from '../../../common/field-text';
import { ReactComponent as DeleteIcon } from '../../../asset/svg/common/close.svg';

import addIcon from '../../../asset/svg/button/plus-light.svg';
import addIconLight from '../../../asset/svg/button/plus.svg';
import { THEME_MODULE_NAME } from '../../../data/theme';
import { useSelector } from '../../../lib/store';
import { THEME_ENUM } from '../../../data/theme/constant';
import CloseIcon from '../../../asset/svg/common/close.svg';
import { Spacing } from '../../../theme';

export const SurveyForm: React.FC<{
  formik: any;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  isSubmitDisabled: Function;
  update?: boolean;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  getFieldValue,
  isSubmitDisabled,
  update,
}) => {
  const { theme } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));

  return (
    <GridElem spacing={5}>
      <FieldTextElem
        name={FORM_VALUE_ENUM.MESSAGE}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        title="EVENT.FORM.QUESTION"
        value={getFieldValue(FORM_VALUE_ENUM.MESSAGE)}
        errorMessage={getFieldError(FORM_VALUE_ENUM.MESSAGE)}
        error={isFieldError(FORM_VALUE_ENUM.MESSAGE)}
      />

      <TripleContainer>
        <FieldTimeInputElem
          name={FORM_VALUE_ENUM.TIME}
          onChange={formik.handleChange}
          title="EVENT.FORM.TIME"
          onBlur={formik.setFieldTouched}
          value={getFieldValue(FORM_VALUE_ENUM.TIME)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.TIME)}
          error={isFieldError(FORM_VALUE_ENUM.TIME)}
          formik={formik}
        />
        <FieldTimeInputElem
          name={FORM_VALUE_ENUM.END_TIME}
          onChange={formik.handleChange}
          onBlur={formik.setFieldTouched}
          title="EVENT.FORM.END_TIME"
          value={getFieldValue(FORM_VALUE_ENUM.END_TIME)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.END_TIME)}
          error={isFieldError(FORM_VALUE_ENUM.END_TIME)}
          formik={formik}
        />
        <FieldTimeInputElem
          name={FORM_VALUE_ENUM.TIME_BOOST}
          onChange={formik.handleChange}
          onBlur={formik.setFieldTouched}
          title="EVENT.FORM.TIME_BOOST"
          value={getFieldValue(FORM_VALUE_ENUM.TIME_BOOST)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.TIME_BOOST)}
          error={isFieldError(FORM_VALUE_ENUM.TIME_BOOST)}
          formik={formik}
        />
      </TripleContainer>

      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <GridElem spacing={15}>
            <React.Fragment>
              <GridElem spacing={2}>
                <FieldArray
                  name="answers"
                  render={(arrayHelpers) => (
                    <GridElem spacing={5}>
                      <AnswerWrapper>
                        {formik.values.answers.map(
                          (answers: any, index: number) => {
                            const deleteAnswer = () => {
                              if (formik.values.answers.length > 1) {
                                arrayHelpers.remove(index);
                              }
                            };
                            return (
                              <AnswerControlContainer key={index}>
                                <AnswerFieldContainer>
                                  <FieldTextElem
                                    name={`${FORM_VALUE_ENUM.ANSWER}.${index}.text`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    title="EVENT.FORM.ANSWER"
                                    value={formik.values.answers[index].text}
                                    errorMessage={
                                      formik.touched?.answers?.[index]?.text &&
                                      formik.errors?.answers?.[index]?.text
                                    }
                                    error={
                                      formik.touched?.answers?.[index]?.text &&
                                      formik.errors?.answers?.[index]?.text
                                    }
                                  />
                                  <FieldTextElem
                                    name={`${FORM_VALUE_ENUM.ANSWER}.${index}.quantity`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    title="EVENT.FORM.QUANTITY"
                                    type="customNumber"
                                    step="0.00001"
                                    min="0"
                                    onKeyDown={(evt) =>
                                      evt.key === ',' && evt.preventDefault()
                                    }
                                    value={
                                      formik.values.answers[index].quantity
                                    }
                                    errorMessage={
                                      formik.touched?.answers?.[index]
                                        ?.quantity &&
                                      formik.errors?.answers?.[index]?.quantity
                                    }
                                    error={
                                      formik.touched?.answers?.[index]
                                        ?.quantity &&
                                      formik.errors?.answers?.[index]?.quantity
                                    }
                                  />
                                </AnswerFieldContainer>
                                <ButtonElem
                                  type="button"
                                  iconLeft={CloseIcon}
                                  color="backgroundThird"
                                  onClick={deleteAnswer}
                                  style={{
                                    minWidth: '46px',
                                    width: '46px',
                                    padding: '15px',
                                    marginTop: '26px',
                                  }}
                                />
                              </AnswerControlContainer>
                            );
                          },
                        )}
                      </AnswerWrapper>

                      <DoubleContainerElem>
                        <ButtonElem
                          type="button"
                          onClick={() =>
                            arrayHelpers.push({ text: '', quantity: '' })
                          }
                          tid="EVENT.CREATE.SURVEY.ADD_ANSWER"
                          color="backgroundThird"
                          iconRight={
                            theme.type === THEME_ENUM.LIGHT
                              ? addIconLight
                              : addIcon
                          }
                          disabled={isSubmitDisabled()}
                        />

                        {update ? (
                          <ButtonElem
                            type="button"
                            tid="EVENT.UPDATE.BUTTON.UPDATE"
                            fill="solid"
                            color="success"
                            disabled={isSubmitDisabled()}
                            onClick={formik.handleSubmit}
                          />
                        ) : (
                          <ButtonElem
                            type="button"
                            tid="EVENT.CREATE.BUTTON.CREATE"
                            fill="solid"
                            onClick={formik.handleSubmit}
                            disabled={isSubmitDisabled()}
                          />
                        )}
                      </DoubleContainerElem>
                    </GridElem>
                  )}
                />
              </GridElem>
            </React.Fragment>
          </GridElem>
        </form>
      </FormikProvider>
    </GridElem>
  );
};

const TripleContainer = styled(GridElem)`
  grid-template-columns: repeat(3, 1fr);

  @media screen and (width<600px) {
    grid-template-columns: 1fr;
  }
`;

const AnswerWrapper = styled(GridElem)``;

const AnswerControlContainer = styled(GridElem)`
  grid-template-columns: auto 46px;
  column-gap: ${Spacing(2)};
`;

const AnswerFieldContainer = styled(GridElem)`
  grid-template-columns: 3fr 1fr;
  column-gap: ${Spacing(2)};
  @media screen and (width<600px) {
    grid-template-columns: 1fr;
  }
`;

export const DeleteIconStyled = styled(DeleteIcon)`
  path {
    transition: all 0.2s;
  }
`;
