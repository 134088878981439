import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import {
  PERSON_ITEM_DATA_RAW,
  PERSON_ITEM_DATA,
  PERSON_ITEM_LIST_DATA,
} from './constant';

export const convertPerson = (
  person: PERSON_ITEM_DATA_RAW,
): PERSON_ITEM_DATA => {
  return {
    ...person,
  };
};

export const convertPersonList = (
  personList: PERSON_ITEM_DATA_RAW[],
): PERSON_ITEM_LIST_DATA => {
  return {
    list: personList?.map((person: PERSON_ITEM_DATA_RAW) => {
      return convertPerson(person);
    }),
    isEmpty: !personList?.length,
  };
};

export const convertPersonListToSelect = (data: PERSON_ITEM_DATA_RAW[]) => {
  const newList: SELECT_OPTION_ITEM_DATA[] = [];
  if (data) {
    data?.map((item: PERSON_ITEM_DATA_RAW) => {
      newList.push({
        value: item.id,

        label: item.name,
      });
    });
  }

  return newList;
};
