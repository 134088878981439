import styled, { css } from 'styled-components';
import { ModalElem } from '../../../common/modal';
import { TextElem } from '../../../common/text';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { UseMutationResult } from 'react-query';
import { ReactComponent as ImageIcon } from '../../../asset/svg/upload/image.svg';
import { ReactComponent as ImageLoadingIcon } from '../../../asset/svg/upload/image-loading.svg';
import ImageZoomIcon from '../../../asset/svg/upload/image-zoom.svg';
import { ReactComponent as CloseIcon } from '../../../asset/svg/upload/edit.svg';
import { ReactComponent as ClosePreviewIcon } from '../../../asset/svg/upload/close-preview.svg';
import { ReactComponent as EditIcon } from '../../../asset/svg/upload/close.svg';
import { FILE_ITEM_DATA_RAW } from '../../../data/file/constant';
import { i18n } from '../../../lib/lang';
import { Center, Content, Input, Progress, ProgressBar, Wrapper } from './file';
import { IonModal } from '@ionic/react';

export const ImageComponent: React.FC<{
  data?: FILE_ITEM_DATA_RAW;
  handleClick: (e: any) => Promise<void>;
  handleReselect: (e: any) => void;
  handleReset: (e: any) => void;
  openModalPreview: (e: any) => void;
  closeModalPreview: (e: any) => void;
  uploadFile: UseMutationResult;
  defaultValue?: string;
  getInputProps: any;
  getRootProps: any;
  error?: string;
  progress: number;
  modalPreviewVisible: boolean;
  description: boolean;
}> = ({
  data,
  getInputProps,
  getRootProps,
  uploadFile,
  defaultValue,
  handleClick,
  error,
  progress,
  handleReselect,
  handleReset,
  modalPreviewVisible,
  openModalPreview,
  closeModalPreview,
  description,
}) => {
  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };
  return (
    <Wrapper
      isError={!!error}
      onClick={handleClick}
      isSuccess={
        !!uploadFile.isSuccess || (!!defaultValue && !uploadFile.isLoading)
      }
      style={{ height: '135px' }}
      className="file-upload-wrapper"
    >
      {!uploadFile.isLoading && !uploadFile.isSuccess && !defaultValue && (
        <div {...getRootProps()}>
          <Input {...getInputProps()} />
          <Content spacing={3}>
            <Center spacing={2}>
              <ImageIcon />
              <TextElem
                tid="FILE.UPLOAD.STATUS.UPLOAD"
                type="medium"
                color="textFourth"
              />
            </Center>
            {description && (
              <>
                {!!error ? (
                  <TextElem tid={i18n.t(error)} size="small" color="error" />
                ) : (
                  <TextElem
                    tid="FILE.UPLOAD.DESCRIPTION.IMG"
                    size="small"
                    color="textPrimary"
                  />
                )}
              </>
            )}
          </Content>
        </div>
      )}
      {uploadFile.isLoading && (
        <Content spacing={3}>
          <Center spacing={2}>
            <ImageLoadingIcon />
            <TextElem
              tid="FILE.UPLOAD.STATUS.UPLOADING"
              type="medium"
              color="textFourth"
            />
          </Center>
          {description && (
            <TextElem
              tid="FILE.UPLOAD.STATUS.UPLOADING_DESCRIPTION"
              size="small"
              color="textPrimary"
            />
          )}

          <Progress>
            <ProgressBar width={progress} />
          </Progress>
        </Content>
      )}

      {(!!uploadFile.isSuccess ||
        (!!defaultValue && !uploadFile.isLoading)) && (
        <Background onClick={openModalPreview}>
          <SuccessButtonContainer
            className="SuccessButtonContainer"
            onClick={stopPropagation}
          >
            <SuccessButton onClick={handleReselect}>
              <EditIcon className="editIcon" />
            </SuccessButton>
            <SuccessButton onClick={handleReset}>
              <CloseIcon />
            </SuccessButton>
          </SuccessButtonContainer>

          {!!error ? (
            <Content spacing={3}>
              <Center spacing={2}>
                <ImageIcon />
                <TextElem
                  tid="FILE.UPLOAD.STATUS.UPLOAD"
                  type="medium"
                  color="textFourth"
                />
              </Center>
              {description && (
                <>
                  {!!error ? (
                    <TextElem tid={i18n.t(error)} size="small" color="error" />
                  ) : (
                    <TextElem
                      tid="FILE.UPLOAD.DESCRIPTION.IMG"
                      size="small"
                      color="textPrimary"
                    />
                  )}
                </>
              )}
            </Content>
          ) : (
            <>
              <Image src={data?.url || defaultValue} />

              <ZoomIcon className="icon">
                <img src={ImageZoomIcon} />
              </ZoomIcon>

              <Modal
                isOpen={modalPreviewVisible}
                onDidDismiss={closeModalPreview}
              >
                <img
                  src={data?.url || defaultValue}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    margin: 'auto',
                  }}
                />
                <ClosePreviewIconStyled onClick={closeModalPreview} />
              </Modal>
            </>
          )}
        </Background>
      )}
    </Wrapper>
  );
};

export const ClosePreviewIconStyled = styled(ClosePreviewIcon)`
  position: absolute;
  right: 16px;
  top: 16px;
  path {
    transition: all 0.2s;
  }
  cursor: pointer;
  :hover {
    path {
      stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
    }
  }
`;

export const Modal = styled(IonModal)<{ minHeight?: number }>`
  pointer-events: none;
  --height: auto;

  --backdrop-opacity: 0;
  --box-shadow: none;

  background: rgba(20, 18, 26, 0.8);

  &.modal-handle::before {
    display: none;
    content: none;
  }

  ::part(content) {
    background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
    top: 50%;
    width: 100%;
    left: 50%;
    max-width: 80%;
    height: 75%;
    border-radius: 16px;
  }

  & > div {
    margin: 0;
    /* margin-right: 40px; */
    max-width: 100%;
    padding: 0;
  }
`;

export const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 117px;
`;

export const SuccessButton = styled.div`
  padding: ${Spacing(2)};
  border-radius: 6px;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
  height: 28px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  path {
    transition: all 0.2s;
  }
  :hover {
    path {
      stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
      fill: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
    }
    .editIcon {
      path {
        stroke: transparent;
      }
    }
  }
`;

export const SuccessButtonContainer = styled.div`
  display: flex;
  position: absolute;
  gap: ${Spacing(2)};
  right: 12px;
  top: 12px;
`;

const Background = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${Spacing(2)};
  position: relative;
  .icon {
    display: none;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    position: absolute;
  }
  :not(:has(.SuccessButtonContainer:hover)):hover {
    .icon {
      display: flex;
    }
  }
`;

export const ZoomIcon = styled.div`
  height: 40px;
  width: 40px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  align-items: center;
  justify-content: center;
  background: white;
`;
