import React, { useRef, useState } from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { GridElem } from '../../common/grid';

import { TextElem } from '../../common/text';
import { VIEWER_ITEM_DATA } from '../../data/viewer/constant';
import { StepperElem } from '../../common/stepper';
import { STEPPER_LIST } from './constant';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import { Skeleton } from './skeleton';
import ReactDOM from 'react-dom';

import { ReactComponent as CloseIcon } from '../../asset/svg/common/close.svg';
import { useOnClickOutside } from 'usehooks-ts';
import { MESSAGE_ITEM_DATA_RAW } from '../../data/message/constant';
import { FlexElem } from '../../common/flex';
import { convertTimeWithTimeZone } from '../../lib/lang/service';

export const Component: React.FC<{
  data?: VIEWER_ITEM_DATA;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  visible: boolean;
  onClose: any;
}> = ({
  data,
  isLoading,
  visible,
  onClose,
  isSuccess,
  isError,
  errorMessage,
}) => {
  const getActiveSteppe = () => {
    if (data?.reservation?.payment?.confirm) {
      return 5;
    }

    if (data?.reservation) {
      return 4;
    }
    if (data?.isButtonOpen) {
      return 3;
    }
    if (data?.isButtonSee) {
      return 2;
    }
    return 1;
  };
  const ref = useRef(null);

  const handleClickOutside = () => {
    onClose();
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <>
      {visible && (
        <>
          {ReactDOM.createPortal(
            <Wrapper>
              <CustomModal ref={ref}>
                <GridElem spacing={5} style={{ overflow: 'hidden' }}>
                  <GridElem style={{ gridTemplateColumns: 'auto 16px' }}>
                    <TextElem
                      tid="VIEWER.ITEM.TITLE"
                      size="main"
                      type="semi-bold"
                    />
                    <CloseIconStyled onClick={onClose} />
                  </GridElem>

                  {isLoading && <Skeleton />}
                  {isError && <AlertActionElem text={errorMessage} />}
                  {isSuccess && data && (
                    <>
                      <Container>
                        <GridElem spacing={2}>
                          <TextElem
                            tid="VIEWER.ITEM.NAME"
                            size="small"
                            color="textPrimary"
                          />
                          {!!data.name ? (
                            <TextElem type="semi-bold">{data.name}</TextElem>
                          ) : (
                            <TextElem
                              type="semi-bold"
                              tid={`VIEWER.ITEM.EMPTY`}
                            />
                          )}
                        </GridElem>
                        <GridElem spacing={2}>
                          <TextElem
                            tid="VIEWER.ITEM.ID"
                            size="small"
                            color="textPrimary"
                          />
                          {!!data.id ? (
                            <TextElem type="semi-bold">{data.id}</TextElem>
                          ) : (
                            <TextElem
                              type="semi-bold"
                              tid={`VIEWER.ITEM.EMPTY`}
                            />
                          )}
                        </GridElem>
                        <GridElem spacing={2}>
                          <TextElem
                            tid="VIEWER.ITEM.PHONE"
                            size="small"
                            color="textPrimary"
                          />
                          {!!data.phone ? (
                            <TextElem type="semi-bold">{data.phone}</TextElem>
                          ) : (
                            <TextElem
                              type="semi-bold"
                              tid={`VIEWER.ITEM.EMPTY`}
                            />
                          )}
                        </GridElem>
                        <GridElem spacing={2}>
                          <TextElem
                            tid="VIEWER.ITEM.IP"
                            size="small"
                            color="textPrimary"
                          />

                          {!!data.ip.length ? (
                            data.ip.map((item: string) => (
                              <TextElem type="semi-bold">{item}</TextElem>
                            ))
                          ) : (
                            <TextElem
                              type="semi-bold"
                              tid="VIEWER.ITEM.EMPTY"
                            />
                          )}
                        </GridElem>
                        <GridElem spacing={2}>
                          <TextElem
                            tid="VIEWER.ITEM.LOCATION"
                            size="small"
                            color="textPrimary"
                          />

                          {!!data.location.length ? (
                            data.location.map((item: string) => (
                              <TextElem type="semi-bold">{item}</TextElem>
                            ))
                          ) : (
                            <TextElem
                              type="semi-bold"
                              tid="VIEWER.ITEM.EMPTY"
                            />
                          )}
                        </GridElem>
                        <GridElem spacing={2}>
                          <TextElem
                            tid="VIEWER.ITEM.DEVICES"
                            size="small"
                            color="textPrimary"
                          />

                          {!!data.deviceInfo.length ? (
                            data.deviceInfo.map((item: string) => (
                              <TextElem type="semi-bold">{item}</TextElem>
                            ))
                          ) : (
                            <TextElem
                              type="semi-bold"
                              tid="VIEWER.ITEM.EMPTY"
                            />
                          )}
                        </GridElem>
                        <GridElem spacing={2}>
                          <TextElem
                            tid="VIEWER.ITEM.BAN"
                            size="small"
                            color="textPrimary"
                          />
                          {!!data.isBanned ? (
                            <TextElem type="semi-bold" tid={`Yes`} />
                          ) : (
                            <TextElem type="semi-bold" tid={`No`} />
                          )}
                        </GridElem>
                        <GridElem spacing={2}>
                          <TextElem
                            tid="Mute"
                            size="small"
                            color="textPrimary"
                          />
                          {!!data.isMuted ? (
                            <TextElem type="semi-bold" tid={`Yes`} />
                          ) : (
                            <TextElem type="semi-bold" tid={`No`} />
                          )}
                        </GridElem>

                        <GridElem spacing={2}>
                          <TextElem
                            tid="VIEWER.ITEM.WATCH_PERIOD"
                            size="small"
                            color="textPrimary"
                          />

                          {!!data.duration.length ? (
                            data.duration.map((item: string[]) => (
                              <TextElem
                                type="semi-bold"
                                tid="VIEWER.ITEM.DURATION"
                                tvalue={{ start: item[0], end: item[1] }}
                              />
                            ))
                          ) : (
                            <TextElem
                              type="semi-bold"
                              tid="VIEWER.ITEM.EMPTY"
                            />
                          )}
                        </GridElem>
                        <GridElem spacing={2}>
                          <TextElem
                            tid="VIEWER.ITEM.CONNECT_TIME"
                            size="small"
                            color="textPrimary"
                          />

                          {!!data.connectDate.length ? (
                            data.connectDate.map((item: string) => (
                              <TextElem type="semi-bold">{item}</TextElem>
                            ))
                          ) : (
                            <TextElem
                              type="semi-bold"
                              tid="VIEWER.ITEM.EMPTY"
                            />
                          )}
                        </GridElem>
                        <GridElem spacing={2}>
                          <TextElem
                            tid="VIEWER.ITEM.WATCH_TIME"
                            size="small"
                            color="textPrimary"
                          />

                          <TextElem type="semi-bold">{data.watchTime}</TextElem>
                        </GridElem>
                        <GridElem spacing={2}>
                          <TextElem
                            tid="VIEWER.ITEM.REQUEST"
                            size="small"
                            color="textPrimary"
                          />
                          <TextElem
                            type="semi-bold"
                            tid={
                              data.needRecord
                                ? `VIEWER.LIST.REQUEST_YES`
                                : `VIEWER.LIST.REQUEST_NO`
                            }
                          />
                        </GridElem>

                        {data.reservation && (
                          <>
                            <GridElem spacing={2}>
                              <TextElem
                                tid="VIEWER.ITEM.RESERVATION_NAME"
                                size="small"
                                color="textPrimary"
                              />
                              <TextElem type="semi-bold">
                                {data.reservation.name}
                              </TextElem>
                            </GridElem>
                            <GridElem spacing={2}>
                              <TextElem
                                tid="VIEWER.ITEM.RESERVATION_PHONE"
                                size="small"
                                color="textPrimary"
                              />
                              <TextElem type="semi-bold">
                                {data.reservation.phone}
                              </TextElem>
                            </GridElem>
                          </>
                        )}
                        <GridElem spacing={2}>
                          <TextElem
                            tid="VIEWER.ITEM.STATUS"
                            size="small"
                            color="textPrimary"
                          />
                          <ContentContainer>
                            <StepperElem
                              stepArray={STEPPER_LIST}
                              activeStep={getActiveSteppe()}
                              numeretic={false}
                            />
                          </ContentContainer>
                        </GridElem>
                        <GridElem
                          spacing={2}
                          style={{
                            gridTemplateRows: 'min-content auto',
                            height: '100%',
                          }}
                        >
                          <TextElem
                            tid="VIEWER.ITEM.UTM"
                            size="small"
                            color="textPrimary"
                          />
                          <ContentContainer>
                            {Object.keys(data.utm).length === 0 ? (
                              <TextElem
                                type="semi-bold"
                                tid="VIEWER.ITEM.EMPTY"
                              />
                            ) : (
                              Object.entries(data.utm).map(([key, value]) => (
                                <span style={{ display: 'block' }}>
                                  <TextElem color="textPrimary" type="medium">
                                    {key}:{' '}
                                  </TextElem>
                                  <TextElem type="medium">{value}</TextElem>
                                </span>
                              ))
                            )}
                          </ContentContainer>
                        </GridElem>

                        <ChatContainer spacing={2}>
                          <TextElem
                            tid="VIEWER.ITEM.CHAT"
                            size="small"
                            color="textPrimary"
                          />
                          <ContentContainer style={{ paddingRight: '0' }}>
                            <ChatContent>
                              <>
                                {data.message?.length == 0 && (
                                  <TextElem
                                    type="semi-bold"
                                    tid="VIEWER.ITEM.CHAT_EMPTY"
                                  />
                                )}
                                {data.message?.map(
                                  (item: MESSAGE_ITEM_DATA_RAW) => (
                                    <FlexElem
                                      style={{
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <TextElem
                                        color="textFourth"
                                        style={{ wordBreak: 'break-all' }}
                                        size="mobileDefault"
                                      >
                                        {item.content}
                                      </TextElem>
                                      <TextElem
                                        type="medium"
                                        color="textPrimary"
                                        size="mobileDefault"
                                      >
                                        {convertTimeWithTimeZone(
                                          item.createDate,
                                        )}
                                      </TextElem>
                                    </FlexElem>
                                  ),
                                )}
                              </>
                            </ChatContent>
                          </ContentContainer>
                        </ChatContainer>
                      </Container>
                    </>
                  )}
                </GridElem>
              </CustomModal>
            </Wrapper>,
            document.body,
          )}
        </>
      )}
    </>
  );
};
const ContentContainer = styled.div`
  padding: ${Spacing(5)};
  border-radius: 12px;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  height: 100%;
`;

const ChatContent = styled(GridElem)`
  overflow: scroll;
  max-height: 400px;
  height: 100%;
  scrollbar-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]}
    transparent;
  scrollbar-width: thin;
  padding-right: ${Spacing(5)};
`;

const ChatContainer = styled(GridElem)`
  grid-column-start: 1;
  grid-column-end: 3;
  grid-template-rows: min-content auto;
  max-height: 400px;
  height: 100%;

  @media screen and (width<620px) {
    grid-column-start: 1;
    grid-column-end: 1;
  }
`;

const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
  path {
    transition: all 0.2s;
  }
  :hover {
    path {
      stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
    }
  }
`;

const Container = styled(GridElem)`
  grid-template-columns: 1fr 1fr;
  max-height: calc(80dvh - 100px);
  scrollbar-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]}
    transparent;
  scrollbar-width: thin;
  overflow-y: auto;
  padding: ${Spacing(0)} ${Spacing(1)};
  &::-webkit-scrollbar {
    width: '8px';
  }
  &::-webkit-scrollbar-track {
    box-shadow: nset 0 0 6px grey;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: darkBlue;
    border-radius: 15px;
    height: 2px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: lightBlue;
    max-height: 10px;
  }
  @media screen and (width<620px) {
    grid-template-columns: 1fr;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(20, 18, 26, 0.8);

  @media screen and (width<620px) {
    align-items: end;
    border-radius-bottom: 0;
  }
`;

const CustomModal = styled.div`
  display: block;
  max-height: 80dvh;
  height: auto;
  padding: ${Spacing(6)} ${Spacing(4)};
  width: 620px;
  @media screen and (width<620px) {
    align-items: end;
    border-radius: 16px 16px 0 0;
  }
  overflow-y: auto;

  border-radius: 16px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
`;
