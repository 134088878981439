import {
  SESSION_ITEM_LIST_DATA_RAW,
  SESSION_ITEM_LIST_DATA,
} from '../../data/session/constant';

export const MODULE_NAME = 'SESSION_LIST_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: (webinarId: string) => `/webinar/${webinarId}/session`,
};

export interface ACTION_RESPONSE_INTER extends SESSION_ITEM_LIST_DATA_RAW {}

export interface DATA extends SESSION_ITEM_LIST_DATA {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}
