import React, { useEffect } from 'react';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
} from './constant';

import { Component } from './component';
import { email, required } from '../../lib/validation/service';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { useMutation, useQueryClient } from 'react-query';
import { action as fetch } from './action';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { useHistory } from 'react-router';
import { WEBINAR_SETTINGS_PAGE_PATH_DYNAMIC } from '../../page/webinar-settings';
import { WEBINAR_ITEM_DATA_RAW } from '../../data/webinar/constant';
import { WEBINAR_ITEM_DATA_MODULE_NAME } from '../webinar-item-data';
import { convert } from './convert';
import { TYPE_OPTION_LIST } from '../event-filter/constant';

export const Container: React.FC<{
  id: string;
  query: {
    logo?: boolean;
    speaker?: boolean;
    session?: boolean;
    topics?: boolean;
    speakerImg?: boolean;
    person?: boolean;
    event?: boolean;
    webgift?: boolean;
    description?: boolean;
    buttonLink?: boolean;
    sessionId?: string;
  };
}> = ({ id, query }) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const onSuccess = (d: any, values: any) => {
    queryClient.invalidateQueries([WEBINAR_ITEM_DATA_MODULE_NAME, id]);

    history.push(WEBINAR_SETTINGS_PAGE_PATH_DYNAMIC(id));
  };

  const action = useMutation((values) => fetch(values, id), { onSuccess });

  const config = {
    [FORM_VALUE_ENUM.WEBINAR]: [required],
    [FORM_VALUE_ENUM.TOPICS]: [],
    [FORM_VALUE_ENUM.SESSION]: [],
    [FORM_VALUE_ENUM.SPEAKER_IMG]: [],
    [FORM_VALUE_ENUM.SPEAKER]: [],
    [FORM_VALUE_ENUM.LOGO]: [],
    [FORM_VALUE_ENUM.PERSON]: [],
    [FORM_VALUE_ENUM.EVENT]: [],
    [FORM_VALUE_ENUM.BUTTON_LINK]: [],
    [FORM_VALUE_ENUM.SESSION_ID]: [],
    [FORM_VALUE_ENUM.SESSION_PARENT]: [],
    [FORM_VALUE_ENUM.VIDEO]: [],
    [FORM_VALUE_ENUM.PREVIEW]: [],
    [FORM_VALUE_ENUM.CRM]: [],
    [FORM_VALUE_ENUM.YOUTUBE]: [],
    [FORM_VALUE_ENUM.PIXEL]: [],
    [FORM_VALUE_ENUM.CHAT]: [],
    [FORM_VALUE_ENUM.EVENT_TYPE]: [],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues = Object.keys(query).length
    ? {
        [FORM_VALUE_ENUM.TOPICS]: query?.topics ? true : false,
        [FORM_VALUE_ENUM.SPEAKER]: query?.speaker ? true : false,
        [FORM_VALUE_ENUM.SPEAKER_IMG]: query?.speakerImg ? true : false,
        [FORM_VALUE_ENUM.LOGO]: query?.logo ? true : false,
        [FORM_VALUE_ENUM.SESSION]: query?.session ? true : false,
        [FORM_VALUE_ENUM.PERSON]: query?.person ? true : false,
        [FORM_VALUE_ENUM.EVENT]: query?.event ? true : false,
        [FORM_VALUE_ENUM.WEBGIFT]: query?.webgift ? true : false,
        [FORM_VALUE_ENUM.DESCRIPTION]: query?.description ? true : false,
        [FORM_VALUE_ENUM.BUTTON_LINK]: query?.buttonLink ? true : false,
        [FORM_VALUE_ENUM.VIDEO]: false,
        [FORM_VALUE_ENUM.PREVIEW]: false,
        [FORM_VALUE_ENUM.CRM]: false,
        [FORM_VALUE_ENUM.CHAT]: false,
        [FORM_VALUE_ENUM.YOUTUBE]: false,
        [FORM_VALUE_ENUM.PIXEL]: false,
        [FORM_VALUE_ENUM.WEBINAR]: '',
        [FORM_VALUE_ENUM.SESSION_ID]: query?.sessionId || '',
        [FORM_VALUE_ENUM.SESSION_PARENT]: '',
        [FORM_VALUE_ENUM.EVENT_TYPE]: TYPE_OPTION_LIST,
      }
    : {
        [FORM_VALUE_ENUM.TOPICS]: false,
        [FORM_VALUE_ENUM.SPEAKER]: false,
        [FORM_VALUE_ENUM.SPEAKER_IMG]: false,
        [FORM_VALUE_ENUM.LOGO]: false,
        [FORM_VALUE_ENUM.SESSION]: false,
        [FORM_VALUE_ENUM.PERSON]: false,
        [FORM_VALUE_ENUM.EVENT]: false,
        [FORM_VALUE_ENUM.DESCRIPTION]: false,
        [FORM_VALUE_ENUM.WEBGIFT]: false,
        [FORM_VALUE_ENUM.BUTTON_LINK]: false,
        [FORM_VALUE_ENUM.VIDEO]: false,
        [FORM_VALUE_ENUM.PREVIEW]: false,
        [FORM_VALUE_ENUM.CRM]: false,
        [FORM_VALUE_ENUM.CHAT]: false,
        [FORM_VALUE_ENUM.YOUTUBE]: false,
        [FORM_VALUE_ENUM.PIXEL]: false,
        [FORM_VALUE_ENUM.WEBINAR]: '',
        [FORM_VALUE_ENUM.SESSION_ID]: '',
        [FORM_VALUE_ENUM.SESSION_PARENT]: '',
        [FORM_VALUE_ENUM.EVENT_TYPE]: TYPE_OPTION_LIST,
      };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    onSubmit: (values: FORM_VALUE_INTER) => {
      return action.mutate(convert(values));
    },
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }

    if (action.isSuccess) {
      return true;
    }
    if (
      !formik.values[FORM_VALUE_ENUM.TOPICS] &&
      !formik.values[FORM_VALUE_ENUM.SESSION] &&
      !formik.values[FORM_VALUE_ENUM.SPEAKER_IMG] &&
      !formik.values[FORM_VALUE_ENUM.SPEAKER] &&
      !formik.values[FORM_VALUE_ENUM.PERSON] &&
      !formik.values[FORM_VALUE_ENUM.EVENT] &&
      !formik.values[FORM_VALUE_ENUM.DESCRIPTION] &&
      !formik.values[FORM_VALUE_ENUM.WEBGIFT] &&
      !formik.values[FORM_VALUE_ENUM.BUTTON_LINK] &&
      !formik.values[FORM_VALUE_ENUM.VIDEO] &&
      !formik.values[FORM_VALUE_ENUM.PREVIEW] &&
      !formik.values[FORM_VALUE_ENUM.CRM] &&
      !formik.values[FORM_VALUE_ENUM.CHAT] &&
      !formik.values[FORM_VALUE_ENUM.YOUTUBE] &&
      !formik.values[FORM_VALUE_ENUM.PIXEL] &&
      !formik.values[FORM_VALUE_ENUM.LOGO]
    ) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) =>
    formik.setFieldValue(name, e.value);

  return (
    <Component
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      isError={isError()}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
      setFieldValue={setFieldValue}
    />
  );
};
