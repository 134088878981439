export const MODULE_NAME = 'WEBINAR_ITEM_CREATE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  TOPICS = 'topics',
  WEBINAR = 'webinar',
  SPEAKER_IMG = 'speakerImg',
  LOGO = 'logo',
  SESSION = 'session',
  SPEAKER = 'speaker',
  PERSON = 'person',
  EVENT = 'event',
  DESCRIPTION = 'description',
  WEBGIFT = 'webgift',
  SESSION_ID = 'sessionId',
  SESSION_PARENT = 'sessionParent',
  BUTTON_LINK = 'buttonLink',
  EVENT_TYPE = 'eventType',
  VIDEO = 'video',
  PREVIEW = 'preview',
  CRM = 'crm',
  CHAT = 'chat',
  YOUTUBE = 'youtube',
  PIXEL = 'pixel',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.SPEAKER_IMG]: boolean;
  [FORM_VALUE_ENUM.LOGO]: boolean;
  [FORM_VALUE_ENUM.SESSION]: boolean;
  [FORM_VALUE_ENUM.SPEAKER]: boolean;
  [FORM_VALUE_ENUM.TOPICS]: boolean;
  [FORM_VALUE_ENUM.PERSON]: boolean;
  [FORM_VALUE_ENUM.DESCRIPTION]: boolean;
  [FORM_VALUE_ENUM.WEBGIFT]: boolean;
  [FORM_VALUE_ENUM.VIDEO]: boolean;
  [FORM_VALUE_ENUM.PREVIEW]: boolean;
  [FORM_VALUE_ENUM.CRM]: boolean;
  [FORM_VALUE_ENUM.CHAT]: boolean;
  [FORM_VALUE_ENUM.YOUTUBE]: boolean;
  [FORM_VALUE_ENUM.PIXEL]: boolean;
  [FORM_VALUE_ENUM.BUTTON_LINK]: boolean;
  [FORM_VALUE_ENUM.WEBINAR]: string;
  [FORM_VALUE_ENUM.SESSION_ID]: string;
  [FORM_VALUE_ENUM.SESSION_PARENT]: string;
  [FORM_VALUE_ENUM.EVENT_TYPE]: any[];
}

export const API = {
  TYPE: 'POST',
  URL: (id: string) => `/webinar/import/${id}`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
