import React from 'react';

import { ACTION_ERROR_INTER, MODULE_NAME } from './constant';

import { useQuery } from 'react-query';
import { getWebinar } from './action';
import { Component } from './component';
import {
  SESSION_ITEM_DATA,
  SESSION_ITEM_DATA_RAW,
} from '../../data/session/constant';
import { convertSession } from '../../data/session/convert';
import { UTMObject } from '../../data/viewer/constant';

export const Container: React.FC<{ sessionId: string; query: UTMObject }> = ({
  sessionId,
  query,
}) => {
  const preFetch = useQuery(MODULE_NAME, () => getWebinar(sessionId), {
    retry: 0,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
  const getData = (): SESSION_ITEM_DATA | undefined => {
    const data = preFetch.data as unknown as SESSION_ITEM_DATA_RAW;

    if (data) {
      return convertSession(data);
    }
  };

  const isLoading = () => {
    if (preFetch.isLoading || preFetch.isFetching) {
      return true;
    }
  };

  const isSuccess = () => {
    if (preFetch.isSuccess && !preFetch.isFetching) {
      return true;
    }
  };

  const isError = () => {
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = preFetch.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const data = getData();
  return (
    <Component
      data={data}
      isLoading={isLoading()}
      isError={isError()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
      sessionId={sessionId}
      query={query}
    />
  );
};
