import { EVENT_TYPE } from '../../data/event/constant';
import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'EVENT_FILTER_MODULE_NAME';

export const API = {
  EXPORT_TO_EXCEL: {
    TYPE: 'GET',
    URL: (id: string) => `/event/exportToExcel/${id}`,
  },
  IMPORT_FROM_EXCEL: {
    TYPE: 'POST',
    URL: (id: string) => `/event/importFromExcel/${id}`,
  },
};

export const SORT_OPTION_LIST = [
  { value: 'name_desc', label: i18n.t('EVENT.FILTER.SORT.NAME_DESC') },
  { value: 'name_asc', label: i18n.t('EVENT.FILTER.SORT.NAME_ASC') },
];

export const TYPE_OPTION_LIST = [
  { value: EVENT_TYPE.MESSAGE, label: i18n.t('EVENT.TYPE.MESSAGE') },
  { value: EVENT_TYPE.BUTTON, label: i18n.t('EVENT.TYPE.BUTTON') },
  { value: EVENT_TYPE.REACTION, label: i18n.t('EVENT.TYPE.REACTION') },
  { value: EVENT_TYPE.BANNER, label: i18n.t('EVENT.TYPE.BANNER') },
  { value: EVENT_TYPE.RESERVATION, label: i18n.t('EVENT.TYPE.RESERVATION') },
  { value: EVENT_TYPE.TIMECODE, label: i18n.t('EVENT.TYPE.TIMECODE') },
  {
    value: EVENT_TYPE.RESERVATION_CREATED,
    label: i18n.t('EVENT.TYPE.RESERVATION_CREATED'),
  },
  { value: EVENT_TYPE.QUESTION, label: i18n.t('EVENT.TYPE.QUESTION') },
  { value: EVENT_TYPE.SURVEY, label: i18n.t('EVENT.TYPE.SURVEY') },
];
