import React, { ReactNode, useEffect, useState } from 'react';
import { i18n } from '../../lib/lang';
import { ButtonElem } from '../button';
import {
  BUTTON_COLOR_TYPE_DATA,
  BUTTON_ICON_TYPE_DATA,
  MODAL_TYPE_DATA,
  MODAL_TYPE_ENUM,
  PROPS_TYPE,
} from './constant';
import { ModalElem } from '../modal';
import styled from 'styled-components';
import deleteIcon from '../../asset/svg/button/delete.svg';
import { Spacing } from '../../theme';
import { GridElem } from '../grid';
import { TextElem } from '../text';

export const Elem: React.FC<PROPS_TYPE> = ({
  titleTid,
  titleValue,
  infoTid,
  infoValue,
  cancelTid,
  cancelTvalue,
  children,
  divider,
  action,
  open,
  onClose,
  closeAction,
  onClick,
  type = 'confirm',
  ref,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    onClose && onClose();
    setOpenModal(false);
  };

  const handleAction = (e: any) => {
    action && action();
    setOpenModal(false);
  };

  useEffect(() => {
    if (open === true) {
      setOpenModal(true);
    }
  }, [open]);

  const getText = () => {
    switch (type) {
      case 'delete':
        return (
          <GridElem spacing={2}>
            <TextElem tid="COMMON.MODAL.TEXT.DELETE.1" type="medium" />
            <TextElem tid="COMMON.MODAL.TEXT.DELETE.2" type="medium" />
          </GridElem>
        );
      case 'confirm':
        return (
          <GridElem spacing={2}>
            <TextElem tid="COMMON.MODAL.TEXT.CONFIRM" type="medium" />
          </GridElem>
        );
    }
  };

  return (
    <>
      <Container onClick={handleClickOpen}>{children}</Container>
      <ModalElem
        open={openModal}
        onClose={handleClose}
        closeAction={closeAction}
        ref={ref}
        titleTid="COMMON.MODAL.TITLE.DELETE"
      >
        <GridElem spacing={5}>
          {getText()}

          <ButtonContainer>
            <ButtonElem
              onClick={handleClose}
              color="backgroundThird"
              tid="COMMON.MODAL.BUTTON.CANCEL"
            />
            <ButtonElem
              tid={MODAL_TYPE_DATA[type]}
              onClick={handleAction}
              color={BUTTON_COLOR_TYPE_DATA[type]}
              fill="solid"
              iconRight={BUTTON_ICON_TYPE_DATA[type]}
            />
          </ButtonContainer>
        </GridElem>
      </ModalElem>
    </>
  );
};
const Container = styled.div`
  display: flex;
`;

const ButtonContainer = styled.div`
  display: grid;
  gap: ${Spacing(3)};
  grid-template-columns: 1fr 1fr;
`;
