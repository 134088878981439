import React from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { GridElem } from '../../common/grid';
import { DATA } from './constant';
import { AlertEmptyElem } from '../../common/alert-empty';
import { WEBINAR_ITEM_DATA } from '../../data/webinar/constant';
import { WebinarItemElem } from '../../data/webinar/frame/webinar-item-list.elem ';
import { WebinarSkeletonElem } from '../../data/webinar/frame/webinar-skeleton.elem';
import { Spacing } from '../../theme';
import { WebinarFilterContainer } from '../webinar-filter';
import { IonInfiniteScroll } from '@ionic/react';

export const Component: React.FC<{
  data?: DATA;

  isError?: boolean;
  errorMessage?: string;
  project: string;
  setProject: Function;
  setType: Function;
  search: string;
  setSearch: Function;
  type: any;
  isFetching?: boolean;
  fetchNextPage: Function;
  hasNextPage?: boolean;
}> = ({
  data,
  isError,
  errorMessage,
  project,
  setProject,
  setType,
  search,
  setSearch,
  type,
  isFetching,
  fetchNextPage,
  hasNextPage,
}) => {
  return (
    <GridElem spacing={5}>
      <WebinarFilterContainer
        project={project}
        setProject={setProject}
        setType={setType}
        search={search}
        setSearch={setSearch}
        type={type}
      />
      {isError && <AlertActionElem text={errorMessage} />}

      {data && data.isEmpty && !isFetching && <AlertEmptyElem />}

      <GridElem spacing={3}>
        {data && !data.isEmpty && (
          <Container style={{ width: '100%' }}>
            {data.list.map((item: WEBINAR_ITEM_DATA) => (
              <WebinarItemElem {...item} key={item.id} />
            ))}
          </Container>
        )}

        {isFetching && <WebinarSkeletonElem />}
      </GridElem>
      {hasNextPage && (
        <IonInfiniteScroll
          onIonInfinite={async (ev) => {
            await fetchNextPage();
            ev.target.complete();
          }}
          threshold="100px"
          disabled={!hasNextPage}
        >
          <div style={{ height: '1px' }}></div>
        </IonInfiniteScroll>
      )}
    </GridElem>
  );
};

const Container = styled.div`
  display: grid;
  @media screen and (width>800px) {
    grid-template-columns: auto auto repeat(3, min-content);
  }
  row-gap: ${Spacing(3)};

  @media screen and (width<=600px) {
    margin-bottom: 78px;
  }
`;
