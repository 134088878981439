import { STEPPER_ITEM } from '../../common/stepper/elem';
import { COLOR_ENUM, COLOR_TYPE } from '../../theme/color';
import { EVENT_ITEM_DATA_RAW } from '../event/constant';
import { WEBINAR_ITEM_DATA_RAW } from '../webinar/constant';

export interface SESSION_ITEM_DATA_RAW {
  id: string;
  createDate: string;
  name: string;
  onlineUserMin: number;
  onlineUserMax: number;
  date: string;
  timeZone: string;
  serverDate: string;
  serverDateEnd: string;
  availableSeats: number;
  userOnline: number;
  sessionStarted: boolean;
  webinar: WEBINAR_ITEM_DATA_RAW;
  auto: boolean;
  sessionIsOver?: boolean;
  status: SESSION_STATUS;
  event: EVENT_ITEM_DATA_RAW[];
  timecode: STEPPER_ITEM[];
  eventStarted: boolean;
  chatActive: boolean;
  preModerationActive: boolean;
  mediaLiveChannelId?: string;

  mediaPackageChannelId?: string;

  mediaPackageEndpointId?: string;

  mediaPackageEndpointUrl?: string;
}

export enum SESSION_STATUS {
  PLAN = 'PLAN',
  ONGOING = 'ONGOING',
  COMPLETED = 'COMPLETED',
  AUTO = 'AUTO',
  ALL = 'ALL',
}

export const SESSION_STATUS_COLOR = {
  [SESSION_STATUS.PLAN]: COLOR_ENUM.DEFAULT,
  [SESSION_STATUS.ONGOING]: COLOR_ENUM.ONGOING_STATUS,
  [SESSION_STATUS.COMPLETED]: COLOR_ENUM.SUCCESS,
  [SESSION_STATUS.AUTO]: COLOR_ENUM.DEFAULT,
  [SESSION_STATUS.ALL]: COLOR_ENUM.DEFAULT,
};
export interface SESSION_ITEM_LIST_DATA_RAW {
  list: SESSION_ITEM_DATA_RAW[];
}

export interface SESSION_ITEM_DATA extends SESSION_ITEM_DATA_RAW {
  statusColor: COLOR_TYPE;
  rawDate: string;
  day: string;
  time: string;
}

export interface SESSION_ITEM_LIST_DATA {
  list: SESSION_ITEM_DATA[];
  isEmpty: boolean;
}

export const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN_URL;

export const API = {
  LIST: {
    TYPE: 'GET',
    URL: (webinarId: string) => `webinar/${webinarId}/session`,
  },
  VIEWER_DATE: {
    TYPE: 'GET',
    URL: (sessionId: string) => `/session/viewerDateList/${sessionId}`,
  },
  LIST_2: {
    TYPE: 'GET',
    URL: (param: string) => `/session/list?${param}`,
  },
};
