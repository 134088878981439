import React from 'react';
import { AlertActionElem } from '../../common/alert-action';
import { GridElem } from '../../common/grid';
import { ContentContainerElem } from '../../common/content-container';
import { TextElem } from '../../common/text';
import { WEBINAR_ITEM_DATA } from '../../data/webinar/constant';
import { FormikValues } from 'formik';
import { FileItemUploadContainer } from '../file-item-upload';
import { WEBINAR_UPDATE_FORM_VALUE_ENUM } from '../webinar-item-update-general';
import { Skeleton } from './skeleton';
import { DoubleContainerElem } from '../../common/double-container';

export const Component: React.FC<{
  formik: FormikValues;
  data?: WEBINAR_ITEM_DATA;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  onSuccessUpload: Function;
}> = ({
  onSuccessUpload,
  data,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  formik,
}) => {
  return (
    <GridElem spacing={5}>
      <ContentContainerElem>
        <TextElem
          tid="WEBINAR.UPDATE.VIDEO.TITLE"
          type="semi-bold"
          size="main"
        />

        {isLoading && <Skeleton />}
        {isError && <AlertActionElem text={errorMessage} />}

        {isSuccess && data && (
          <DoubleContainerElem>
            <GridElem spacing={2}>
              <TextElem tid="WEBINAR.UPDATE.FORM.PREVIEW" color="textPrimary" />
              <FileItemUploadContainer
                onSuccess={onSuccessUpload}
                defaultValue={data?.preview?.url}
                name={WEBINAR_UPDATE_FORM_VALUE_ENUM.PREVIEW}
                type="video"
                maxDurationText="2 hours"
                maxDuration={3600 * 2}
                maxSize={10737418240}
                maxSizeText="10 GB"
              />
            </GridElem>
            <GridElem spacing={2}>
              <TextElem tid="WEBINAR.UPDATE.FORM.VIDEO" color="textPrimary" />
              <FileItemUploadContainer
                onSuccess={onSuccessUpload}
                defaultValue={data?.video?.url}
                name={WEBINAR_UPDATE_FORM_VALUE_ENUM.VIDEO}
                type="video"
                maxDurationText="8 hours"
                maxDuration={36000}
                maxSize={21474836480}
                maxSizeText="20 GB"
              />
            </GridElem>
          </DoubleContainerElem>
        )}
      </ContentContainerElem>
    </GridElem>
  );
};
