//@ts-nocheck

import { GridElem } from '../../../common/grid';
import { TextElem } from '../../../common/text';
import styled from 'styled-components';
import { ButtonElem } from '../../../common/button';
import { COLOR_ENUM } from '../../../theme/color';
import { Spacing } from '../../../theme';
import { WEBINAR_ITEM_DATA_RAW } from '../../../data/webinar/constant';
import img from '../../../asset/img/sessionNotification.png';
import { Animation } from './animation';
import { useEffect } from 'react';

export const PaymentConfirm: React.FC<{
  webinarData: WEBINAR_ITEM_DATA_RAW;
}> = ({ webinarData }) => {
  const handleClick = () => {
    window.open(webinarData.buttonLink, '_blank');
  };
  useEffect(() => {
    if (webinarData?.pixelId) {
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js',
      );

      fbq('init', webinarData?.pixelId);
      fbq('track', 'PageView');
      fbq('track', 'InitiateCheckout');
    }
    // Инициализация Pixel
  }, [webinarData?.pixelId]);
  return (
    <Container spacing={5}>
      <Animation />
      <GridElem spacing={3}>
        <TextElem
          tid="SESSION.VIEWER.RESERVATION.MODAL.SUCCESS.TITLE"
          type="semi-bold"
          size="medium"
        />

        <TextElem
          tid="SESSION.VIEWER.RESERVATION.MODAL.SUCCESS.DESCRIPTION"
          color="textPrimary"
        />
      </GridElem>
      <Border spacing={4}>
        <TextElem color="textSecondary">
          {webinarData.buttonLinkDescription}
        </TextElem>
        <ButtonStyled
          color="blue"
          onClick={handleClick}
          tid={webinarData.buttonLinkName}
        />
        <Image src={img} />
      </Border>
    </Container>
  );
};

const Container = styled(GridElem)``;

const Image = styled.img`
  position: absolute;
  top: -11px;
  right: -11px;
`;

const Border = styled(GridElem)`
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BLUE]};
  padding: ${Spacing(4)};
  border-radius: ${Spacing(4)};
  position: relative;
`;

const ButtonStyled = styled(ButtonElem)`
  height: 56px;
  div > span {
    font-size: 16px;
    color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  }
`;
