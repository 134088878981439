import React from 'react';
import { EVENT_ITEM_DATA, EVENT_TYPE } from '../constant';
import { Spacing } from '../../../theme';
import { TextElem } from '../../../common/text';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';
import styled from 'styled-components';

export const ButtonMobile: React.FC<{
  data: EVENT_ITEM_DATA;
}> = ({ data }) => {
  const { link, buttonText } = data;

  return (
    <>
      <EllipsisContainerElem>
        <TextElem type="medium" color="textFourth" oneLine>
          {link}
        </TextElem>
      </EllipsisContainerElem>
      <TextElem type="medium" color="textPrimary" oneLine>
        {buttonText}
      </TextElem>
    </>
  );
};

const Image = styled.img`
  height: 40px;
  border-radius: 8px;
`;
