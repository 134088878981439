import styled from 'styled-components';
import { TextElem } from '../../../common/text';
import { Spacing } from '../../../theme';
import { ReactComponent as DetailIcon } from '../../../asset/svg/session/detail.svg';
import { FlexElem } from '../../../common/flex';
import { ReactComponent as GarantIcon } from '../../../asset/svg/session/garant.svg';
import { Popover } from 'antd';
import { COLOR_ENUM } from '../../../theme/color';

export const PopoverElem: React.FC<{}> = ({}) => {
  return (
    <FlexElem
      spacing={1.5}
      style={{ justifyContent: 'center', flexWrap: 'wrap' }}
    >
      <GarantIcon />
      <TextElem
        tid="SESSION.VIEWER.RESERVATION.MODAL.GARANT_1"
        oneLine
        size="small"
        style={{ height: '14px' }}
      />
      <TextElem
        tid="SESSION.VIEWER.RESERVATION.MODAL.GARANT_2"
        oneLine
        size="small"
        style={{ height: '14px' }}
        color="success"
      />
      <PopoverContainer id="popover-container">
        <PopoverStyled
          //@ts-ignore
          content={() => (
            <TextElem tid="SESSION.VIEWER.RESERVATION.MODAL.POPOVER"></TextElem>
          )}
          overlayStyle={{
            width: 'calc(100% - 40px)',
            maxWidth: '430px',
          }}
          //@ts-ignore
          getPopupContainer={() => document.getElementById('popover-container')}
        >
          <DetailIcon />
        </PopoverStyled>
      </PopoverContainer>
    </FlexElem>
  );
};

const PopoverContainer = styled.div`
  .ant-popover-content {
    width: calc(100% - 40px);
    max-width: 430px;
  }
  .ant-popover-inner {
    padding: ${Spacing(4)};
    background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  }
  /* z-index: ; */
  .ant-popover-arrow {
    ::before {
      background-color: ${({ theme }) =>
        theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
    }
  }
  .ant-popover {
    border-radius: 10px;
  }
`;

const PopoverStyled = styled(Popover)`
  display: flex;
  gap: ${Spacing(1.5)};
`;
