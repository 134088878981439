import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { TextElem } from '../text';
import { PROPS_TYPE } from './constant';
import { i18n } from '../../lib/lang';
import CalendarIcon from '../../asset/svg/common/calendar.svg';

// import PhoneInput from 'react-phone-input-2';
import { PhoneInput } from 'react-international-phone';
import { GridElem } from '../grid';
// import 'react-phone-input-2/lib/style.css';
import 'react-international-phone/style.css';
import indicatorIcon from '../../asset/svg/common/selectIndicator.svg';

export const Elem: React.FC<PROPS_TYPE> = ({
  title,
  error,
  errorMessage,
  onChange,
  onBlur,
  value,
  name,
  type = 'text',
  disabled,
  icon,
  placeholder,
  className,
  maxLength,
  min,
  max,
  errorContainer = true,
  autoFocus = false,
  style,
  ...props
}) => {
  const refIcon = useRef<HTMLDivElement>(null);

  const handleChange = (e: any) => {
    if (type === 'phone') {
      if (e && onChange) {
        onChange({ target: { name: name, value: e } });
      }
    } else {
      if (
        (type === 'customNumber' &&
          (new RegExp(/^\d*\.?\d*$/).test(e?.target?.value) ||
            !e?.target?.value)) ||
        type !== 'customNumber'
      ) {
        if (!e.target) e.target = {};
        e.target.name = name;

        e.target.value = maxLength
          ? e.target.value.substr(0, maxLength)
          : e.target.value;

        if (onChange) {
          onChange(e);
        }
      }
    }
  };

  const handleBlur = (e: any) => {
    if (!e.target) e.target = {};

    e.target.name = name;
    e.target.value = e.target.value;

    if (onBlur) {
      onBlur(e);
    }
  };

  const iconSize = refIcon?.current?.clientWidth || 40;
  if (disabled) {
    return (
      <GridElem spacing={3}>
        {title && (
          <TextElem
            tid={title}
            color="textPrimary"
            style={{ height: '14px' }}
          />
        )}
        <FakeInput>
          <TextElem tid={value} />
          <Icon ref={refIcon}>{icon}</Icon>
        </FakeInput>
      </GridElem>
    );
  } else {
    return (
      <Container className={className} style={style}>
        <Content>
          {title && (
            <TextElem
              tid={title}
              color="textPrimary"
              style={{ height: '14px', display: 'block', marginBottom: '12px' }}
            />
          )}
          {type === 'phone' ? (
            <PhoneInputCustom
              onChange={handleChange}
              onBlur={handleBlur}
              value={value}
              defaultCountry="ua"
              inputProps={{
                name: name,
              }}
            />
          ) : (
            <>
              <CustomInput
                name={name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={error}
                type={type}
                value={value}
                iconSize={iconSize}
                placeholder={placeholder && i18n.t(placeholder)}
                className={className}
                maxLength={maxLength}
                min={min}
                max={max}
                autoFocus={autoFocus}
                {...props}
              />
              <div id="content" />
              <Icon ref={refIcon}>{icon}</Icon>
            </>
          )}
        </Content>

        {(error || errorMessage) && (
          <TextElem size="alert" color="error">
            {errorMessage}
          </TextElem>
        )}
      </Container>
    );
  }
};

export const CustomInput = styled.input<{
  error?: boolean;
  onIonChange?: Function;
  type?: string;
  iconSize?: number;
}>`
  box-sizing: border-box;

  cursor: pointer;
  height: 46px;
  :focus-visible {
    outline: none;
  }
  position: relative;

  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.REGULAR]};

  padding-right: ${({ iconSize }) => iconSize}px;

  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
  @media screen and (width<=600px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MOBILE_DEFAULT]}px;
  }
  line-height: 1em;
  text-align: start;
  color: ${({ theme }) => theme[COLOR_ENUM.TEXT_DEFAULT]};
  caret-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_DEFAULT]};

  background: ${({ theme }) => theme[COLOR_ENUM.INPUT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  width: 100%;

  *:has(+ &) {
    transition: all 0.2s;
  }

  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]};

  &:hover {
    border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_HOVER]};

    *:has(~ &) {
      color: ${({ theme }) => theme[COLOR_ENUM.INPUT_TITLE]} !important;
    }
  }

  &:focus-within {
    border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]} !important;
  }

  ::placeholder {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PLACEHOLDER]};
  }

  &:focus-within {
    background: ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};
    border-color: ${({ theme }) => theme[COLOR_ENUM.ACTIVE]} !important;
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_ACTIVE]};
    *:has(~ &) {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
    }
    ::placeholder {
      display: none;
      color: ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};
    }
  }
  padding: 0 ${Spacing(4)};

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => theme[COLOR_ENUM.ERROR]} !important;
      color: ${({ theme }) => theme[COLOR_ENUM.ERROR]} !important;
    `}

  ::-webkit-calendar-picker-indicator {
    /* color: rgba(0, 0, 0, 0); */
    opacity: 1;
    display: block;
    background: url(${CalendarIcon}) no-repeat;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 10px;
    top: 14px;
  }
`;

const PhoneInputCustom = styled(PhoneInput)<{}>`
  .react-international-phone-country-selector-button__button-content {
    ::after {
      content: '';
      display: block;
      position: absolute;
      width: 15px;
      height: 13px;
      top: 14px;
      left: -5px;
      background: url(${indicatorIcon}) no-repeat;
      svg {
        path {
          stroke: red;
        }
      }
    }
  }
  .react-international-phone-input {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_DEFAULT]} !important;
  }

  .react-international-phone-country-selector-dropdown {
    top: 45px;
    outline: none !important;
    li {
      transition: all 0.2s;
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
      border-bottom: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
      @media screen and (width<=600px) {
        font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MOBILE_DEFAULT]}px;
      }
      padding: 0 ${Spacing(4)};
      height: 47px;
      display: flex;
      align-items: center;
    }

    .react-international-phone-country-selector-dropdown__list-item--selected {
      color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
      background-color: transparent;
      position: relative;
    }
    input:has(.react-international-phone-country-selector-button--active) {
      background: red !important;
    }
    li:hover {
      background-color: ${({ theme }) => theme[COLOR_ENUM.BORDER]};
    }
    border-radius: 0px 0px
      ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px
      ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
    background-color: ${({ theme }) => theme[COLOR_ENUM.INPUT_ACTIVE]};
    width: 100%;
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  }
  .react-international-phone-country-selector-button {
    position: absolute;
    width: 52px;
    height: 46px;
    left: 22px;
    background: transparent;
    border: none;
    :hover {
      background: transparent;
    }
    .react-international-phone-country-selector-button__dropdown-arrow {
      display: none;
    }
    .react-international-phone-flag-emoji {
      width: 18px;
    }
  }

  position: relative !important;

  .react-international-phone-country-selector {
    position: static;
  }

  && input {
    :focus-visible {
      outline: none;
    }

    padding-left: 64px;
    height: 46px;

    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
    @media screen and (width<=600px) {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MOBILE_DEFAULT]}px;
    }
    line-height: 1em;
    text-align: start;
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};

    background: ${({ theme }) => theme[COLOR_ENUM.INPUT]};
    border-radius: ${SIZE_BORDER_RADIUS_DATA[
      SIZE_BORDER_RADIUS_ENUM.DEFAULT
    ]}px;
    width: 100%;
    border: none;
    outline: none;
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]};

    &:hover {
      border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_HOVER]};
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_HOVER]};
    }

    &:focus-within {
      border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]};
    }

    ::placeholder {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PLACEHOLDER]};
    }

    &:focus-within {
      background: ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};
      border-color: ${({ theme }) => theme[COLOR_ENUM.ACTIVE]};
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_ACTIVE]};

      ::placeholder {
        display: none;
        color: ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};
      }
    }
  }

  :has(
      .react-international-phone-country-selector
        > .react-international-phone-country-selector-button--active
    )
    > input {
    border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px
      ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px 0 0;
  }
  .container:has(input:focus) .buttonWrapper > button {
    background-color: blue; /* или любой другой цвет */
  }
`;

const FakeInput = styled.div`
  padding: 0 ${Spacing(4)};
  position: relative;
  height: 46px;
  font-size: ${({ theme }) => theme[SIZE_FONT_ENUM.INPUT]}px;
  @media screen and (width<=600px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MOBILE_DEFAULT]}px;
  }
  color: ${({ theme }) => theme[COLOR_ENUM.TEXT_DISABLED]};
  background: ${({ theme }) => theme[COLOR_ENUM.INPUT_DISABLED]};
  line-height: 1em;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  overflow: hidden;
  display: flex;
  align-items: center;
  && > span {
    width: calc(100% - 40px);
    display: block;
    overflow: hidden;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }
`;

const Content = styled.div`
  input {
    transition: all 0.2s;
  }
  span {
    transition: all 0.2s;
  }

  position: relative;
`;

const Container = styled.div`
  display: grid;
  grid-gap: ${Spacing(2)};
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme[COLOR_ENUM.INPUT]}
      inset;
  }
`;

const Icon = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: max-content;
  box-sizing: content-box;
  z-index: 2;
`;
