import { Component } from './component';
import {
  MESSAGE_ITEM_DATA,
  REPLAY_MESSAGE_DATA,
} from '../../data/message/constant';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { i18n } from '../../lib/lang';
import { SESSION_ITEM_DATA } from '../../data/session/constant';
import { checkTextToLink } from '../../lib/util/checkTextToLink';

export const Container: React.FC<{
  chatMessage: MESSAGE_ITEM_DATA[];
  sendMessage: Function;
  setChatMessage: Function;
  eventStarted: boolean;
  data?: SESSION_ITEM_DATA;
  isLoading?: boolean;
  isSuccess?: boolean;
  toggleMessageSelection: Function;
  selectedMessages: { messageIds: string[]; viewerIds: string[] };
  preModerationActive: boolean;
}> = ({
  chatMessage,
  sendMessage,
  setChatMessage,
  eventStarted,
  data,
  isLoading,
  isSuccess,
  toggleMessageSelection,
  selectedMessages,
  preModerationActive,
}) => {
  const [offensiveWords, setOffensiveWords] = useState<string[]>([]);
  const [newMessage, setNewMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [reply, setReply] = useState<REPLAY_MESSAGE_DATA | null>(null);

  useEffect(() => {
    axios
      .get('/offensiveWords.txt')
      .then((response) => {
        const words = response.data
          .split('\n')
          .map((word: string) => word.trim());
        const flattenedWords = words.flatMap((word: string) => word.split(' '));
        setOffensiveWords(flattenedWords);
      })
      .catch((error) => {
        console.error('Error loading offensive words:', error);
      });
  }, []);

  const checkForOffensiveWords = (text: string) => {
    const words = text.toLowerCase().split(/\s+/);
    const contains = words.some((word: string) =>
      offensiveWords.includes(word),
    );
    return contains;
  };

  const changeMessage = (e: any) => {
    if (e.target.value.trim().length > 300) {
      setError(i18n.t('VALIDATION.MAX_LENGTH', { max: 300 }));
    } else if (checkForOffensiveWords(e.target.value.trim())) {
      setError(i18n.t('ERROR.OFFENSIVE_WORD'));
    } else {
      setError('');
    }
    setNewMessage(e.target.value);
  };

  const pressEnter = (e: any) => {
    if (
      e.key === 'Enter' &&
      !e.shiftKey &&
      !e.ctrlKey &&
      !e.altKey &&
      !e.metaKey &&
      !isSubmitDisabled()
    ) {
      e.preventDefault();

      sendMessageWrapper();
    }
  };

  const sendMessageWrapper = () => {
    if (!error && newMessage.trim()) {
      sendMessage(newMessage.trim(), reply?.id);
      setReply(null);
      setNewMessage('');
    }
  };

  function isSubmitDisabled() {
    return error;
  }

  return (
    <Component
      chatMessage={chatMessage}
      error={error}
      pressEnter={pressEnter}
      isSubmitDisabled={isSubmitDisabled}
      changeMessage={changeMessage}
      newMessage={newMessage}
      sendMessageWrapper={sendMessageWrapper}
      eventStarted={eventStarted}
      moderMessage={data?.webinar?.moderMessage}
      isLoading={isLoading}
      isSuccess={isSuccess}
      reply={reply}
      setReply={setReply}
      toggleMessageSelection={toggleMessageSelection}
      selectedMessages={selectedMessages}
      preModerationActive={preModerationActive}
      setNewMessage={setNewMessage}
    />
  );
};
