import React from 'react';

import { LayoutSectionElem } from '../../common/layout-section';
import { HeaderContainer } from '../../epic/header';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { MenuAppContainer } from '../../epic/menu-app';
import { WebinarListContainer } from '../../epic/webinar-list';

import icon from '../../asset/svg/header/webinar.svg';
import iconLight from '../../asset/svg/header/webinar-light.svg';
import { THEME_MODULE_NAME } from '../../data/theme';
import { useSelector } from '../../lib/store';
import { THEME_ENUM } from '../../data/theme/constant';

export const Page: React.FC = () => {
  const { theme } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));
  return (
    <PageElem>
      <LayoutAppElem>
        <MenuAppContainer />

        <LayoutElem size="default">
          <HeaderContainer
            icon={theme.type === THEME_ENUM.LIGHT ? iconLight : icon}
            title="WEBINAR.LIST.HEADER"
            size="default"
          />
          <LayoutSectionElem spacing={5}>
            <WebinarListContainer />
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
    </PageElem>
  );
};
