import {
  VIEWER_ITEM_LIST_DATA_RAW,
  VIEWER_ITEM_LIST_DATA,
} from '../../data/viewer/constant';
import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'VIEWER_ITEM_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: (viewerId: string) => `/viewer/${viewerId}`,
};

export interface ACTION_RESPONSE_INTER extends VIEWER_ITEM_LIST_DATA_RAW {}

export interface DATA extends VIEWER_ITEM_LIST_DATA {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export const STEPPER_LIST = [
  { topic: i18n.t('VIEWER.LIST.STATUS_NOT_SEEN') },
  { topic: i18n.t('VIEWER.LIST.STATUS_BUTTON_NOT_PRESSED') },
  { topic: i18n.t('VIEWER.LIST.STATUS_BUTTON_PRESSED') },
  { topic: i18n.t('VIEWER.LIST.STATUS_SENT') },
  { topic: i18n.t('VIEWER.LIST.STATUS_PAID') },
];
