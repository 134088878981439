import React, { useEffect } from 'react';

import { GridElem } from '../../common/grid';

import { TextElem } from '../../common/text';

import styled, { keyframes } from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';

import { SessionItemRoomEditProfileContainer } from '../session-item-room-edit-profile';
import { FlexElem } from '../../common/flex';
import { ReactComponent as ViewerIcon } from '../../asset/svg/session/viewer.svg';
import { ReactComponent as ClockIcon } from '../../asset/svg/session/clock.svg';
import { SESSION_ITEM_DATA } from '../../data/session/constant';
import { ContentContainerElem } from '../../common/content-container';
import { Skeleton } from './skeleton';
import { useTimer } from 'react-timer-hook';
import { AvatarElem } from '../../common/avatar';

export const Component: React.FC<{
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  data?: SESSION_ITEM_DATA;
  sessionStart: boolean;
  viewerCount?: number;
  setReactionActive: React.Dispatch<React.SetStateAction<boolean>>;
  reactionActive: boolean;
}> = ({
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  data,
  sessionStart,
  viewerCount,
  reactionActive,
  setReactionActive,
}) => {
  const { seconds, minutes, hours, days, isRunning, restart } = useTimer({
    expiryTimestamp: new Date('1970-01-01T00:00:00.0000'),
  });
  useEffect(() => {
    if (data?.serverDate) {
      restart(new Date(data.serverDate));
    }
  }, [data?.serverDate]);

  const second = seconds > 9 ? seconds.toString() : '0' + seconds.toString();
  const minute = minutes > 9 ? minutes.toString() : '0' + minutes.toString();
  const hour =
    hours + 24 * days > 9
      ? (hours + 24 * days).toString()
      : '0' + hours.toString();
  const day = days > 9 ? days.toString() : '0' + days.toString();
  return (
    <Container>
      {isLoading && <Skeleton />}
      {data && isSuccess && (
        <>
          <FlexElem style={{ justifyContent: 'space-between' }}>
            <SpeakerInfo>
              {/* {data?.webinar?.speakerImg && ( */}
              <SpeakerImg userAvatar={data?.webinar?.speakerImg?.url} />
              {/* )} */}
              <SpeakerTextInfo spacing={0.5}>
                <TextElem tid="SESSION.ITEM.SPEAKER" size="verySmall" />
                <TextElem
                  tid="SESSION.ITEM.SPEAKER_NAME"
                  type="semi-bold"
                  size="semiSmall"
                  tvalue={{
                    value: data?.webinar?.speakerName,
                  }}
                />
              </SpeakerTextInfo>
            </SpeakerInfo>
            <FlexStyled spacing={3}>
              {sessionStart && (
                <InfoContainer>
                  <FlexStyled spacing={2}>
                    <TextElem
                      color="error"
                      tid="SESSION.ITEM.LIVE"
                      type="semi-bold"
                    />
                    <RedDot />
                  </FlexStyled>
                </InfoContainer>
              )}
              {sessionStart ? (
                <InfoContainerCount>
                  <FlexStyled spacing={4} style={{ justifyContent: 'center' }}>
                    <TextElem
                      tid="SESSION.ITEM.WATCH_NOW"
                      color="textPrimary"
                      oneLine
                    />
                    <FlexStyled
                      spacing={1.5}
                      style={{ width: 'auto', alignItem: 'center' }}
                    >
                      <ViewerIconStyled />
                      <TextElem
                        size="medium"
                        type="semi-bold"
                        color="textSecondary"
                      >
                        {viewerCount}
                      </TextElem>
                      <Dot />
                    </FlexStyled>
                  </FlexStyled>
                </InfoContainerCount>
              ) : (
                <TextContainer>
                  <InfoContainerCount>
                    <FlexStyled spacing={4} style={{ alignItems: 'center' }}>
                      <StartText
                        tid="SESSION.ITEM.START_AT"
                        color="textPrimary"
                      />
                      <FlexElem spacing={1.5} style={{ width: 'auto' }}>
                        <ClockIconStyled />
                        <TimeText
                          size="medium"
                          type="semi-bold"
                          color="textSecondary"
                        >
                          {data?.time}
                        </TimeText>
                      </FlexElem>
                    </FlexStyled>
                  </InfoContainerCount>
                  <TimeContainer>
                    <p>
                      <TextElem type="semi-bold" color="textSecondary">
                        {hour[0]}
                        {hour[1]}
                      </TextElem>
                      <TextElem type="semi-bold" color="textThird" size="small">
                        &nbsp;:&nbsp;
                      </TextElem>
                      <TextElem type="semi-bold" color="textSecondary">
                        {minute[0]}
                        {minute[1]}
                      </TextElem>
                      <TextElem type="semi-bold" color="textThird" size="small">
                        &nbsp;:&nbsp;
                      </TextElem>
                      <TextElem type="semi-bold" color="textSecondary">
                        {second[0]}
                        {second[1]}
                      </TextElem>
                    </p>
                  </TimeContainer>
                </TextContainer>
              )}
            </FlexStyled>
          </FlexElem>

          <SessionItemRoomEditProfileContainer
            sessionId={data?.id}
            reactionActive={reactionActive}
            setReactionActive={setReactionActive}
          />
        </>
      )}
    </Container>
  );
};

const ViewerIconStyled = styled(ViewerIcon)`
  path {
    fill: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
  }
`;

const TextContainer = styled(FlexElem)`
  @media screen and (width<460px) {
    gap: ${Spacing(2)};
  }
`;

const ClockIconStyled = styled(ClockIcon)`
  height: 16px !important;
  width: 16px !important;
  path {
    fill: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
  }
  @media screen and (width < 460px) {
    height: 10px !important;
    width: 10px !important;
  }
`;

const StartText = styled(TextElem)`
  @media screen and (width < 460px) {
    font-size: 11px;
  }
`;

const TimeText = styled(TextElem)`
  @media screen and (width < 460px) {
    font-size: 14px;
  }
`;

const TimeContainer = styled.div`
  border-radius: 12px;
  padding: 0 ${Spacing(4)};
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 14px;
  min-width: 110px;

  justify-content: center;

  @media screen and (width < 460px) {
    padding: 0 ${Spacing(3)};
    height: 36px;
    min-width: 92px;
    span {
      font-size: 12px;
    }
  }
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
`;

const FlexStyled = styled(FlexElem)`
  width: fit-content;
  justify-content: center;
  @media screen and (width<460px) {
    gap: ${Spacing(2)};
  }
  @media screen and (width<600px) {
    width: 100%;
  }
`;

const Dot = styled.div`
  height: 4px;
  width: 4px;
  border-radius: 100%;

  background: ${({ theme }) => theme[COLOR_ENUM.SUCCESS]};
`;

const blink = keyframes`
  0%, 100% {
    opacity: 1; /* Полностью видимая точка */
  }
  50% {
    opacity: 0; /* Невидимая точка */
  }
`;

const RedDot = styled(Dot)`
  background: ${({ theme }) => theme[COLOR_ENUM.ERROR]};
  animation: ${blink} 2s infinite; /* Применяем анимацию */
  box-shadow: 0 0 8px rgba(255, 0, 0, 0.5);
`;

const InfoContainer = styled.div`
  padding: ${Spacing(4)};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;

  svg {
    height: 18px;
    width: 19px;
    min-width: 18px;
  }
  @media screen and (width<460px) {
    padding: ${Spacing(3)};
    height: 36px;

    svg {
      height: 10px;
      width: 10px;
      min-width: 10px;
    }
    span {
      font-size: 12px;
    }
  }
`;

const InfoContainerCount = styled(InfoContainer)`
  @media screen and (width<600px) {
    gap: 8px;
    width: 100%;
    justify-items: center;
  }
`;

export const SpeakerImg = styled(AvatarElem)`
  @media screen and (width<500px) {
    img {
    }
  }
`;

const SpeakerInfo = styled.div`
  display: flex;
  gap: ${Spacing(3)};
  align-items: center;
  @media screen and (width<600px) {
    display: none;
  }
`;

const SpeakerTextInfo = styled(GridElem)`
  /* height: 28px; */
`;

const Container = styled(ContentContainerElem)`
  height: 100%;
  width: 100%;
  border-radius: 20px;
  padding: ${Spacing(4)} ${Spacing(5)};
  grid-template-columns: auto 50px;
  gap: ${Spacing(4)};
  align-items: center;

  @media screen and (width<460px) {
    grid-template-columns: auto 36px;

    gap: ${Spacing(2)};
  }

  @media screen and (width<900px) {
    background: ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};
    /* border-radius: 20px; */
    padding: 0;
  }
`;
