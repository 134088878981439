import React from 'react';

import { FormikValues } from 'formik';

import { EVENT_TYPE_TAB, FORM_VALUE_ENUM } from './constant';

import { GridElem } from '../../common/grid';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import { i18n } from '../../lib/lang';

import { TextElem } from '../../common/text';
import { ContentContainerElem } from '../../common/content-container';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';

import { ReactComponent as AddIconLarge } from '../../asset/svg/common/add.svg';
import { ReactComponent as CloseIcon } from '../../asset/svg/common/close.svg';
import { TabActionElem } from '../../common/tab-action';

import { EVENT_TYPE } from '../../data/event/constant';
import { ReservationForm } from './frame/reservation';
import { MessageForm } from './frame/message';
import { BannerForm } from './frame/banner';
import { ButtonForm } from './frame/button';
import { TimecodeForm } from './frame/timecode';
import { GroupMessageForm } from './frame/group-message';
import { ReactionForm } from './frame/reaction';
import { ReservationCreatedForm } from './frame/reservationCreated';
import { QuestionForm } from './frame/question';
import { SurveyForm } from './frame/survey';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  formVisible: boolean;
  setFormVisible: Function;
  getSelectValue: Function;
  sessionId: string;
  setFieldValue: Function;
  onSuccessUpload: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isError,
  errorMessage,
  formVisible,
  setFormVisible,
  getSelectValue,
  setFieldValue,
  sessionId,
  onSuccessUpload,
}) => {
  const closeModal = () => {
    formik.resetForm();

    setFormVisible(false);
  };
  const changeTab = (e: any) => {
    formik.resetForm();

    formik.handleChange(e);
  };
  return (
    <>
      {formVisible ? (
        <FormElem onSubmit={formik.handleSubmit}>
          {isLoading && <LoaderElem />}
          <ContentContainerElem>
            <Container spacing={6}>
              <TitleContainer>
                <TextElem
                  tid="EVENT.CREATE.TITLE"
                  type="semi-bold"
                  size="main"
                />
                <CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
              </TitleContainer>
              <TabActionElem
                tabList={EVENT_TYPE_TAB}
                name={FORM_VALUE_ENUM.TYPE}
                onChange={changeTab}
                value={getFieldValue(FORM_VALUE_ENUM.TYPE)}
              />
              <GridElem spacing={5}>
                {getFieldValue(FORM_VALUE_ENUM.TYPE) == EVENT_TYPE.MESSAGE && (
                  <MessageForm
                    formik={formik}
                    getFieldValue={getFieldValue}
                    getFieldError={getFieldError}
                    isFieldError={isFieldError}
                    sessionId={sessionId}
                    setFieldValue={setFieldValue}
                  />
                )}

                {getFieldValue(FORM_VALUE_ENUM.TYPE) ==
                  EVENT_TYPE.GROUP_MESSAGE && (
                  <GroupMessageForm
                    formik={formik}
                    getFieldValue={getFieldValue}
                    getFieldError={getFieldError}
                    isFieldError={isFieldError}
                    sessionId={sessionId}
                  />
                )}
                {getFieldValue(FORM_VALUE_ENUM.TYPE) == EVENT_TYPE.REACTION && (
                  <ReactionForm
                    formik={formik}
                    getFieldValue={getFieldValue}
                    getFieldError={getFieldError}
                    isFieldError={isFieldError}
                    setFieldValue={setFieldValue}
                    getSelectValue={getSelectValue}
                    isSubmitDisabled={isSubmitDisabled}
                  />
                )}

                {getFieldValue(FORM_VALUE_ENUM.TYPE) == EVENT_TYPE.SURVEY && (
                  <SurveyForm
                    formik={formik}
                    getFieldValue={getFieldValue}
                    getFieldError={getFieldError}
                    isFieldError={isFieldError}
                    isSubmitDisabled={isSubmitDisabled}
                  />
                )}

                {getFieldValue(FORM_VALUE_ENUM.TYPE) ==
                  EVENT_TYPE.RESERVATION_CREATED && (
                  <ReservationCreatedForm
                    formik={formik}
                    getFieldValue={getFieldValue}
                    getFieldError={getFieldError}
                    isFieldError={isFieldError}
                    setFieldValue={setFieldValue}
                    sessionId={sessionId}
                  />
                )}
                {getFieldValue(FORM_VALUE_ENUM.TYPE) == EVENT_TYPE.QUESTION && (
                  <QuestionForm
                    formik={formik}
                    getFieldValue={getFieldValue}
                    getFieldError={getFieldError}
                    isFieldError={isFieldError}
                    setFieldValue={setFieldValue}
                    sessionId={sessionId}
                  />
                )}
                {getFieldValue(FORM_VALUE_ENUM.TYPE) ==
                  EVENT_TYPE.RESERVATION && (
                  <ReservationForm
                    formik={formik}
                    getFieldValue={getFieldValue}
                    getFieldError={getFieldError}
                    isFieldError={isFieldError}
                  />
                )}
                {getFieldValue(FORM_VALUE_ENUM.TYPE) == EVENT_TYPE.BANNER && (
                  <BannerForm
                    formik={formik}
                    getFieldValue={getFieldValue}
                    getFieldError={getFieldError}
                    isFieldError={isFieldError}
                    onSuccessUpload={onSuccessUpload}
                  />
                )}
                {getFieldValue(FORM_VALUE_ENUM.TYPE) == EVENT_TYPE.BUTTON && (
                  <ButtonForm
                    formik={formik}
                    getFieldValue={getFieldValue}
                    getFieldError={getFieldError}
                    isFieldError={isFieldError}
                  />
                )}
                {getFieldValue(FORM_VALUE_ENUM.TYPE) == EVENT_TYPE.TIMECODE && (
                  <TimecodeForm
                    formik={formik}
                    getFieldValue={getFieldValue}
                    getFieldError={getFieldError}
                    isFieldError={isFieldError}
                  />
                )}
                {getFieldValue(FORM_VALUE_ENUM.TYPE) !==
                  EVENT_TYPE.GROUP_MESSAGE &&
                  getFieldValue(FORM_VALUE_ENUM.TYPE) !== EVENT_TYPE.SURVEY && (
                    <FieldContainer>
                      <ButtonElem
                        type="submit"
                        tid="EVENT.CREATE.BUTTON.CREATE"
                        fill="solid"
                        disabled={isSubmitDisabled()}
                      />
                    </FieldContainer>
                  )}
              </GridElem>
              {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
            </Container>
          </ContentContainerElem>
        </FormElem>
      ) : (
        <AddContainer onClick={() => setFormVisible(true)}>
          <TextElem tid="EVENT.CREATE.ADD" type="semi-bold" size="main" />
          <AddIconLarge />
        </AddContainer>
      )}
    </>
  );
};

const Container = styled(GridElem)`
  @media screen and (width>900px) {
    gap: ${Spacing(5)};
  }
`;

export const FieldContainer = styled(GridElem)`
  @media screen and (width>600px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media screen and (width>900px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddContainer = styled(TitleContainer)`
  padding: ${Spacing(4)} ${Spacing(5)};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  transition: all 0.2s;
  :hover {
    background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
  }
`;
