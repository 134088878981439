import {
  EXPENDITURE_ITEM_DATA,
  EXPENDITURE_ITEM_DATA_RAW,
  EXPENDITURE_ITEM_LIST_DATA,
} from './constant';

export const convertExpenditure = (
  expenditure: EXPENDITURE_ITEM_DATA_RAW,
): EXPENDITURE_ITEM_DATA => {
  return {
    ...expenditure,
  };
};

export const convertExpenditureList = (
  expenditureList: EXPENDITURE_ITEM_DATA_RAW[],
): EXPENDITURE_ITEM_LIST_DATA => {
  return {
    list: expenditureList?.map((expenditure: EXPENDITURE_ITEM_DATA_RAW) => {
      return convertExpenditure(expenditure);
    }),
    isEmpty: !expenditureList?.length,
  };
};
