import React from 'react';

import { GridElem } from '../../common/grid';
import { CategorySelect } from './frame/category-select';
import { Search } from './frame/search';

import { TimeRange } from './frame/time-range';
import { VIEWER_ITEM_LIST_DATA } from '../../data/viewer/constant';
import { ButtonElem } from '../../common/button';
import Icon from '../../asset/svg/common/download.svg';
import styled from 'styled-components';
import { OnlineSwitch } from './frame/online';
import { Spacing } from '../../theme';
import { FlexElem } from '../../common/flex';
import { FieldDataTimeRangeElem } from '../../common/field-datetime-range';
import { COLOR_ENUM } from '../../theme/color';

export const Component: React.FC<{
  setValue: Function;
  value: string;
  search: string;
  setSearch: Function;
  data?: VIEWER_ITEM_LIST_DATA;
  sessionId: string;
  isLoading?: boolean;
  handleExport: () => void;
  online: boolean;
  setOnline: Function;
  setDate: Function;
  date: any;

  setRange: Function;
}> = ({
  setValue,
  value,
  search,
  setSearch,
  setRange,
  // session,
  // setSession,
  data,
  sessionId,
  isLoading,
  handleExport,
  online,
  setOnline,
  date,
  setDate,
}) => {
  const handleChange = (e: any) => {
    setDate(e?.target?.value);
  };
  return (
    <Container spacing={4}>
      {/* <SessionSelect
        webinarId={webinarId}
        session={session}
        setSession={setSession}
      /> */}
      <CategorySelect value={value} setValue={setValue} />

      <TimeRange setRange={setRange} />
      <Search search={search} setSearch={setSearch} />
      <FieldDataTimeRange showTime={false} onChange={handleChange} />

      <OnlineSwitch online={online} setOnline={setOnline} />

      <ButtonElem
        onClick={handleExport}
        tid="VIEWER.LIST.EXPORT"
        color="backgroundThird"
        iconRight={Icon}
        className="button"
      />
      {/* </ButtonContainer> */}
    </Container>
  );
};

const FieldDataTimeRange = styled(FieldDataTimeRangeElem)`
  .ant-picker-range {
    :hover {
      background-color: ${({ theme }) =>
        theme[COLOR_ENUM.DEFAULT] === '#1B1924'
          ? theme[COLOR_ENUM.INPUT_BACKGROUND]
          : theme[COLOR_ENUM.INPUT]};
    }
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.DEFAULT] === '#1B1924'
        ? theme[COLOR_ENUM.INPUT_BACKGROUND]
        : theme[COLOR_ENUM.INPUT]};
  }
`;
const Container = styled(GridElem)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${Spacing(3)};

  @media (max-width: 710px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
