import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './checkout-form';
import { useSelector } from '../../../lib/store';
import { LIGHT_COLOR_DATA, DARK_COLOR_DATA } from '../../../theme/color';
import { THEME_MODULE_NAME } from '../../theme';
import { THEME_ENUM } from '../../theme/constant';

const Checkout: React.FC<{ reservationId: string }> = ({ reservationId }) => {
  const stripePromise = loadStripe(
    'pk_test_51NxYJqFwp1xPJMQby5suG9GhoP0DfMFEsmFWa0w7omgX8aGh7DwiVsdeStSagIKcodEU6TbfYEJIyu2qYc8HzcMP00ugaK2yrC',
  );

  const { theme: themeType } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));

  const appearance = {
    theme:
      themeType.type === THEME_ENUM.LIGHT
        ? ('stripe' as 'stripe')
        : ('night' as 'night'),
    fontSize: '12px',
    borderRadius: '6px',
    spacing: '8px',
  };

  const options = {
    mode: 'payment' as const,
    amount: 50000,
    currency: 'uah',
    appearance: appearance,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm reservationId={reservationId} />
    </Elements>
  );
};

export default Checkout;
