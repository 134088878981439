import React, { useState } from 'react';
import { useFormik, FormikValues, Form, Formik } from 'formik';
import { FieldArray } from 'formik';
import { GridElem } from '../../../common/grid';
import { DoubleContainerElem } from '../../../common/double-container';
import { FieldTextAreaElem } from '../../../common/field-text-area';
import { FORM_VALUE_ENUM } from '../constant';
import { FieldTimeInputElem } from '../../../common/field-time-input';
import { getPersonList } from '../../../data/person/action';
import { convertPersonListToSelect } from '../../../data/person/convert';
import { SelectDynamicContainer } from '../../select-dynamic';
import styled from 'styled-components';
import { ButtonElem } from '../../../common/button';
import { FieldTextElem } from '../../../common/field-text';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import { ReactComponent as DeleteIcon } from '../../../asset/svg/common/close.svg';
import deleteIcon from '../../../asset/svg/button/delete.svg';
import addIcon from '../../../asset/svg/button/plus.svg';
import { FieldContainer } from '../component';
import { ModalConfirmElem } from '../../../common/modal-confirm';
import { TextElem } from '../../../common/text';
import { getIn } from 'formik'; // Импортируем getIn для работы с вложенными объектами
import * as Yup from 'yup';
import { i18n } from '../../../lib/lang';
import { EVENT_ITEM_DATA_RAW } from '../../../data/event/constant';
interface Message {
  person: string;
  time: string;
  message: string;
}

const parseTimeToDate = (timeString: string): Date => {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  const date = new Date();
  date.setHours(hours, minutes, seconds, 0);
  return date;
};

const formatDateToTimeString = (date: Date): string => {
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};

const distributeMessages = (
  messages: string[],
  users: string[],
  startTimeString: string,
  endTimeString: string,
): Message[] => {
  const distributed: Message[] = [];
  const startTime = parseTimeToDate(startTimeString);
  const endTime = parseTimeToDate(endTimeString);

  // Массив для отслеживания пользователей, которые уже были использованы
  let availableUsers = [...users];

  messages.forEach((message) => {
    if (availableUsers.length === 0) {
      // Если все пользователи уже были использованы, сбрасываем доступных пользователей
      availableUsers = [...users];
    }

    // Выбираем случайного пользователя из доступных
    const randomIndex = Math.floor(Math.random() * availableUsers.length);
    const randomUser = availableUsers[randomIndex];

    // Удаляем выбранного пользователя из доступных, чтобы избежать повторения
    availableUsers.splice(randomIndex, 1);

    const randomTime = new Date(
      startTime.getTime() +
        Math.random() * (endTime.getTime() - startTime.getTime()),
    );

    distributed.push({
      person: randomUser,
      time: formatDateToTimeString(randomTime),
      message,
    });
  });

  return distributed;
};

export const GroupMessageForm: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  sessionId: string;
  update?: boolean;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  getFieldValue,
  sessionId,
  update = false,
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  // Создаём схему валидации с Yup
  const messageValidationSchema = Yup.object().shape({
    messages: Yup.array().of(
      Yup.object().shape({
        person: Yup.string().required(i18n.t('VALIDATION.REQUIRED')), // Поле user обязательно
        time: Yup.string().required(i18n.t('VALIDATION.REQUIRED')), // Поле time обязательно
        message: Yup.string()
          .required(i18n.t('VALIDATION.REQUIRED')) // Сообщение обязательно
          .max(200, i18n.t('VALIDATION.BALANCE', { max: 200 })), // Максимальная длина сообщения 500 символов
      }),
    ),
  });

  const formikInternal: any = useFormik({
    initialValues: {
      messages: getFieldValue(FORM_VALUE_ENUM.MESSAGES).length
        ? getFieldValue(FORM_VALUE_ENUM.MESSAGES).map(
            (item: EVENT_ITEM_DATA_RAW) => ({
              ...item,
              person: item.person.id,
            }),
          )
        : ([] as Message[]),
    },
    enableReinitialize: true,
    validationSchema: messageValidationSchema, // добавляем валидацию
    validateOnMount: true,
    onSubmit: (values: FormikValues) => {
      formik.setFieldValue(FORM_VALUE_ENUM.MESSAGES, values.messages);
      formik.handleSubmit();
      formikInternal.resetForm();
    },
  });

  // Функция очистки всех сообщений
  const handleClearMessages = (): void => {
    setIsModalVisible(true);
  };

  return (
    <GridElem spacing={5}>
      <TripleContainer>
        <SelectDynamicContainer
          formik={formik}
          value={getFieldValue(FORM_VALUE_ENUM.PERSON)}
          action={getPersonList}
          name={FORM_VALUE_ENUM.PERSON}
          convert={convertPersonListToSelect}
          param={sessionId}
          title="EVENT.FORM.PERSON"
          errorMessage={getFieldError(FORM_VALUE_ENUM.PERSON)}
          error={isFieldError(FORM_VALUE_ENUM.PERSON)}
          isMulti
        />
        <FieldTimeInputElem
          name={FORM_VALUE_ENUM.TIME}
          onChange={formik.handleChange}
          title="EVENT.FORM.TIME"
          value={getFieldValue(FORM_VALUE_ENUM.TIME)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.TIME)}
          error={isFieldError(FORM_VALUE_ENUM.TIME)}
          formik={formik}
        />
        <FieldTimeInputElem
          name={FORM_VALUE_ENUM.END_TIME}
          onChange={formik.handleChange}
          title="EVENT.FORM.END_TIME"
          value={getFieldValue(FORM_VALUE_ENUM.END_TIME)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.END_TIME)}
          error={isFieldError(FORM_VALUE_ENUM.END_TIME)}
          formik={formik}
        />
      </TripleContainer>

      <FieldTextAreaElem
        name={FORM_VALUE_ENUM.MESSAGE}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        title="EVENT.FORM.MESSAGE"
        value={getFieldValue(FORM_VALUE_ENUM.MESSAGE)}
        errorMessage={getFieldError(FORM_VALUE_ENUM.MESSAGE)}
        error={isFieldError(FORM_VALUE_ENUM.MESSAGE)}
      />

      <Formik
        initialValues={formikInternal.initialValues}
        values={formikInternal.values}
        validationSchema={messageValidationSchema}
        validateOnMount={true}
        onSubmit={(values: FormikValues) => {
          formik.setFieldValue(FORM_VALUE_ENUM.MESSAGES, values.messages);
          formik.handleSubmit();
        }}
      >
        {(formikProps) => {
          const {
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            isValid,
          } = formikProps;

          const addMessage = (): void => {
            const users = getFieldValue(FORM_VALUE_ENUM.PERSON);

            const messages: string[] = getFieldValue(
              FORM_VALUE_ENUM.MESSAGE,
            ).split('\n\n\n');
            const distributedMessages: Message[] = distributeMessages(
              messages,
              users,
              getFieldValue(FORM_VALUE_ENUM.TIME),
              getFieldValue(FORM_VALUE_ENUM.END_TIME),
            );

            setFieldValue('messages', [
              ...values.messages,
              ...distributedMessages,
            ]);
          };

          return (
            <Form>
              <GridElem spacing={5}>
                {isModalVisible && (
                  <ModalConfirmElem
                    action={() => {
                      setFieldValue('messages', []);
                      setIsModalVisible(false);
                    }}
                    open={isModalVisible}
                    type="delete"
                  />
                )}
                <FieldContainer>
                  <DoubleContainerElem>
                    <ButtonElem
                      type="button"
                      onClick={addMessage}
                      tid="EVENT.CREATE.GROUP_MESSAGE.ADD_MESSAGE"
                      color="backgroundThird"
                      iconRight={addIcon}
                      disabled={!formik.isValid}
                    />

                    <ButtonElem
                      type="button"
                      onClick={handleClearMessages}
                      tid="EVENT.CREATE.GROUP_MESSAGE.CLEAR_ALL"
                      color="error"
                      iconRight={deleteIcon}
                    />
                  </DoubleContainerElem>
                </FieldContainer>
                {values.messages.length > 0 && (
                  <FieldArray
                    name="messages"
                    render={(arrayHelpers) => (
                      <GridElem spacing={5}>
                        <GridElem spacing={3}>
                          <TextElem tid={'EVENT.CREATE.GROUP_MESSAGE.TITLE'} />
                          <MessageList>
                            {values.messages.map(
                              (msg: Message, index: number) => (
                                <MessageWrapper key={index}>
                                  <CustomGridElem>
                                    <FieldTimeInputElem
                                      name={`messages[${index}].time`}
                                      value={values.messages[index].time}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      errorMessage={getIn(
                                        errors,
                                        `messages[${index}].time`,
                                      )}
                                    />
                                    <SelectDynamicContainer
                                      formik={formikProps}
                                      value={values.messages[index].person}
                                      action={getPersonList}
                                      name={`messages[${index}].person`}
                                      convert={convertPersonListToSelect}
                                      param={sessionId}
                                    />
                                    <FieldTextElem
                                      value={values.messages[index].message}
                                      onBlur={handleBlur}
                                      name={`messages[${index}].message`}
                                      onChange={handleChange}
                                      errorMessage={
                                        getIn(
                                          touched,
                                          `messages[${index}].message`,
                                        ) &&
                                        getIn(
                                          errors,
                                          `messages[${index}].message`,
                                        )
                                      }
                                    />
                                  </CustomGridElem>
                                  <div
                                    className="deleteIconContainer"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <DeleteIconStyled />
                                  </div>
                                </MessageWrapper>
                              ),
                            )}
                          </MessageList>
                        </GridElem>
                        <FieldContainer>
                          {update ? (
                            <ButtonElem
                              type="button"
                              tid="EVENT.UPDATE.BUTTON.UPDATE"
                              fill="solid"
                              color="success"
                              disabled={!isValid}
                              onClick={handleSubmit}
                            />
                          ) : (
                            <ButtonElem
                              type="button"
                              tid="EVENT.CREATE.BUTTON.CREATE"
                              fill="solid"
                              onClick={handleSubmit}
                              disabled={!isValid}
                            />
                          )}
                        </FieldContainer>
                      </GridElem>
                    )}
                  />
                )}
              </GridElem>
            </Form>
          );
        }}
      </Formik>
    </GridElem>
  );
};

export const DeleteIconStyled = styled(DeleteIcon)`
  path {
    transition: all 0.2s;
  }
`;

const MessageWrapper = styled(GridElem)`
  grid-template-columns: auto 16px;
  height: 100%;
  & .deleteIconContainer {
    cursor: pointer;
    padding-top: 15px;

    :hover {
      path {
        stroke: ${({ theme }) => theme[COLOR_ENUM.ERROR]};
      }
    }
  }
`;

const TripleContainer = styled(GridElem)`
  grid-template-columns: repeat(3, 1fr);

  @media screen and (width<750px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;

    > :nth-child(1) {
      grid-column: 1/3;
    }
  }
  @media screen and (width<500px) {
    grid-template-columns: 1fr;
    > :nth-child(1) {
      grid-column: 1/2;
    }
  }
`;

const CustomGridElem = styled(GridElem)`
  grid-template-columns: 168px 200px auto;
  gap: ${Spacing(3)};
  align-items: start;
  @media screen and (width<870px) {
    grid-template-columns: 1fr 1fr;
    > :nth-child(3) {
      grid-column: 1/3;
    }
  }
  @media screen and (width<600px) {
    grid-template-columns: 1fr;
    > :nth-child(3) {
      grid-column: 1/2;
    }
  }
`;

const MessageList = styled(GridElem)`
  @media screen and (width<870px) {
    gap: ${Spacing(5)};
  }
`;
