import { ACTION_DATA, ACTION_TYPE, STORE_INTER } from './constant';
import { ActionData } from '../../lib/store/core';

const initialState: STORE_INTER = {
  logged: null,
  isConfirm: null,
  user: null,
  meta: {},
};

export function Store(state = initialState, action: ActionData<ACTION_DATA>) {
  switch (action.type) {
    case ACTION_TYPE.SET_DATA:
      return {
        ...state,
        logged: !!action.data.user,
        isConfirm: action.data.user?.confirmAccount,
        user: action.data.user,
        meta: action.data.meta,
      };
    case ACTION_TYPE.SET_META_DATA:
      return {
        ...state,
        meta: action.data,
      };

    case ACTION_TYPE.CLEAR_DATA:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
