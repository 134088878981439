export const MODULE_NAME =
  'SESSION_ITEM_MODERATION_CREATE_QUESTION_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: (id: string) => `session/moderation/createQuestionFromMessage/${id}`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
